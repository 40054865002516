import { TableFields } from "types";
import { LooseObject, SelectDataProp } from "../models/common";
import { flatMap } from "lodash";

export const LIST_TASK_STUDENT_COLUMN: LooseObject[] = [
  { title: "result", name: "result", type: "string", clickAble: true, typeSort: "object" },
  { title: "task_name", name: "name", type: "string", typeSort: "string" },
  { title: "contact", name: "contact", type: "string", typeSort: "object" },
  { title: "type", name: "type", type: "string", typeSort: "object" },
  { title: "pic", name: "assigned_to", type: "string", typeSort: "object" },
  { title: "deadline_at", name: "deadline_at", type: "dateTime", isPast: true, typeSort: "string" },
  { title: "created_at", name: "created_at", type: "dateTime", typeSort: "string" },
];

export const LIST_TASK_MASTER_COLUMN: LooseObject[] = [
  { title: "result", name: "result", type: "string", clickAble: true, typeSort: "object" },
  { title: "task_name", name: "name", type: "string", typeSort: "string" },
  { title: "contact", name: "contact", type: "string", typeSort: "object" },
  { title: "type", name: "type", type: "string", typeSort: "object" },
  { title: "pic", name: "assigned_to", type: "string", typeSort: "object" },
  { title: "deadline_at", name: "deadline_at", type: "dateTime", isPast: true, typeSort: "string" },
  { title: "created_at", name: "created_at", type: "dateTime", typeSort: "string" },
];

export const LIST_ROLE_COLUMN: LooseObject[] = [
  { title: "role_name", name: "name", type: "link", href: "/role/edit/{id}", hightLight: true, typeSort: "string" },
  { title: "number_of_user", name: "users_info", type: "numberOfarray", hightLight: true, typeSort: "array" },
  { title: "number_of_permission", name: "permissions", type: "numberOfarray", typeSort: "array" },
  { title: "created_date", name: "created_date", type: "date", typeSort: "date" },
  { title: "action", type: "action", show: ["pen"], permissionsPen: ["role_edit"], href: "/role/edit/{id}" },
];

export const LIST_USER_COLUMN: LooseObject[] = [
  { title: "full_name", name: "name", type: "link", href: "/user/{id}", hightLight: true, typeSort: "string" },
  { title: "email", name: "email", type: "link", href: "/user/{id}", hightLight: true, typeSort: "string" },
  { title: "type", name: "type", type: "typeUser", typeSort: "number" },
  { title: "role", name: "roles", type: "array", typeSort: "array" },
  { title: "created_date", name: "created_at", type: "date", typeSort: "date" },
  {
    title: "action",
    name: "status",
    type: "action",
    show: ["switchBtn", "pen", "bin"],
    permissionsPen: ["user_edit"],
    href: "/user/{id}",
    permissionsSwitch: ["user_disable"],
    permissionsDelete: ["user_delete"]
  },
];

export const LIST_STUDENT_CONTACT_COLUMN: LooseObject[] = [
  {
    title: "full_name",
    name: "full_name",
    nameDisplay: "full_name",
    type: "link",
    href: "/studentContact/{id}",
    hightLight: true,
    typeSort: "string",
  },
  {
    title: "phone_number",
    name: "phone_number",
    type: "phone",
    nameDisplay: "phone_number",
    permissionsCall: ["student_contact_call_global", "student_contact_call_own"],
    hasPermissionsCall: ["student_contact_call_global"],
    permissionsSms: ["student_contact_send_sms_own", "student_contact_send_sms_global"],
    hasPermissionsSms: ["student_contact_send_sms_global"],
    isStudent: true,
    typeSort: "string",
  },
  { title: "lead_source", name: "contact_source", type: "string", nameDisplay: "lead_source", typeSort: "object" },
  { title: "high_school", name: "high_school", type: "string", nameDisplay: "high_school", typeSort: "string" },
  { title: "note", name: "note", type: "string", nameDisplay: "note", typeSort: "string" },
  {
    title: "email",
    name: "email",
    type: "email",
    nameDisplay: "email",
    permissions: ["student_contact_send_email_own", "student_contact_send_email_global"],
    hasPermissions: ["student_contact_send_email_global"],
    isStudent: true,
    typeSort: "string",
  },
  { title: "pic", name: "user", type: "string", nameDisplay: "pic", typeSort: "object" },
  { title: "aspiration", name: "aspiration", type: "string", nameDisplay: "aspiration", typeSort: "aspiration" },
  { title: "date_created", name: "date_created", type: "date", nameDisplay: "date_created", typeSort: "date" },
  { title: "date_of_birth", name: "date_of_birth", type: "date", nameDisplay: "date_of_birth", typeSort: "string" },
  { title: "citizen_id", name: "citizen_id", type: "string", nameDisplay: "citizen_id", typeSort: "string" },
  { title: "country", name: "country", type: "string", nameDisplay: "country", typeSort: "object" },
  { title: "province_city", name: "city", type: "string", nameDisplay: "province_city", typeSort: "object" },
  { title: "district", name: "district", type: "string", nameDisplay: "district", typeSort: "object" },
  { title: "ward", name: "ward", type: "string", nameDisplay: "ward", typeSort: "object" },
  { title: "address", name: "address", type: "string", nameDisplay: "address", typeSort: "string" },
  {
    title: "subject_combination",
    name: "subject_combination",
    type: "string",
    nameDisplay: "subject_combination",
    typeSort: "object",
  },
  {
    title: "english_language_certificate",
    name: "english_language_certificate",
    type: "english_language_certificate",
    nameDisplay: "english_language_certificate",
    typeSort: "object",
  },
  {
    title: "japanese_language_certificate",
    name: "japan_language_certificate",
    type: "string",
    nameDisplay: "japanese_language_certificate",
    typeSort: "string",
  },
  {
    title: "source_information",
    name: "contact_url_source",
    type: "string",
    nameDisplay: "source_information",
    typeSort: "object",
  },
  { title: "call_status", name: "call_status", type: "string", nameDisplay: "call_status", typeSort: "object" },
  {
    title: "total_graduation_score",
    name: "total_graduation_score",
    type: "string",
    nameDisplay: "total_graduation_score",
    typeSort: "number",
  },
  {
    title: "competency_evaluation_score",
    name: "competency_evaluation_score",
    type: "string",
    nameDisplay: "competency_evaluation_score",
    typeSort: "number",
  },
  {
    title: "date_of_acceptance",
    name: "date_of_acceptance",
    type: "date",
    nameDisplay: "date_of_acceptance",
    typeSort: "string",
  },
  { title: "state", name: "contact_state", type: "string", nameDisplay: "state", typeSort: "object" },
  { title: "reject_reason", name: "reject_reason", type: "string", nameDisplay: "reject_reason", typeSort: "object" },
  {
    title: "action",
    type: "action",
    show: ["pen", "view"],
    permissionsPen: ["student_contact_edit"],
    href: "/studentContact/{id}",
  },
];

export const LIST_MASTER_CONTACT_COLUMN: LooseObject[] = [
  {
    title: "full_name",
    name: "full_name",
    nameDisplay: "full_name",
    type: "link",
    href: "/masterContact/{id}",
    hightLight: true,
    typeSort: "string",
  },
  {
    title: "phone_number",
    name: "phone_number",
    type: "phone",
    nameDisplay: "phone_number",
    permissionsCall: ["master_contact_call_global", "master_contact_call_own"],
    hasPermissionsCall: ["master_contact_call_global"],
    permissionsSms: ["master_contact_send_sms_own", "master_contact_send_sms_global"],
    hasPermissionsSms: ["master_contact_send_sms_global"],
    typeSort: "string",
  },
  { title: "lead_source", name: "contact_source", type: "string", nameDisplay: "lead_source", typeSort: "object" },
  { title: "high_school", name: "high_school", type: "string", nameDisplay: "high_school", typeSort: "string" },
  { title: "university", name: "university", type: "string", nameDisplay: "university", typeSort: "string" },
  { title: "discipline", name: "discipline", type: "string", nameDisplay: "discipline", typeSort: "string" },
  { title: "note", name: "note", type: "string", nameDisplay: "note", typeSort: "string" },
  {
    title: "email",
    name: "email",
    type: "email",
    nameDisplay: "email",
    permissions: ["master_contact_send_email_own", "master_contact_send_email_global"],
    hasPermissions: ["master_contact_send_email_global"],
    typeSort: "string",
  },
  { title: "pic", name: "user", type: "string", nameDisplay: "pic", typeSort: "object" },
  { title: "date_created", name: "date_created", type: "date", nameDisplay: "date_created", typeSort: "date" },
  { title: "date_of_birth", name: "date_of_birth", type: "date", nameDisplay: "date_of_birth", typeSort: "string" },
  { title: "citizen_id", name: "citizen_id", type: "string", nameDisplay: "citizen_id", typeSort: "string" },
  { title: "country", name: "country", type: "string", nameDisplay: "country", typeSort: "object" },
  { title: "province_city", name: "city", type: "string", nameDisplay: "province_city", typeSort: "object" },
  { title: "district", name: "district", type: "string", nameDisplay: "district", typeSort: "object" },
  { title: "ward", name: "ward", type: "string", nameDisplay: "ward", typeSort: "object" },
  { title: "address", name: "address", type: "string", nameDisplay: "address", typeSort: "string" },
  { title: "exam_subjects", name: "exam_subjects", type: "string", nameDisplay: "exam_subjects", typeSort: "object" },
  {
    title: "english_language_certificate",
    name: "english_language_certificate",
    type: "english_language_certificate",
    nameDisplay: "english_language_certificate",
    typeSort: "object",
  },
  {
    title: "japanese_language_certificate",
    name: "japan_language_certificate",
    type: "string",
    nameDisplay: "japanese_language_certificate",
    typeSort: "string",
  },
  {
    title: "source_information",
    name: "contact_url_source",
    type: "string",
    nameDisplay: "source_information",
    typeSort: "object",
  },
  { title: "call_status", name: "call_status", type: "string", nameDisplay: "call_status", typeSort: "object" },
  {
    title: "date_of_acceptance",
    name: "date_of_acceptance",
    type: "date",
    nameDisplay: "date_of_acceptance",
    typeSort: "string",
  },
  { title: "state", name: "contact_state", type: "string", nameDisplay: "state", typeSort: "object" },
  { title: "reject_reason", name: "reject_reason", type: "string", nameDisplay: "reject_reason", typeSort: "object" },
  {
    title: "action",
    type: "action",
    show: ["pen"],
    permissionsPen: ["master_contact_edit"],
    href: "/masterContact/{id}",
  },
];

export const STUDENT_LIST_COLUMN: LooseObject[] = [
  { title: "list_name", name: "name", type: "string", hightLight: true, typeSort: "string" },
  { title: "size", name: "size", type: "string", hightLight: true, typeSort: "number" },
  { title: "source", name: "source", type: "string", typeSort: "string" },
  { title: "unit", name: "unit_tags", type: "array", typeSort: "array" },
  { title: "last_updated", name: "last_updated", type: "date", typeSort: "date" },
  { title: "created_date", name: "created_date", type: "date", typeSort: "date" },
];

export const MASTER_LIST_COLUMN: LooseObject[] = [
  { title: "list_name", name: "name", type: "string", hightLight: true, typeSort: "string" },
  { title: "size", name: "size", type: "string", hightLight: true, typeSort: "number" },
  { title: "source", name: "source", type: "string", typeSort: "string" },
  { title: "unit", name: "unit_tags", type: "array", typeSort: "array" },
  { title: "last_updated", name: "last_updated", type: "date", typeSort: "date" },
  { title: "created_date", name: "created_date", type: "date", typeSort: "date" },
];

export const USER_TYPE: SelectDataProp[] = [
  { value: 0, name: "Standard" },
  { value: 1, name: "Social" },
];

export const USER_STATUS: SelectDataProp[] = [
  { value: 1, name: "Active" },
  { value: 0, name: "Inactive" },
];

export const STUDENT_CONTACT_CREATE_FIELD = [
  {
    title: "general_information",
    option: [
      { title: "full_name", name: "full_name", type: "text", required: true },
      { title: "email", name: "email", type: "email", required: true },
      { title: "phone_number", name: "phone_number", type: "number", required: true },
      { title: "date_of_birth", name: "date_of_birth", type: "date", required: false, isPast: true },
      { title: "country", name: "country_id", type: "select", option: "country", required: false },
      { title: "province_city", name: "city_id", type: "select", option: "city", required: false },
      { title: "district", name: "district_id", type: "select", option: "district", required: false },
      { title: "ward", name: "ward_id", type: "select", option: "ward", required: false },
      { title: "address", name: "address", type: "text", required: false },
      { title: "citizen_id", name: "citizen_id", type: "number", required: false },
      { title: "high_school", name: "high_school", type: "text", required: false },
    ],
  },
  {
    title: "educational_information",
    option: [
      { title: "competency_evaluation_score", name: "competency_evaluation_score", type: "number", required: false },
      { title: "total_graduation_score", name: "total_graduation_score", type: "number", required: false },
      { title: "english_language_certificate", name: "english_language_certificate", type: "text", required: false },
      { title: "japanese_language_certificate", name: "japan_language_certificate", type: "text", required: false },
      {
        title: "subject_combination",
        name: "subject_combination_id",
        type: "select",
        option: "subjectCombination",
        required: false,
      },
      { title: "other_certificate", name: "other_certificate", type: "text", required: false },
    ],
  },
  {
    title: "supporting_details",
    option: [
      { title: "source", name: "contact_source_id", type: "select", option: "source", required: false },
      { title: "contact_source_note", name: "contact_source_note", type: "text", required: false },
      { title: "source_note", name: "source_note", type: "text", required: false },
      { title: "pic", name: "user_id", type: "select", option: "pic", required: false },
      { title: "date_of_acceptance", name: "date_of_acceptance", type: "date", required: false, isPast: false },
      { title: "state", name: "contact_state_id", type: "select", option: "state", required: false },
      { title: "call_status", name: "call_status_id", type: "select", option: "callStatus", required: false },
    ],
  },
  {
    title: "aspiration_field",
    leftOption: [],
    rightOption: [{ title: "note", name: "note", type: "textarea", required: false }],
  },
];

export const STUDENT_CONTACT_DETAIL_FIELD = [
  {
    title: "general_information",
    option: [
      { title: "full_name", name: "full_name", type: "text", required: true },
      { title: "email", name: "email", type: "email", required: true },
      { title: "phone_number", name: "phone_number", type: "number", required: true },
      { title: "date_of_birth", name: "date_of_birth", type: "date", required: false, isPast: true },
      { title: "country", name: "country_id", type: "select", option: "country", required: false },
      { title: "province_city", name: "city_id", type: "select", option: "city", required: false },
      { title: "district", name: "district_id", type: "select", option: "district", required: false },
      { title: "ward", name: "ward_id", type: "select", option: "ward", required: false },
      { title: "address", name: "address", type: "text", required: false },
      { title: "citizen_id", name: "citizen_id", type: "number", required: false },
      { title: "high_school", name: "high_school", type: "text", required: false },
    ],
  },
  {
    title: "educational_information",
    option: [
      { title: "competency_evaluation_score", name: "competency_evaluation_score", type: "number", required: false },
      { title: "total_graduation_score", name: "total_graduation_score", type: "number", required: false },
      { title: "english_language_certificate", name: "english_language_certificate", type: "text", required: false },
      { title: "japanese_language_certificate", name: "japan_language_certificate", type: "text", required: false },
      {
        title: "subject_combination",
        name: "subject_combination_id",
        type: "select",
        option: "subjectCombination",
        required: false,
      },
      { title: "other_certificate", name: "other_certificate", type: "text", required: false },
    ],
  },
  {
    title: "supporting_details",
    option: [
      { title: "source", name: "contact_source_id", type: "select", option: "source", required: false },
      { title: "contact_source_note", name: "contact_source_note", type: "text", required: false },
      { title: "source_note", name: "source_note", type: "text", required: false },
      { title: "source_information", name: "contact_url_source_id", type: "text", required: false },
      { title: "pic", name: "user_id", type: "select", option: "pic", required: false },
      { title: "date_of_acceptance", name: "date_of_acceptance", type: "date", required: false, isPast: false },
      { title: "call_status", name: "call_status_id", type: "select", option: "callStatus", required: false },
    ],
  },
  {
    title: "aspiration_field",
    leftOption: [],
    rightOption: [{ title: "note", name: "note", type: "textarea", required: false }],
  },
];

export const STUDENT_CONTACT_FIELD_MAP_FILTER = [
  { filter: "filter_reject_reason_id", name: "reject_reason_id", type: "select" },
  { filter: "states", name: "contact_state_id", type: "array" },
  { filter: "filter_full_name", name: "full_name", type: "text" },
  { filter: "filter_email", name: "email", type: "text" },
  { filter: "filter_phone_number", name: "phone_number", type: "text" },
  // { filter: "date_of_birth", name: "date_of_birth", type: "text"},
  { filter: "countries", name: "country_id", type: "array" },
  { filter: "cities", name: "city_id", type: "array" },
  { filter: "districts", name: "district_id", type: "array" },
  // { filter: "ward", name: "ward_id", type: "array"},
  { filter: "filter_address", name: "address", type: "text" },
  { filter: "filter_citizen_id", name: "citizen_id", type: "text" },
  { filter: "filter_high_school", name: "high_school", type: "text" },
  { filter: "filter_competency_evaluation_score", name: "competency_evaluation_score", type: "text" },
  { filter: "filter_total_graduation_score", name: "total_graduation_score", type: "text" },
  { filter: "filter_english_language_certificate", name: "english_language_certificate", type: "text" },
  { filter: "filter_japanese_language_certificate", name: "japan_language_certificate", type: "text" },
  { filter: "filter_subject_combination_id", name: "subject_combination_id", type: "select" },
  { filter: "filter_contact_source_id", name: "contact_source_id", type: "select" },
  { filter: "filter_pic", name: "user_id", type: "select" },
  { filter: "filter_date_of_acceptance", name: "date_of_acceptance", type: "text" },
  { filter: "filter_call_status_id", name: "call_status_id", type: "select" },
  { filter: "filter_note", name: "note", type: "text" },
  { filter: "text", name: "all", type: "all" },
  // { filter: "aspiration_registered", name: "aspiration_registered", type: "array",},
];

export const MASTER_CONTACT_CREATE_FIELD = [
  {
    title: "general_information",
    option: [
      { title: "full_name", name: "full_name", type: "text", required: true },
      { title: "email", name: "email", type: "email", required: true },
      { title: "phone_number", name: "phone_number", type: "number", required: true },
      { title: "date_of_birth", name: "date_of_birth", type: "date", required: false, isPast: true },
      { title: "country", name: "country_id", type: "select", option: "country", required: false },
      { title: "province_city", name: "city_id", type: "select", option: "city", required: false },
      { title: "district", name: "district_id", type: "select", option: "district", required: false },
      { title: "ward", name: "ward_id", type: "select", option: "ward", required: false },
      { title: "address", name: "address", type: "text", required: false },
      { title: "citizen_id", name: "citizen_id", type: "number", required: false },
      { title: "high_school", name: "high_school", type: "text", required: false },
    ],
  },
  {
    title: "educational_information",
    option: [
      { title: "university", name: "university", type: "text", required: false },
      { title: "discipline", name: "discipline", type: "text", required: false },
      { title: "english_language_certificate", name: "english_language_certificate", type: "text", required: false },
      { title: "japanese_language_certificate", name: "japan_language_certificate", type: "text", required: false },
      {
        title: "exam_subjects",
        name: "exam_subjects_id",
        type: "select",
        option: "subjectCombination",
        required: false,
      },
      { title: "other_certificate", name: "other_certificate", type: "text", required: false },
    ],
  },
  {
    title: "supporting_details",
    option: [
      { title: "source", name: "contact_source_id", type: "select", option: "source", required: false },
      { title: "contact_source_note", name: "contact_source_note", type: "text", required: false },
      { title: "source_note", name: "source_note", type: "text", required: false },
      { title: "pic", name: "user_id", type: "select", option: "pic", required: false },
      { title: "date_of_acceptance", name: "date_of_acceptance", type: "date", required: false, isPast: false },
      { title: "state", name: "contact_state_id", type: "select", option: "state", required: false },
      { title: "call_status", name: "call_status_id", type: "select", option: "callStatus", required: false },
    ],
  },
  {
    title: "aspiration_field",
    leftOption: [],
    rightOption: [{ title: "note", name: "note", type: "textarea", required: false }],
  },
];

export const MASTER_CONTACT_DETAIL_FIELD = [
  {
    title: "general_information",
    option: [
      { title: "full_name", name: "full_name", type: "text", required: true },
      { title: "email", name: "email", type: "email", required: true },
      { title: "phone_number", name: "phone_number", type: "number", required: true },
      { title: "date_of_birth", name: "date_of_birth", type: "date", required: false, isPast: true },
      { title: "country", name: "country_id", type: "select", option: "country", required: false },
      { title: "province_city", name: "city_id", type: "select", option: "city", required: false },
      { title: "district", name: "district_id", type: "select", option: "district", required: false },
      { title: "ward", name: "ward_id", type: "select", option: "ward", required: false },
      { title: "address", name: "address", type: "text", required: false },
      { title: "citizen_id", name: "citizen_id", type: "number", required: false },
      { title: "high_school", name: "high_school", type: "text", required: false },
    ],
  },
  {
    title: "educational_information",
    option: [
      { title: "university", name: "university", type: "text", required: false },
      { title: "discipline", name: "discipline", type: "text", required: false },
      { title: "english_language_certificate", name: "english_language_certificate", type: "text", required: false },
      { title: "japanese_language_certificate", name: "japan_language_certificate", type: "text", required: false },
      {
        title: "exam_subjects",
        name: "exam_subjects_id",
        type: "select",
        option: "subjectCombination",
        required: false,
      },
      { title: "other_certificate", name: "other_certificate", type: "text", required: false },
    ],
  },
  {
    title: "supporting_details",
    option: [
      { title: "source", name: "contact_source_id", type: "select", option: "source", required: false },
      { title: "contact_source_note", name: "contact_source_note", type: "text", required: false },
      { title: "source_note", name: "source_note", type: "text", required: false },
      { title: "source_information", name: "contact_url_source_id", type: "text", required: false },
      { title: "pic", name: "user_id", type: "select", option: "pic", required: false },
      { title: "date_of_acceptance", name: "date_of_acceptance", type: "date", required: false, isPast: false },
      { title: "call_status", name: "call_status_id", type: "select", option: "callStatus", required: false },
    ],
  },
  {
    title: "aspiration_field",
    leftOption: [],
    rightOption: [{ title: "note", name: "note", type: "textarea", required: false }],
  },
];

export const MASTER_CONTACT_FIELD_MAP_FILTER = [
  { filter: "filter_reject_reason_id", name: "reject_reason_id", type: "select" },
  { filter: "states", name: "contact_state_id", type: "array" },
  { filter: "filter_full_name", name: "full_name", type: "text" },
  { filter: "filter_email", name: "email", type: "text" },
  { filter: "filter_phone_number", name: "phone_number", type: "text" },
  // { filter: "date_of_birth", name: "date_of_birth", type: "text"},
  { filter: "countries", name: "country_id", type: "array" },
  { filter: "cities", name: "city_id", type: "array" },
  { filter: "districts", name: "district_id", type: "array" },
  // { filter: "ward", name: "ward_id", type: "array"},
  { filter: "filter_address", name: "address", type: "text" },
  { filter: "filter_citizen_id", name: "citizen_id", type: "text" },
  { filter: "filter_high_school", name: "high_school", type: "text" },
  { filter: "filter_university", name: "university", type: "text" },
  { filter: "filter_discipline", name: "discipline", type: "text" },
  { filter: "filter_english_language_certificate", name: "english_language_certificate", type: "text" },
  { filter: "filter_japanese_language_certificate", name: "japan_language_certificate", type: "text" },
  { filter: "filter_exam_subject_id", name: "exam_subjects_id", type: "select" },
  { filter: "filter_contact_source_id", name: "contact_source_id", type: "select" },
  { filter: "filter_pic", name: "user_id", type: "select" },
  { filter: "filter_date_of_acceptance", name: "date_of_acceptance", type: "text" },
  { filter: "filter_call_status_id", name: "call_status_id", type: "select" },
  { filter: "filter_note", name: "note", type: "text" },
  { filter: "text", name: "all", type: "all" },
  // { filter: "aspiration_registered", name: "aspiration_registered", type: "array",},
];

export const FILTER_STUDENT_CONTACT_FIELD = {
  option: [
    { title: "filter_contact_list_id", name: "filter_contact_list_id", type: "select", option: "list" },
    { title: "filter_full_name", name: "filter_full_name", type: "text" },
    { title: "filter_phone_number", name: "filter_phone_number", type: "number" },
    { title: "filter_citizen_id", name: "filter_citizen_id", type: "number" },
    { title: "country", name: "countries", type: "select", option: "country", isMultiple: true },
    { title: "province_city", name: "cities", type: "select", option: "city", isMultiple: true, isSubOption: true },
    { title: "district", name: "districts", type: "select", option: "district", isMultiple: true, isSubOption: true },
    { title: "filter_address", name: "filter_address", type: "text" },
    { title: "filter_aspiration_1", name: "filter_aspiration_1", type: "select", option: "aspiration" },
    { title: "filter_aspiration_2", name: "filter_aspiration_2", type: "select", option: "aspiration" },
    { title: "filter_aspiration_3", name: "filter_aspiration_3", type: "select", option: "aspiration" },
    { title: "filter_note", name: "filter_note", type: "text" },
    { title: "filter_competency_evaluation_score", name: "filter_competency_evaluation_score", type: "number" },
    { title: "filter_total_graduation_score", name: "filter_total_graduation_score", type: "number" },
    { title: "filter_english_language_certificate", name: "filter_english_language_certificate", type: "text" },
    { title: "filter_japan_language_certificate", name: "filter_japan_language_certificate", type: "text" },
    { title: "filter_high_school", name: "filter_high_school", type: "text" },
    {
      title: "filter_subject_combination_id",
      name: "filter_subject_combination_id",
      type: "select",
      option: "subjectCombination",
      required: false,
    },
    { title: "filter_contact_source_id", name: "filter_contact_source_id", type: "select", option: "source" },
    { title: "filter_contact_source_note", name: "filter_contact_source_note", type: "text" },
    { title: "filter_pic", name: "filter_pic", type: "select", option: "pic" },
    { title: "filter_contact_has_task_with_user_id", name: "contact_has_task_with_user_id", type: "select", option: "taskAssigned" },
    {
      title: "filter_date_of_acceptance", name: "filter_date_of_acceptance", type: "exclude_emailed_contact",
      start_time: {
        title: "filter_acceptance_from",
        name: "filter_acceptance_from",
        type: "date",
      },
      end_time: {
        title: "filter_acceptance_to",
        name: "filter_acceptance_to",
        type: "date",
      }
    },
    { title: "status", name: "statuses", type: "select", option: "status", isMultiple: true },
    { title: "state", name: "states", type: "select", option: "state", isMultiple: true },
    { title: "filter_reject_reason_id", name: "filter_reject_reason_id", type: "select", option: "rejectReason" },
    { title: "filter_call_status_id", name: "filter_call_status_id", type: "select", option: "callStatus" },
    {
      title: "filter_exclude_emailed_contact", name: "filter_exclude_emailed_contact", type: "exclude_emailed_contact",
      start_time: {
        title: "filter_emailed_from",
        name: "filter_emailed_from",
        type: "date",
      },
      end_time: {
        title: "filter_emailed_to",
        name: "filter_emailed_to",
        type: "date",
      }
    },
    { title: "filter_email", name: "filter_email", type: "text", required: true },
    { title: "year_of_concern", name: "year_of_concern", type: "string", required: false }
  ],
};

export const FILTER_ONLINE_ADMISSION_FIELD = {
  option: [
    { title: "filter_full_name", name: "filter_full_name", type: "text" },
    { title: "filter_phone_number", name: "filter_phone_number", type: "number" },
    { title: "filter_citizen_id", name: "filter_citizen_id", type: "number" },
    { title: "country", name: "countries", type: "select", option: "country", isMultiple: true },
    { title: "province_city", name: "cities", type: "select", option: "city", isMultiple: true, isSubOption: true },
    { title: "district", name: "districts", type: "select", option: "district", isMultiple: true, isSubOption: true },
    { title: "filter_address", name: "filter_address", type: "text" },
    { title: "aps", name: "aspiration_ids", type: "select", option: "aspirations"},
    { title: "admission_method", name: "admission_method_ids", type: "select", option: "admissionMethods"},
    { title: "is_paid", name: "is_paid", type: "select", option: "isPaid" },
    { title: "online_profile", name: "online_profile", type: "select", option: "profileStatus" },
    { title: "offline_profile", name: "offline_profile", type: "select", option: "profileStatus" },
    {
      title: "created_date", name: "created_at",type: "exclude_emailed_contact",
      start_time: {
        title: "filter_acceptance_from",
        name: "created_at_from",
        type: "date",
      },
      end_time: {
        title: "filter_acceptance_to",
        name: "created_at_to",
        type: "date",
      }
    },
    { title: "admission_round", name: "admission_period_ids", type: "select", option: "admissionPeriods" },
    { title: "update_status", name: "updated_profile", type: "select", option: "isUpdate" },
  ],
};

export const FILTER_MASTER_CONTACT_FIELD = {
  option: [
    { title: "filter_contact_list_id", name: "filter_contact_list_id", type: "select", option: "list" },
    { title: "filter_full_name", name: "filter_full_name", type: "text" },
    { title: "filter_email", name: "filter_email", type: "text", required: true },
    { title: "filter_phone_number", name: "filter_phone_number", type: "number" },
    { title: "filter_citizen_id", name: "filter_citizen_id", type: "number" },
    { title: "country", name: "countries", type: "select", option: "country", isMultiple: true },
    { title: "province_city", name: "cities", type: "select", option: "city", isMultiple: true, isSubOption: true },
    { title: "district", name: "districts", type: "select", option: "district", isMultiple: true, isSubOption: true },
    { title: "filter_address", name: "filter_address", type: "text" },
    { title: "filter_aspiration_1", name: "filter_aspiration_1", type: "select", option: "aspiration" },
    { title: "filter_university", name: "filter_university", type: "text" },
    { title: "filter_discipline", name: "filter_discipline", type: "text" },
    { title: "filter_note", name: "filter_note", type: "text" },
    { title: "filter_english_language_certificate", name: "filter_english_language_certificate", type: "text" },
    { title: "filter_japan_language_certificate", name: "filter_japan_language_certificate", type: "text" },
    { title: "filter_high_school", name: "filter_high_school", type: "text" },
    {
      title: "filter_exam_subject_id",
      name: "filter_exam_subject_id",
      type: "select",
      option: "subjectCombination",
      required: false,
    },
    { title: "filter_contact_source_id", name: "filter_contact_source_id", type: "select", option: "source" },
    { title: "filter_contact_source_note", name: "filter_contact_source_note", type: "text" },
    { title: "filter_pic", name: "filter_pic", type: "select", option: "pic" },
    { title: "filter_contact_has_task_with_user_id", name: "contact_has_task_with_user_id", type: "select", option: "taskAssigned" },
    { title: "filter_date_of_acceptance", name: "filter_date_of_acceptance", type: "date" },
    { title: "status", name: "statuses", type: "select", option: "status", isMultiple: true },
    { title: "state", name: "states", type: "select", option: "state", isMultiple: true },
    { title: "filter_reject_reason_id", name: "filter_reject_reason_id", type: "select", option: "rejectReason" },
    { title: "filter_call_status_id", name: "filter_call_status_id", type: "select", option: "callStatus" },
  ],
};

export const ACTION_STUDENT_CONTACT: string[] = [
  "delete",
  "export",
  "mass_update",
  "send_email",
  "send_sms",
  "add_to_target_list",
  "add_task",
];

export const ACTION_STUDENT_CONTACT_TRASH: string[] = ["restore", "delete_completely"];

export const ACTION_MASTER_CONTACT_TRASH: string[] = ["restore", "delete_completely"];

export const ACTION_MASTER_CONTACT: string[] = [
  "delete",
  "export",
  "mass_update",
  "send_email",
  "send_sms",
  "add_to_target_list",
  "add_task",
];

export const ACTION_STUDENT_LIST: string[] = ["edit", "delete", "export"];

export const ACTION_MASTER_LIST: string[] = ["edit", "delete", "export"];

export const STATUS_APPLICATION_STUDENT: LooseObject[] = [
  { id: "0", name: "Disapproved" },
  { id: "1", name: "Approved" },
];

export const MODULE_FIELD_STUDENT: LooseObject[] = [
  { label: "full_name", name: "full_name", require: true, defaultIndex: 0 },
  { label: "email", name: "email", require: true, defaultIndex: 1 },
  { label: "phone_number", name: "phone_number", require: true, defaultIndex: 2 },
  { label: "subject_combination", name: "subject_combination_id", require: false, defaultIndex: 3 },
  { label: "citizen_id", name: "citizen_id", require: false, defaultIndex: 4 },
  { label: "total_graduation_score", name: "total_graduation_score", require: false, defaultIndex: 5 },
  { label: "english_language_certificate_ielts", name: "english_language_certificate_ielts", require: false, defaultIndex: 6 },
  { label: "english_language_certificate_toefl", name: "english_language_certificate_toefl", require: false, defaultIndex: 7 },
  { label: "japanese_language_certificate", name: "japan_language_certificate", require: false, defaultIndex: 8 },
  { label: "source", name: "contact_source_id", require: false, defaultIndex: 9 },
  { label: "source_note", name: "contact_source_note", require: false, defaultIndex: 10 },
  { label: "call_status", name: "call_status_id", require: false, defaultIndex: 11 },
  { label: "city", name: "city_id", require: false, defaultIndex: 12 },
  { label: "district", name: "district_id", require: false, defaultIndex: 13 },
  { label: "address", name: "address", require: false, defaultIndex: 14 },
  { label: "country", name: "country_id", require: false, defaultIndex: 15 },
  { label: "high_school", name: "high_school", require: false, defaultIndex: 16 },
  { label: "competency_evaluation_score", name: "competency_evaluation_score", require: false, defaultIndex: 17 },
  { label: "date_of_acceptance", name: "date_of_acceptance", require: false, defaultIndex: 18 },
  { label: "note", name: "note", require: false, defaultIndex: 19 },
  { label: "aspiration", name: "aspiration", require: false, defaultIndex: 20 },
  { label: "aspiration_status", name: "aspiration_status", require: false, defaultIndex: 21 }
];

export const MODULE_FIELD_MASTER: LooseObject[] = [
  { label: "full_name", name: "full_name", require: true, defaultIndex: 0 },
  { label: "email", name: "email", require: true, defaultIndex: 1 },
  { label: "phone_number", name: "phone_number", require: true, defaultIndex: 2 },
  { label: "citizen_id", name: "citizen_id", require: false, defaultIndex: 3 },
  { label: "exam_subjects", name: "exam_subjects_id", require: false, defaultIndex: 4 },
  { label: "university", name: "university", require: false, defaultIndex: 5 },
  { label: "discipline", name: "discipline", require: false, defaultIndex: 6 },
  { label: "english_language_certificate", name: "english_language_certificate", require: false, defaultIndex: 7 },
  { label: "japanese_language_certificate", name: "japan_language_certificate", require: false, defaultIndex: 8 },
  { label: "source", name: "contact_source_id", require: false, defaultIndex: 9 },
  { label: "call_status", name: "call_status_id", require: false, defaultIndex: 10 },
  { label: "city", name: "city_id", require: false, defaultIndex: 11 },
  { label: "district", name: "district_id", require: false, defaultIndex: 12 },
  { label: "address", name: "address", require: false, defaultIndex: 13 },
  { label: "country", name: "country_id", require: false, defaultIndex: 14 },
  { label: "date_of_acceptance", name: "date_of_acceptance", require: false, defaultIndex: 15 },
  { label: "note", name: "note", require: false, defaultIndex: 16 },
  { label: "high_school", name: "high_school", require: false, defaultIndex: 17 },
];

export const STUDENT_CONTACT_TYPE = 1;
export const MASTER_CONTACT_TYPE = 2;

export const CALL_LOG_TYPE = [
  { label: "Student", value: STUDENT_CONTACT_TYPE },
  { label: "Master", value: MASTER_CONTACT_TYPE },
  { label: "Unknown", value: 0 },
  { label: "CandidateContact", value: 3 }
];

export const NOTIFICATION_TYPE = {
  importStudentContact: "IMPORT_STUDENT_CONTACT",
  importMasterContact: "IMPORT_MASTER_CONTACT",
  emailBatchCompleted: "EMAIL_BATCH_COMPLETED",
  smsBatchCompleted: "SMS_BATCH_COMPLETED",
  taskSupportRequest: "TASK_SUPPORT_REQUEST",
  importmoneytransferhistory: "IMPORT_MONEY_TRANSFER_HISTORY",
};

export const ID_SOURCE_NHTS = 17;

export const JAPANESE_LANGUAGE_CERTIFICATE = [
  { label: "N1", value: "N1" },
  { label: "N2", value: "N2" },
  { label: "N3", value: "N3" },
  { label: "N4", value: "N4" },
  { label: "N5", value: "N5" },
];

export const IELTS_MARKS = [
  { label: "4.5", value: 4.5 },
  { label: "5.0", value: 5.0 },
  { label: "5.5", value: 5.5 },
  { label: "6.0", value: 6.0 },
  { label: "6.5", value: 6.5 },
  { label: "7.0", value: 7.0 },
  { label: "7.5", value: 7.5 },
  { label: "8.0", value: 8.0 },
  { label: "8.5", value: 8.5 },
  { label: "9.0", value: 9.0 },
];

export const TASK_TYPE = 9;
export const INIT_COUNTRY_ISO_VN_DEFAULT = "VN";
export const COUNTRY_ID_VN_DEFAULT = 240;

export const ADMISSION_FIELD: TableFields[] = [
  { title: "full_name", name: "full_name", type: "text", required: true },
  { title: "date_of_birth", name: "date_of_birth", type: "date", required: true, isPast: true, isFuture: false },
  { title: "gender", name: "gender", type: "select", option: "gender", required: true },
  { title: "citizen_id", name: "citizen_id", type: "text", required: true },
  { title: "email", name: "email", type: "text", required: true },
  { title: "phone_number", name: "phone_number", type: "text", required: true },
  { title: "permanent_address", name: "address", type: "text", required: true },
  { title: "country", name: "country_id", type: "select", option: "country", required: true },
  { title: "province_city", name: "city_id", type: "select", option: "city", required: true },
  { title: "district", name: "district_id", type: "select", option: "district", required: true },
  { title: "high_school", name: "highschool_id", type: "select", option: "highSchool", required: true },
];

export const ACADEMIC_RESULTS = [
  { key: "math" },
  { key: "physics" },
  { key: "chemistry" },
  { key: "biology" },
  { key: "literature" },
  { key: "history" },
  { key: "geography" },
  { key: "foreign_language" },
  { key: "civic_education" },
];

const GRADE = ["10", "11", "12"];

const SEMESTER = ["HK1", "HK2"];

export const GRADE_HIGH_SCHOOL = flatMap(GRADE, (key) =>
  SEMESTER.map((item) => ({ key: `L${key}${item}`, label: item[item.length - 1], grade: key }))
);

export const CANDIDATES_CONTACT_COLUMNS = [
  { title: "full_name", name: "full_name", type: "string", hightLight: true, typeSort: "string" },
  { title: "phone_number", name: "phone_number", type: "phone_online_admission", typeSort: "string" },
  {
    title: "email", name: "email", type: "email", hightLight: true, typeSort: "string", isStudent: true, hasPermissions: ["student_contact_send_email_global"], permissions: [
      "student_contact_send_email_own",
      "student_contact_send_email_global"
    ]
  },
  { title: "citizen_id", name: "citizen_id", type: "string", typeSort: "string" },
  { title: "aps", name: "aspiration", type: "string", typeSort: "object" },
  { title: "admission_method", name: "admission_method", type: "string", typeSort: "object" },
  { title: "is_paid", name: "is_paid", type: "boolean", typeSort: "number", permissions: ["online_admission_edit"] },
  { title: "online_profile", name: "online_profile_status", type: "boolean", typeSort: "number" },
  { title: "offline_profile", name: "offline_profile_status", type: "boolean", typeSort: "number" },
  { title: "created_at", name: "created_at", type: "string", typeSort: "string" },
  { title: "score", name: "score", type: "string", typeSort: "number" },
  { title: "admission_round", name: "admission_period", type: "roundPeriod", typeSort: "object" },
  { title: "update_status", name: "update_status", type: "boolean", typeSort: "number" },
  {
    title: "action",
    type: "action",
    show: ["pen", "view"],
    href: "/edit-documents?token=",
    permissionsPen: ["online_admission_edit"],
    token: true,
  },
];
export const ACTION_CANDIDATES_LIST: string[] = ["delete", "export"];

export const STUDENT_APPLICATION_COLUMNS = [
  { title: "full_name", name: "full_name", type: "string", hightLight: true, typeSort: "string" },
  { title: "profile_code", name: "profile_code", type: "profile_code", hightLight: true, typeSort: "string" },
  { title: "phone_number", name: "phone_number", type: "phone_number", typeSort: "string" },
  { title: "citizen_id", name: "citizen_id", type: "string", typeSort: "string" },
  { title: "admission_method", name: "admission_method", type: "string", typeSort: "object" },
  { title: "aps", name: "aspiration", type: "string", typeSort: "object" },
  { title: "online_profile", name: "online_profile_status", type: "boolean", typeSort: "number" },
  { title: "offline_profile", name: "offline_profile_status", type: "boolean", typeSort: "number" },
  { title: "update_status", name: "update_status", type: "boolean", typeSort: "number" },
  { title: "note", name: "review_notes", type: "note", nameDisplay: "note" },
];

export const GENDER = [
  { id: 1, name: "Nam" },
  { id: 2, name: "Nữ" },
  { id: 3, name: "Khác" },
];

export const LANGUAGESKILL = [
  { id: 1, name: "Tiếng Anh" },
  { id: 2, name: "Tiếng Nhật" },
];

export const ACEDEMY_CONDUCT = [
  { id: 1, name: "Tốt" },
  { id: 2, name: "Khá" },
  { id: 3, name: "Trung bình" },
  { id: 4, name: "Yếu" },
];

export const ACEDEMY_PERFORMANCE = [
  { id: 1, name: "Giỏi" },
  { id: 2, name: "Khá" },
  { id: 3, name: "Trung bình" },
  { id: 4, name: "Yếu" },
];

export const FIELD_STUDY_RESULT = [
  {
    id: 1,
    grade: "LỚP 10",
    semester: "HỌC KÌ I",
    mobileSemester: "HKI",
    performance: "academy_performance_10_1",
    conduct: "academy_conduct_10_1",
    field: 10,
  },
  {
    id: 2,
    grade: "",
    semester: "HỌC KÌ II",
    mobileSemester: "HKII",
    performance: "academy_performance_10_2",
    conduct: "academy_conduct_10_2",
    field: 10,
  },
  {
    id: 3,
    grade: "LỚP 11",
    semester: "HỌC KÌ I",
    mobileSemester: "HKI",
    performance: "academy_performance_11_1",
    conduct: "academy_conduct_11_1",
    field: 11,
  },
  {
    id: 4,
    grade: "",
    semester: "HỌC KÌ II",
    mobileSemester: "HKII",
    performance: "academy_performance_11_2",
    conduct: "academy_conduct_11_2",
    field: 11,
  },
  {
    id: 5,
    grade: "LỚP 12",
    semester: "HỌC KÌ I",
    mobileSemester: "HKI",
    performance: "academy_performance_12_1",
    conduct: "academy_conduct_12_1",
    field: 12,
  },
  {
    id: 6,
    grade: "",
    semester: "HỌC KÌ II",
    mobileSemester: "HKII",
    performance: "academy_performance_12_2",
    conduct: "academy_conduct_12_2",
    field: 12,
  },
];

export const programs = [
  { id: 1, name_vi: "BJS" },
  { id: 2, name_vi: "BCSE" },
  { id: 3, name_vi: "ECE" },
  { id: 4, name_vi: "EMJM" },
  { id: 5, name_vi: "ESAS" },
  { id: 6, name_vi: "EFTH" },
  { id: 15, name_vi: "GBJS" },
  { id: 16, name_vi: "ESCT" },
  { id: 17, name_vi: "EASC" },
];

export const admissionMethods = [
  { id: 1, name_vi: "Đánh giá hồ sơ năng lực và phỏng vấn" },
  { id: 2, name_vi: "Chứng chỉ các kỳ thi (SAT/ACT/A-Level)" },
  { id: 3, name_vi: "Chứng chỉ ngoại ngữ kết hợp điểm thi TN THPT" },
  { id: 4, name_vi: "Điểm thi đánh giá năng lực của ĐHQGHN" },
  { id: 5, name_vi: "Xét tuyển thẳng và ưu tiên xét tuyển" },
  { id: 6, name_vi: "Xét điểm thi TN THPT" },
];
