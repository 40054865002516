export const admissionImageTypes: Record<number, string[]> = {
	1: ['citizen_image', 'transcript_image', 'language_certificate_image', 'personal_image', 'award_image', 'fee_payment_proof_image'],
	2: ['citizen_image', 'transcript_image', 'national_certificate_image', 'personal_image', 'award_image', 'fee_payment_proof_image'],
	3: ['citizen_image', 'transcript_image', 'ielts_toefl_certificate_image', 'personal_image', 'award_image', 'fee_payment_proof_image'],
	4: ['citizen_image', 'transcript_image', 'competency_assessment_score_image', 'personal_image', 'award_image', 'fee_payment_proof_image'],
	5: ['citizen_image', 'transcript_image', 'personal_image', 'award_image', 'fee_payment_proof_image'],
	6: ['citizen_image', 'highschool_graduation_exam_test_result_image', 'transcript_image', 'personal_image', 'extra_international_certificate_images', 'fee_payment_proof_image'],
};

export const imageTitles: Record<string, string> = {
	citizen_image: "Ảnh căn cước công dân / Citizen ID images",
	transcript_image: "Học bạ / Transcript images",
	language_certificate_image: "Chứng chỉ ngoại ngữ / Language certificates",
	national_certificate_image: "Chứng chỉ quốc tế (SAT, ACT, A-Level) / International certificate images (SAT, ACT, A-Level)",
	ielts_toefl_certificate_image: "Chứng chỉ ngoại ngữ (IELTS, TOEFL, JLPT, NATTEST) / Language certificates (IELTS, TOEFL, JLPT, NATTEST)",
	competency_assessment_score_image: "Điểm đánh giá năng lực / Competency assessment score images",
	personal_image: "Ảnh cá nhân (4x6cm) / Personal images (4x6cm)",
	award_image: "Các giải thưởng giấy tờ ưu tiên khác / Other certificate images",
	fee_payment_proof_image: "Minh chứng chuyển khoản lệ phí / Fee payment proof images",
	highschool_graduation_exam_test_result_image: "Minh chứng điểm thi THPT và Số báo danh / High-school graduation result and candidate id",
	extra_international_certificate_images: "Chứng chỉ ngoại ngữ (IELTS, TOEFL, Aptis, VSTEP, JLPT) / International certificates (IELTS, TOEFL, Aptis, VSTEP, JLPT)",
};
export const reviewTitles: Record<string, string> = {
	citizen_image: "Tình trạng mục ảnh CCCD",
	transcript_image: "Tình trạng mục học bạ",
	language_certificate_image: "Tình trạng mục chứng chỉ ngoại ngữ",
	national_certificate_image: "Tình trạng mục chứng chỉ quốc tế",
	ielts_toefl_certificate_image: "Tình trạng mục chứng chỉ ngoại ngữ (IELTS, TOEFL, JLPT, NATTEST)",
	competency_assessment_score_image: "Tình trạng mục điểm đánh giá năng lực",
	personal_image: "Tình trạng mục ảnh cá nhân ",
	award_image: "Tình trạng mục các giải thưởng",
	fee_payment_proof_image: "Tình trạng mục chuyển khoản lệ phí",
	highschool_graduation_exam_test_result_image: "Tình trạng mục điểm thi THPT",
	extra_international_certificate_images: "Tình trạng mục chứng chỉ ngoại ngữ (IELTS, TOEFL, Aptis, VSTEP, JLPT)",
};
