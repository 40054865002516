import { LinkOutlined, LogoutOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { LooseObject } from "models/common";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  socketListenEmailBatchJobFinished,
  socketListenImport,
  socketListenSmsBatchJobFinished,
  socketListenTaskSupportRequest,
} from "services/socketClient";
import { getAllNotification, getNotifyById } from "store/notifcationSlice";
import { MASTER_CONTACT_TYPE, NOTIFICATION_TYPE, STUDENT_CONTACT_TYPE } from "utils";
import logo from "../../assets/images/deha-vietnam-logo_w.webp";
import iconEnSquare from "../../assets/images/englandSquare.png";
import iconJPSquare from "../../assets/images/japaneseSquare.jpg";
import iconVnSquare from "../../assets/images/vietnamSquare.png";
import { getUserInfo } from "../../redux/auth/auth.service";
import { logout } from "../../redux/auth/auth.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import DropdownMenu from "../DropdownMenu";
import Sidebar from "./../Sidebar/Sidebar";
import { useStyles } from "./styles";
import logoVJU from "../../assets/images/logo_Vju.svg";

export const pages = [
  {
    label: "contacts",
    name: "contacts",
    path: "/contacts",
    permissions: [
      "master_contact_view_own",
      "master_contact_view_global",
      "student_contact_view_own",
      "student_contact_view_global",
      "student_list_view_own",
      "student_list_view_global",
      "master_list_view_own",
      "master_list_view_global",
    ],
    options: [
      {
        label: "students",
        path: "/studentContact",
        permissions: ["student_contact_view_own", "student_contact_view_global"],
      },
      {
        label: "masters",
        path: "/masterContact",
        permissions: ["master_contact_view_own", "master_contact_view_global"],
      },
      {
        label: "student_lists",
        path: "/studentList",
        permissions: ["student_list_view_own", "student_list_view_global"],
      },
      {
        label: "master_lists",
        path: "/masterList",
        permissions: ["master_list_view_own", "master_list_view_global"],
      },
    ],
  },
  {
    label: "reminder_management",
    name: "tasksManagement",
    path: "/tasksManagement",
    permissions: ["reminder_management_view"],
    options: [
      { label: "students", path: "/student-tasks", permissions: ["reminder_management_view"] },
      { label: "masters", path: "/master-tasks", permissions: ["reminder_management_view"] },
    ],
  },
  {
    label: "reports",
    name: "reports",
    path: "/reports",
    permissions: ["report_view"],
    options: [],
  },
  {
    label: "settings",
    name: "settings",
    path: "/role",
    permissions: ["role_view", "user_view"],
    options: [
      { label: "role", path: "/role", permissions: ["role_view"] },
      { label: "user", path: "/user", permissions: ["user_view"] },
      { label: "student_admission_period", path: "/student-admission-period", permissions: ["online_admission_view"] },
    ],
  },
  {
    label: "online_admissions",
    name: "onlineAdmission",
    path: "/onlineAdmission",
    permissions: ["online_admission_view"],
    options: [],
  },
  {
    label: "student_application",
    name: "studentApplication",
    path: "/studentApplication",
    permissions: ["online_admission_view"],
    options: [],
  },
];

function NavBar() {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null);
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const listNotifies = useSelector((state: RootState) => state.notification.list);
  const newNotify = useSelector((state: RootState) => state.notification.newNotify);
  const currentLanguage = i18n.language;
  const location = useLocation();

  const initialized = useRef(false);
  useEffect(() => {
    if (userInfo && !initialized.current) {
      initialized.current = true;
      dispatch(getAllNotification());
      socketListenImport(userInfo?.user?.id, (data: any) => {
        dispatch(getAllNotification());
      });
      socketListenEmailBatchJobFinished(userInfo?.user?.id, (data: any) => {
        dispatch(getAllNotification());
      });
      socketListenSmsBatchJobFinished(userInfo?.user?.id, (data: any) => {
        dispatch(getAllNotification());
      });
      socketListenTaskSupportRequest(userInfo?.user?.id, (data: any) => {
        dispatch(getAllNotification());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (location.pathname !== "/registerForm" && !location.pathname.includes("edit-documents") && !userInfo) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, userInfo]);

  const handleOpenNavMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    },
    [setAnchorElNav]
  );
  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [setAnchorElUser]
  );

  const handleOpenNotification = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNotification(event.currentTarget);
    },
    [setAnchorElNotification]
  );

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, [setAnchorElNav]);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  const handleCloseNotificationMenu = useCallback(() => {
    setAnchorElNotification(null);
  }, [setAnchorElNotification]);

  const handleLogout = async () => {
    const result = await dispatch(logout());
    if (result) {
      navigate("/login");
    }
    handleCloseUserMenu();
  };

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem("react-app-lang", language);
    window.location.reload();
  };

  const joinArrayDataUser = (arr: any[]) => {
    return arr?.map((item) => item?.email || item?.phone_number).join(", ");
  };

  const logoHeader = useMemo(() => {
    switch (process.env.REACT_APP_LOGO) {
      case "vju":
        return logoVJU;
      default:
        return logo;
    }
  }, []);

  return (
    <>
      {location.pathname !== "/registerForm" && !location.pathname.includes("edit-documents") && userInfo && (
        <AppBar position="sticky">
          <Container maxWidth={false} className={classes.container}>
            <Toolbar disableGutters>
              <Link to="/">
                <img src={logoHeader} alt="Deha Vietnam logo" className={classes.logoDeha} />
              </Link>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} className={classes.navbar}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                {anchorElNav && <Sidebar isOpen={anchorElNav} onClose={handleCloseNavMenu} pages={pages} />}
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} className="nav-bar">
                {pages.map((page, index) => (
                  <DropdownMenu key={index} page={page} onClose={() => setAnchorElNav(null)} />
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  onClick={() => changeLanguage("vi")}
                  sx={{ p: 0 }}
                  className={classes.wrapLanguage}
                  disabled={currentLanguage === "vi"}
                >
                  <img
                    alt="Language"
                    className={[classes.language, currentLanguage === "vi" ? classes.activeLanguage : ""].join(" ")}
                    src={iconVnSquare}
                  />
                </IconButton>
                {process.env.REACT_APP_IS_SHOW_EN_TRANSLATE_BTN === "true" && (
                  <IconButton
                    onClick={() => changeLanguage("en")}
                    sx={{ p: 0 }}
                    className={classes.wrapLanguage}
                    disabled={currentLanguage === "en"}
                  >
                    <img
                      alt="Language"
                      className={`${classes.language} ${currentLanguage === "en" && classes.activeLanguage}`}
                      src={iconEnSquare}
                    />
                  </IconButton>
                )}
                {process.env.REACT_APP_IS_SHOW_JP_TRANSLATE_BTN === "true" && (
                  <IconButton
                    onClick={() => changeLanguage("jp")}
                    sx={{ p: 0 }}
                    className={classes.wrapLanguage}
                    disabled={currentLanguage === "jp"}
                  >
                    <img
                      alt="Language"
                      className={`${classes.language} ${currentLanguage === "jp" && classes.activeLanguage}`}
                      src={iconJPSquare}
                    />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <div className={classes.wrapNotification}>
                  <IconButton onClick={handleOpenNotification} className={classes.wrapBell}>
                    <NotificationsActiveOutlinedIcon className={classes.bell} />
                  </IconButton>
                  {newNotify !== 0 && <span className={classes.numberNotify}>{newNotify}</span>}
                </div>
                <Menu
                  className={classes.menuAvatar}
                  id="menu-appbar"
                  anchorEl={anchorElNotification}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNotification)}
                  onClose={handleCloseNotificationMenu}
                >
                  <MenuItem>
                    <InfoOutlinedIcon className={classes.infor} />
                    <span className={classes.nameNotify}>{t("notification")}</span>
                  </MenuItem>
                  {Array.isArray(listNotifies) && listNotifies.length !== 0 ? (
                    listNotifies?.map((item: LooseObject, index: number) => {
                      switch (item?.event) {
                        case NOTIFICATION_TYPE.importStudentContact:
                        case NOTIFICATION_TYPE.importMasterContact:
                          return (
                            <MenuItem
                              key={`${item.id} ${index} - a`}
                              onClick={() => {
                                navigate(
                                  `/${
                                    item?.event === NOTIFICATION_TYPE.importStudentContact
                                      ? "studentContact"
                                      : "masterContact"
                                  }/import/${item?.id}`
                                );
                                setAnchorElNotification(null);
                              }}
                              className={`${classes.menuItemNotify} ${classes.menuItem} ${
                                index !== listNotifies.length - 1 && classes.boderItem
                              } ${item?.read_at && classes.viewed}`}
                            >
                              <Typography>
                                <span className={classes.nameFile}>{item?.data?.file_name}</span>
                                {` ${t("has_been_imported_successfully")}`}
                              </Typography>
                              <Typography noWrap>
                                <span>{`(`}</span>
                                <span className={classes.textBold}>{t("imported")}</span>
                                {` : ${Number(item?.data?.success) + Number(item?.data?.error)} ${t("rows")}, `}
                                <span className={classes.textBold}>{t("error")}</span>
                                <span>{` : ${item?.data?.error} ${t("rows")})`}</span>
                              </Typography>
                            </MenuItem>
                          );
                        case NOTIFICATION_TYPE.emailBatchCompleted:
                        case NOTIFICATION_TYPE.smsBatchCompleted:
                          return (
                            <MenuItem
                              key={`${item.id} ${index} - a`}
                              className={`${classes.menuItemNotify} ${classes.menuItem} ${
                                index !== listNotifies.length - 1 && classes.boderItem
                              } ${item?.read_at && classes.viewed}`}
                              onClick={() => {
                                navigate(`/notify/${item?.id}`);
                                setAnchorElNotification(null);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <Typography>
                                    {`${isEmpty(item?.data?.success_data) ? t("could_not_send_to") : t("sent_to")}:`}
                                    &nbsp;
                                  </Typography>

                                  <Typography
                                    noWrap
                                    className={`${
                                      isEmpty(item?.data?.success_data) ? classes.textFailed : classes.textSuccess
                                    } ${
                                      isEmpty(item?.data?.success_data)
                                        ? classes.wrapLongTextFail
                                        : classes.wrapLongTextSuccess
                                    }`}
                                  >
                                    {joinArrayDataUser(
                                      isEmpty(item?.data?.success_data)
                                        ? item?.data?.failed_data
                                        : item?.data?.success_data
                                    )}
                                  </Typography>
                                </Box>
                                <Typography>
                                  {item?.data?.total_recipients >= 2 && `(${item?.data?.total_recipients})`}
                                </Typography>
                              </Box>
                              <Typography noWrap>
                                <span>{`(`}</span>
                                <span className={classes.textBold}>{t("completed")}</span>
                                {` : ${Number(item?.data?.success_data.length)}, `}
                                <span className={classes.textBold}>{t("uncompleted")}</span>
                                <span>{` : ${item?.data?.failed_data.length})`}</span>
                              </Typography>
                            </MenuItem>
                          );
                        case NOTIFICATION_TYPE.taskSupportRequest:
                          return (
                            <MenuItem
                              key={`${item.id} ${index} - a`}
                              onClick={() => {
                                if (item?.data?.contact_type === STUDENT_CONTACT_TYPE) {
                                  navigate(`/notify/${item?.id}`);
                                  setTimeout(() => {
                                    navigate(`/studentContact/${item?.data?.contact_id}/tasks`);
                                  }, 0);
                                } else if (item?.data?.contact_type === MASTER_CONTACT_TYPE) {
                                  navigate(`/notify/${item?.id}`);
                                  setTimeout(() => {
                                    navigate(`/masterContact/${item?.data?.contact_id}/tasks`);
                                  }, 0);
                                }
                                setAnchorElNotification(null);
                              }}
                              className={`${classes.menuItemNotify} ${classes.menuItem} ${
                                index !== listNotifies.length - 1 && classes.boderItem
                              } ${item?.read_at && classes.viewed}`}
                            >
                              <Typography>
                                {` ${t("you_have_support_request_that_needs_assistance")}`}
                                <span className={classes.nameFile}> : {item?.data?.task_name}</span>
                              </Typography>
                            </MenuItem>
                          );
                        case NOTIFICATION_TYPE.importmoneytransferhistory:
                          return (
                            <MenuItem
                              key={`${item.id} ${index} - a`}
                              onClick={() => {
                                dispatch(getNotifyById(item?.id));
                                dispatch(getAllNotification());
                                setAnchorElNotification(null);
                              }}
                              className={`${classes.menuItemNotify} ${classes.menuItem} ${
                                index !== listNotifies.length - 1 && classes.boderItem
                              } ${item?.read_at && classes.viewed}`}
                            >
                              <Typography>
                                <span className={classes.nameFile}>{item?.data?.file_name}</span>
                                {` ${t("has_been_imported_successfully")}`}
                              </Typography>
                              <Typography noWrap>
                                <span>{`(`}</span>
                                <span className={classes.textBold}>{t("imported")}</span>
                                {` : ${Number(item?.data?.success) + Number(item?.data?.error)} ${t("rows")}, `}
                                <span className={classes.textBold}>{t("error")}</span>
                                <span>{` : ${item?.data?.error} ${t("rows")})`}</span>
                              </Typography>
                            </MenuItem>
                          );
                        default:
                          return null;
                      }
                    })
                  ) : (
                    <MenuItem>{t("no_data")}</MenuItem>
                  )}
                </Menu>
              </Box>
              {userInfo && (
                <Box sx={{ flexGrow: 0 }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Avatar" className={classes.avatar} src={userInfo.user.avatar} />
                  </IconButton>
                  <Menu
                    className={classes.menuAvatar}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key="logout" onClick={handleLogout} className={classes.menuItem}>
                      <LogoutOutlined className={classes.iconButton} />
                      <Typography>{t("logout")}</Typography>
                    </MenuItem>

                    <MenuItem
                      key="landing-page-referral-link"
                      onClick={() => {
                        navigate("/landingPageReferralLink ");
                      }}
                      className={classes.menuItem}
                    >
                      <LinkOutlined className={classes.iconButton} sx={{ rotate: "-45deg" }} />
                      <Typography>{t("landing_page_referral_link")}</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}

export default NavBar;
