import { yupResolver } from "@hookform/resolvers/yup";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CardCollapse from "components/CardCollapse";
import InputEmail from "components/InputEmail";
import ModalConfirm from "components/Modal/ModalConfirm";
import InputPhoneNumber from "components/inputPhoneNumber";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import HeaderPage from "../../../../../components/HeaderPage";
import Loading from "../../../../../components/Loading/Loading";
import Select from "../../../../../components/Select";
import InputDate from "../../../../../components/inputDate";
import { LooseObject, optionsStudentContact } from "../../../../../models/common";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { getCities, getDistrict, getWard } from "../../../../../store/locationSlice";
import {
  createStudentContact,
  getOptionsStudentContact,
  initialOptions,
  resetStudentContactError,
  updateOptions,
} from "../../../../../store/studentContactSlice";
import {
  COUNTRY_ID_VN_DEFAULT,
  INIT_COUNTRY_ISO_VN_DEFAULT,
  ID_SOURCE_NHTS,
  IELTS_MARKS,
  JAPANESE_LANGUAGE_CERTIFICATE,
  STUDENT_CONTACT_CREATE_FIELD,
} from "../../../../../utils/constant";
import { regexDecimalNumber, regexNumber } from "../../../../../utils/helper";
import BaseButton from "./../../../../../components/Buttons/BaseButton/index";
import { useStyles } from "./styles";
import _ from "lodash";

export default function CreateStudentContact() {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allOptions = useSelector((state: RootState) => state.studentcontacts.options);
  const [options, setOptions] = useState<optionsStudentContact>(initialOptions);
  const errorStore = useSelector((state: RootState) => state.studentcontacts.error);
  const isHasOption = useSelector((state: RootState) => state.studentcontacts.isHasOption);
  const [isOpenModalConfirmCancel, setIsOpenModalConfirmCancel] = useState<boolean>(false);
  const [fieldCreate, setFieldCreate] = useState<LooseObject[]>(STUDENT_CONTACT_CREATE_FIELD);
  const [hideOptionAspiration, setHideOptionAspiration] = useState<number[]>([]);
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .trim()
      .required(t("this_field_is_required"))
      .max(255, t("this_field_must_not_exceed_255_characters")),
    email: Yup.string()
      .trim()
      .email(t("email_is_invalid"))
      .max(255, t("this_field_must_not_exceed_255_characters"))
      .nullable(),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, t("this_field_must_be_a_number"))
      .typeError(t("this_field_must_be_a_number"))
      .nullable()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    country_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    city_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    district_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    ward_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    citizen_id: Yup.string()
      .matches(regexNumber, t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    address: Yup.string().max(255, t("this_field_must_not_exceed_255_characters")),
    note: Yup.string().trim(),
    competency_evaluation_score: Yup.number()
      .positive(t("value_must_be_positive"))
      .nullable()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .typeError(t("this_field_must_be_a_number"))
      .test("is-decimal", t("this_field_has_only_two_decimal_values"), (value) => {
        if (!value) return true;
        return regexDecimalNumber.test(value.toString());
      }),
    total_graduation_score: Yup.number()
      .positive(t("value_must_be_positive"))
      .nullable()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .typeError(t("this_field_must_be_a_number"))
      .test("is-decimal", t("this_field_has_only_two_decimal_values"), (value) => {
        if (!value) return true;
        return regexDecimalNumber.test(value.toString());
      }),
    high_school: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")),
    english_language_certificate: Yup.object({
      type: Yup.string().trim().nullable(),
      value: Yup.string()
        .ensure()
        .when("type", {
          is: (val: any) => val,
          then: (schema: Yup.StringSchema) => schema.trim().required(t("this_field_is_required")),
          otherwise: (schema: Yup.StringSchema) => schema.nullable(),
        }),
    }),
    japan_language_certificate: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")).nullable(),
    subject_combination_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    contact_source_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    source_note: Yup.string().trim().nullable(),
    call_status_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    user_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    date_of_acceptance: Yup.date().nullable(),
    date_of_birth: Yup.date().nullable(),
    country_iso: Yup.string().trim().nullable(),
    contact_state_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    aspiration_registered: Yup.array().of(
      Yup.object({
        aspiration_id: Yup.number().typeError("This field must be a number").nullable(),
        contact_status_id: Yup.number().typeError("This field must be a number").nullable(),
        note: Yup.string().trim().nullable(),
      }).test("eitherFieldRequired", `${t("please_select_the_aspiration")}`, function (value) {
        const { aspiration_id, contact_status_id, note } = value;
        return (!aspiration_id && !contact_status_id && !note) || Number.isInteger(aspiration_id);
      })
    ),
    contact_source_note: Yup.string().trim().nullable(),
    other_certificate: Yup.string().trim().nullable(),
  });
  type StudentContactSubmitForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof StudentContactSubmitForm;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setError,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm<StudentContactSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const contactSource = watch("contact_source_id");
  const typeEnglishLanguageCertificate = watch("english_language_certificate.type");

  const updateHideAspiration = () => {
    const aspirationValue = getValues("aspiration_registered");
    const hideValue: number[] = [];
    if (Array.isArray(aspirationValue)) {
      aspirationValue.forEach((item: LooseObject) => {
        if (Number.isInteger(item?.aspiration_id)) {
          hideValue.push(item?.aspiration_id);
        }
      });
    }
    setHideOptionAspiration(hideValue);
  };

  useEffect(() => {
    if (allOptions) {
      let newOption = { ...allOptions };
      newOption.city = options.city;
      setOptions(newOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions]);

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current && Array.isArray(options?.aspiration) && options?.aspiration.length !== 0) {
      initialized.current = true;
      const newField = [...JSON.parse(JSON.stringify(fieldCreate))];
      const count = options?.aspiration.length;
      for (let index = count; index > 0; index--) {
        newField[3].leftOption.unshift({
          title: "aspiration",
          name: "aspiration",
          type: "select",
          option: "aspiration",
          required: false,
          index: index,
        });
      }
      setFieldCreate(newField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const getDataOption = async () => {
    if (isHasOption) {
      dispatch(updateOptions({ city: [], district: [], ward: [] }));
    } else {
      setLoading(true);
      const response = await dispatch(getOptionsStudentContact());
      if (response.meta.requestStatus === "rejected") {
        toast.error<void>(t("system_error,_please_try_again_later"));
        navigate("/studentContact");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataOption();
    reset();
    return () => {
      dispatch(resetStudentContactError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorStore?.length !== 0) {
      errorStore?.forEach((item: any) => {
        setError(item.key, {
          type: "manual",
          message: item.message.join(","),
        });
      });
    }
  }, [errorStore, setError]);

  useEffect(() => {
    const setDefaultValues = async () => {
      setValue("country_iso", INIT_COUNTRY_ISO_VN_DEFAULT);
      setValue("country_id", COUNTRY_ID_VN_DEFAULT);

      const cities = await dispatch(getCities([COUNTRY_ID_VN_DEFAULT]));
      if (cities && Array.isArray(cities)) {
        if (getValues("country_id") === COUNTRY_ID_VN_DEFAULT) {
          setOptions((prevOptions) => ({ ...prevOptions, city: cities, district: [], ward: [] }));
        }
      }
    };
    setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateStudent = async (data: StudentContactSubmitForm) => {
    setLoading(true);

    try {
      const aspiration: LooseObject[] = [];
      data.aspiration_registered?.forEach((item: LooseObject) => {
        if (Number.isInteger(item?.aspiration_id)) {
          aspiration.push(item);
        }
      });
      let contact: LooseObject = _.cloneDeep(data);
      contact = {
        ...data,
        date_of_acceptance: data.date_of_acceptance && moment(data.date_of_acceptance).format("YYYY/MM/DD"),
        date_of_birth: data.date_of_birth && moment(data.date_of_birth).format("YYYY/MM/DD"),
        aspiration_registered: [...aspiration],
      };
      if (contact?.english_language_certificate?.type === "IELTS") {
        contact = {
          ...contact,
          english_language_certificate: {
            type: "IELTS",
            value: contact?.english_language_certificate?.value ? +contact?.english_language_certificate?.value : "",
          },
        };
      }
      const result = await dispatch(createStudentContact(contact));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("create_student_contact_successful"));
        navigate("/studentContact");
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
        const firstFormGroup = document.querySelectorAll(".container")[0];
        firstFormGroup.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChangeSelect = async (name: string, data: any, reason: string) => {
    if (name.includes("aspiration_id")) {
      updateHideAspiration();
    }
    if (name === "contact_source_id" && data !== ID_SOURCE_NHTS) {
      setValue("source_note", "");
    }
    if (reason === "clear") {
      if (name === "country_id") {
        setOptions({ ...options, city: [], district: [], ward: [] });
        resetField("city_id");
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "city_id") {
        setOptions({ ...options, district: [], ward: [] });
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "district_id") {
        setOptions({ ...options, ward: [] });
        resetField("ward_id");
      }
    } else {
      if (name === "country_id") {
        const cities = await dispatch(getCities([data.id]));
        if (cities && Array.isArray(cities)) {
          setOptions({ ...options, city: cities, district: [], ward: [] });
        }
        resetField("city_id");
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "city_id") {
        const district = await dispatch(getDistrict([data.id]));
        if (district && Array.isArray(district)) {
          setOptions({ ...options, district: district, ward: [] });
        }
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "district_id") {
        const ward = await dispatch(getWard([data.id]));
        if (ward && Array.isArray(ward)) {
          setOptions({ ...options, ward: ward });
        }
        resetField("ward_id");
      }
    }
  };

  const handleClickCancel = () => {
    const form: LooseObject = getValues();
    let check = false;

    Object.keys(form).forEach((key: string) => {
      if (key === "aspiration_registered") {
        Array.isArray(form[key]) &&
          form[key].forEach((element: LooseObject) => {
            if (element?.aspiration_id || element?.contact_status_id) {
              check = true;
              return;
            }
          });
      } else if (
        (key === "country_iso" && form[key] !== INIT_COUNTRY_ISO_VN_DEFAULT) ||
        (key === "country_id" && form[key] !== COUNTRY_ID_VN_DEFAULT) ||
        (key !== "country_iso" && key !== "country_id" && form[key])
      ) {
        check = true;
        return;
      }
    });
    if (check) {
      setIsOpenModalConfirmCancel(true);
    } else {
      navigate("/studentContact");
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("create_student_contact")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("create_student_contact")} />
        <form onSubmit={handleSubmit(handleCreateStudent)} noValidate>
          <div className={classes.containerContent}>
            {fieldCreate.map((item: LooseObject, index: number) => (
              <div className={classes.containerCard} key={`${index} - a`}>
                {item.title !== "aspiration_field" ? (
                  <CardCollapse
                    title={t(item.title)}
                    className={classes.headerCard}
                    childrens={
                      <div className={classes.containerCardContent}>
                        {item?.option.map((field: LooseObject, indexL: number) => (
                          <Fragment key={`${indexL} - b`}>
                            <div
                              className={`${classes.formControl} 
                              ${
                                field.title === "source_note" && contactSource !== ID_SOURCE_NHTS ? classes.hidden : ""
                              }`}
                            >
                              <div className={classes.label}>
                                {t(field.title)}
                                {field.required && <span className={classes.labelRequired}>*</span>}
                              </div>
                              <div className={classes.wrapValueItem}>
                                {field.type === "email" ? (
                                  <InputEmail nameRegisterEmail={field.name} control={control} />
                                ) : field.title === "phone_number" ? (
                                  <InputPhoneNumber
                                    options={options["country"]}
                                    nameRegisterPhone={field.name}
                                    control={control}
                                    nameRegisterIso="country_iso"
                                    initIso={INIT_COUNTRY_ISO_VN_DEFAULT}
                                    isDisableClearable
                                    className={classes.inputLogoPhoneNumber}
                                  />
                                ) : field.title === "english_language_certificate" ? (
                                  <div className={classes.wrapEnglishlanguage}>
                                    <Controller
                                      control={control}
                                      defaultValue=""
                                      name="english_language_certificate.type"
                                      render={({ field: { ...field } }) => (
                                        <RadioGroup
                                          {...field}
                                          row
                                          className={classes.wrapRadio}
                                          onClick={(e: any) => {
                                            const englishCertificate: any = getValues(
                                              "english_language_certificate.type"
                                            );
                                            if (englishCertificate === e.target.value) {
                                              setValue("english_language_certificate.type", "");
                                            }
                                            setValue("english_language_certificate.value", "");
                                          }}
                                        >
                                          <FormControlLabel
                                            value={"IELTS"}
                                            control={<Radio className={classes.radio} />}
                                            label={"IELTS"}
                                          />
                                          <FormControlLabel
                                            value={"TOEFL"}
                                            control={<Radio className={classes.radio} />}
                                            label={"TOEFL"}
                                          />
                                        </RadioGroup>
                                      )}
                                    ></Controller>
                                    {typeEnglishLanguageCertificate === "IELTS" && (
                                      <Select
                                        options={IELTS_MARKS}
                                        classNamePoper={classes.inputSelectPoper}
                                        nameRegister={"english_language_certificate.value"}
                                        control={control}
                                        isForm
                                        onchange={handleChangeSelect} 
                                        isFilterSelected={false}
                                        disableClearable
                                      />
                                    )}
                                    {typeEnglishLanguageCertificate === "TOEFL" && (
                                      <input
                                        className={`${classes.input} ${classes.inputEnglish}`}
                                        {...register("english_language_certificate.value")}
                                        type={"text"}
                                      />
                                    )}
                                  </div>
                                ) : field.title === "japanese_language_certificate" ? (
                                  <Select
                                    options={JAPANESE_LANGUAGE_CERTIFICATE}
                                    className={classes.inputSelect}
                                    classNamePoper={classes.inputSelectPoper}
                                    nameRegister={field.name}
                                    control={control}
                                    isForm
                                    onchange={handleChangeSelect}
                                    isFilterSelected={false}
                                  />
                                ) : field.type === "select" ? (
                                  <Select
                                  options={
                                    field.option === "state"
                                      ? options[field.option as keyof optionsStudentContact].map((item) => ({
                                          ...item,
                                          isDisabled: item.id !== 1 && item.id !== 2,
                                        }))
                                      : options[field.option as keyof optionsStudentContact]
                                  }
                                    className={classes.inputSelect}
                                    classNamePoper={classes.inputSelectPoper}
                                    nameRegister={field.name}
                                    control={control}
                                    isForm
                                    onchange={handleChangeSelect}
                                  />
                                ) : field.type === "date" ? (
                                  <InputDate control={control} nameRegister={field.name} isPast={field?.isPast} />
                                ) : (
                                  <>
                                    <input className={classes.input} {...register(field.name)} type={field.type} />
                                  </>
                                )}
                                {
                                  <div className={classes.error}>
                                    {field.title === "english_language_certificate"
                                      ? errors["english_language_certificate"]?.message ??
                                        errors["english_language_certificate"]?.value?.message
                                      : errors[field.name as KeyError]?.message}
                                  </div>
                                }
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    }
                  />
                ) : (
                  <div className={classes.containerAspiration}>
                    <div className={classes.containerLeftAspiration}>
                      {item?.leftOption.map((field: LooseObject, indexL: number) => (
                        <Fragment key={`${indexL} - c`}>
                          <div className={`${classes.formControl}`}>
                            <div className={classes.labelAspiration}>{`${t(field.title)} ${field.index}`}</div>
                            <div className={classes.wrapValueItem}>
                              <div className={classes.wrapAspiration}>
                                <Select
                                  options={options?.aspiration ?? []}
                                  className={classes.inputSelectAspiration}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={`aspiration_registered.${field.index - 1}.aspiration_id`}
                                  control={control}
                                  isForm
                                  onchange={handleChangeSelect}
                                  hiddenOptions={hideOptionAspiration}
                                  placeholder={`${t("aspiration")} ${field.index}`}
                                />
                                <div className={classes.wrapAspirationStatusAndNumber}>
                                  <Select
                                    options={options?.status}
                                    className={classes.inputSelectStatus}
                                    classNamePoper={classes.inputSelectPoper}
                                    nameRegister={`aspiration_registered.${field.index - 1}.contact_status_id`}
                                    control={control}
                                    isForm
                                    onchange={handleChangeSelect}
                                    placeholder={t("status")}
                                  />
                                  <input
                                    className={classes.inputAspirationNumber}
                                    {...register(`aspiration_registered.${field.index - 1}.note`)}
                                    type="text"
                                    placeholder={t("application_number")}
                                  />
                                </div>
                              </div>
                              <div className={classes.error}>
                                {Array.isArray(errors["aspiration_registered"]) &&
                                  errors["aspiration_registered"][field.index - 1]?.message}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                      <div className={classes.errorAspiration}>{errors["aspiration_registered"]?.message}</div>
                    </div>
                    <div className={classes.containerRightAspiration}>
                      {item?.rightOption.map((field: LooseObject, indexL: number) => (
                        <Fragment key={`${indexL} - b`}>
                          <div className={`${classes.formControlNote}`}>
                            <p className={classes.labelNote}>
                              {t(field.title)}
                              {field.required && <span className={classes.labelRequired}>*</span>}
                            </p>
                            <div className={classes.wrapValueItemNote}>
                              <textarea
                                rows={item?.leftOption.length}
                                className={classes.inputArea}
                                {...register(field.name)}
                              ></textarea>
                              {errors[field.name as KeyError] && (
                                <div className={classes.error}>{errors[field.name as KeyError]?.message}</div>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={classes.containerBtn}>
            <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleClickCancel} />
            <BaseButton
              title={t("create")}
              className={classes.btnCreate}
              startIcon={<AddRoundedIcon className={classes.iconAdd} />}
              isSubmit
            />
          </div>
        </form>
      </div>
      {isOpenModalConfirmCancel && (
        <ModalConfirm
          isOpen={isOpenModalConfirmCancel}
          onClose={() => setIsOpenModalConfirmCancel(false)}
          title={t("confirmation")}
          headerContent={t("leave_this_form?")}
          content={t("changes_you_made_may_not_be_saved.")}
          onConfirm={() => navigate("/studentContact")}
        />
      )}
    </>
  );
}
