import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  blockReviewNotes: {
    display: "flex",
    alignItems: "flex-end"
  },
  reviewNotes: {
    maxWidth: "230px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  seeMore: {
    color: "#80868B",
    fontSize: "10px",
    marginLeft: "5px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));
