import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import {FileUploadOutlined} from "@mui/icons-material";

interface ModalImportFileProp {
  isOpen: boolean;
  onClose: Function;
  title: string;
  headerContent?: string;
  content?: string;
  htmlContent?: JSX.Element;
}

export default function ModalImportFile({
  isOpen,
  onClose,
  title,
  htmlContent,
}: ModalImportFileProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <div className={classes.title}>
          <div style={{display: "flex", alignItems: "center"}}>
            <FileUploadOutlined />
            <p>{title}</p>
          </div>

          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.wrapContent}>
          {htmlContent}
        </DialogContent>
      </Dialog>
    </div>
  );
}
