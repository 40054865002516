import {Close, ContentPaste, SaveAltOutlined} from "@mui/icons-material";
import {CircularProgress, DialogActions, IconButton} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import {LooseObject} from "models/common";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {axiosClient} from "services/axiosClient";
import ModalChangeApplicationStatus from "../ModalChangeApplicationStatus";
import {useStyles} from "./styles";
import {toast} from "react-toastify";
import {RootState, useAppDispatch} from "../../../redux/store";
import {getCandidateApplicationPdf} from "../../../store/applicationSlice";
import {padStart} from "lodash";
import {useLocation} from "react-router-dom";
import CandidateInfoComponent from "components/CandidateInfo";
import {getAllCandidates, updateCandidateApplicationStatus} from "../../../store/registerFormSlice";
import {useSelector} from "react-redux";
import ResponsiveDrawer from "../../Drawer/ResponsiveDrawer";
import {API} from "../../../utils/api";

interface IProps {
	isOpen: boolean;
	onClose: Function;
	dataDetail: LooseObject;
}

export default function ModalDetailCandidateContact({isOpen, onClose, dataDetail}: IProps) {
	const {t} = useTranslation();
	const {classes} = useStyles();
	const location = useLocation();
	const isStudentContact = location.pathname === "/studentContact";
	const [loading, setLoading] = useState<boolean>(false);
	// const [openModalChangeApplicationStatus, setOpenModalChangeApplicationStatus] = useState<boolean>(false);
	const dispatch = useAppDispatch();
  const [applicationIds, setApplicationIds] = useState<any[]>([]);
  const [activeApplicationId, setActiveApplicationId] = React.useState<number>(0);

  const handleDataReceived = (newApplicationId: number) => {
    setActiveApplicationId(newApplicationId);
  };

	const reviewData = useSelector((state: RootState) => state.review.review);
	const updateStatus = useSelector((state: RootState) => state.review.updateStatus);

	const handleClose = () => {
		onClose();
	};
	
	// const handleOpenModalChangeApplicationStatus = () => {
	// 	setOpenModalChangeApplicationStatus(true);
	// };

	const onSubmit = async () => {
		const dataUpdate = {
			id: dataDetail.id,
			data: {
				review: reviewData,
				update_status: Object.fromEntries(
					Object.keys(updateStatus || {}).map((key) => [key, 0])
				),
			},
		};

		const response = await dispatch(updateCandidateApplicationStatus(dataUpdate));
		await dispatch(getAllCandidates());
		if (response.meta.requestStatus === "fulfilled") {
			toast.success<void>(t("change_result_successful"));
		} else {
			console.error(response.payload.message);
			toast.error<void>(t("failed"));
		}
	};
	
	const handleDownloadCandidateApplicationPdf = async () => {
		setLoading(true);
		const response = await dispatch(getCandidateApplicationPdf(dataDetail.view_html));
		try {
			const href = window.URL.createObjectURL(response);
			const fileName = dataDetail.full_name
				+ "_VJU" + dataDetail.aspiration.id + dataDetail.admission_method.id + padStart(dataDetail.numerical_order, 5, '0')
				+ "_đợt_" + dataDetail.admission_period.number;
			const anchorElement = document.createElement("a");
			anchorElement.href = href;
			anchorElement.download = fileName;
			document.body.appendChild(anchorElement);
			anchorElement.click();
			document.body.removeChild(anchorElement);
			window.URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
			toast.error<void>(t("system_error,_please_try_again_later"));
		}
		setLoading(false);
	}
	
	// const handleCloseModalChangeApplicationStatus = () => {
	// 	setOpenModalChangeApplicationStatus(false);
	// };

	useEffect(() => {
    if (isStudentContact) {
      setActiveApplicationId(dataDetail.application_id);
	  setApplicationIds(dataDetail.application_ids);
    }
	}, [dataDetail]);

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth={"md"}
				open={isOpen}
				onClose={handleClose}
				PaperProps={{sx: {overflow: "hidden", height: "auto", maxWidth: "1300px"}}}
			>
				<div className={classes.headerModal}>
					<div className={classes.wrapTitle}>
						<ContentPaste/>
						<p>{t("detail")}</p>
					</div>
					<IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
						<Close/>
					</IconButton>
				</div>
        <DialogContent className={`${classes.containerContent} ${isStudentContact && classes.padding0}`}>
          {loading ? (
            <CircularProgress />
          ) : isStudentContact ? (
              <div className={classes.relative}>
                <ResponsiveDrawer menuItems={applicationIds} onDataReceived={handleDataReceived}>
                  <CandidateInfoComponent dataDetail={dataDetail} activeApplicationId={activeApplicationId} />
                </ResponsiveDrawer>
              </div>
          ) : (
            <CandidateInfoComponent dataDetail={dataDetail} />
          )}
				</DialogContent>
				<DialogActions className={classes.footer} style={{marginBottom: 10, paddingBottom: 10}}>
					<BaseButton
						startIcon={<SaveAltOutlined style={{fontSize: "20px"}}/>}
						title={t("download_application_pdf")}
						className={classes.btnSearch}
						onClick={() => handleDownloadCandidateApplicationPdf()}
					/>
					<BaseButton
						title={t("change_application_status")}
						className={classes.btnUpdate}
						onClick={() => onSubmit()}
					/>
				</DialogActions>
			</Dialog>
			{/*{openModalChangeApplicationStatus && (*/}
			{/*	<ModalChangeApplicationStatus*/}
			{/*		isOpen={openModalChangeApplicationStatus}*/}
			{/*		onClose={handleCloseModalChangeApplicationStatus}*/}
			{/*		dataDetail={dataDetail}*/}
			{/*	/>*/}
			{/*)}*/}
		</div>
	);
}
