import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
	container: {
		width: "100%",

	},
	header: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		backgroundColor: "#AB2523",
		zIndex: "9000",
		display: "flex",
		justifyContent: "center",
	},
	btnCancel: {
		backgroundColor: "#FFFFFF",
		color: colors.defaultColorText,
		fontWeight: 600,
		"&:hover": {
			backgroundColor: "#FFFFFF",
		},
	},
	text: {
		fontWeight: 400,
		fontSize: 18,
		paddingLeft: 16,
	},
	wrapHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		maxWidth: "1200px",
		width: "100%",
		padding: "5px 15px",
		height: "60px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			height: "80px",
			justifyContent: "center",
		},
	},
	logo: {
		height: "55px",
	},
	content: {
		marginTop: "70px",
		flex: "1",
		[theme.breakpoints.down("sm")]: {
			marginTop: "90px",
		},
	},
}));
