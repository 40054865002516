import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  padding0: {
    padding: 0,
  },
  borderBottomItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  textCenter: {
    textAlign: "center",
  },
  dFlex: {
    display: 'flex',
  },
  appBar: {
    position: "absolute",
    color: "black",
    backgroundColor: "white",
    boxShadow: "none",
  },
  drawer: {
    height: "100%",
    overflowY: "auto",

    "& .MuiDrawer-paper": {
      position: "relative",
    },
  },
  minHeight30: {
    minHeight: "30px !important"
  },
  itemButtonActive: {
    "&.Mui-selected": {
      backgroundColor: "#F2F2F2",
    },
  },
}));
