import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinkButton from "../../../../../components/Buttons/LinkButton";
import HeaderPage from "../../../../../components/HeaderPage";
import Loading from "../../../../../components/Loading/Loading";
import Table from "../../../../../components/Table/index";
import { LooseObject } from "../../../../../models/common";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import {
  changeStatusUser,
  deleteUser,
  fetchUsers,
  setPageSize,
} from "../../../../../store/userSlice";
import { LIST_USER_COLUMN } from "../../../../../utils/constant";
import { sortTable } from "../../../../../utils/helper";
import { useStyles } from "./styles";
import { toast } from "react-toastify";
import ModalConfirm from "../../../../../components/Modal/ModalConfirm";
import RoleBaseComponent from "components/RoleBaseComponent";
import SearchBar from "../../../../../components/inputSearch";
import {updateSearchOption} from "../../../../../store/studentContactSlice";

enum STATUSTYPE {
  active = "active",
  disable = "disable",
}

export default function User() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<LooseObject[]>([]);
  const users = useSelector((state: RootState) => state.users.users);
  const page = useSelector((state: RootState) => state.users.page);
  const total = useSelector((state: RootState) => state.users.total);
  const pageSize = useSelector((state: RootState) => state.users.limit);
  const [searchText, setSearchText] = useState<string>('');
  const [limit, setLimit] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [dataDelete, setDataDelete] = useState<number>();
  const [statuschange, setStatuschange] = useState<STATUSTYPE>(
    STATUSTYPE.active
  );
  const [indexUserChangeStatus, setIndexUserChangeStatus] =
    useState<number>(-1);
  const [
    isOpenModalConfirmDeleteCompletely,
    setIsOpenModalConfirmDeleteCompletely,
  ] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    dispatch(fetchUsers(page, searchText))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, navigate, page, pageSize]);

  useEffect(() => {
    setData(users);
  }, [users]);

  const handlePageClick = (page: number = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    dispatch(fetchUsers(page, searchText))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSearchContact = () => {
    setLoading(true);
    dispatch(fetchUsers(page, searchText))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setPageSize(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModalConfirm = useCallback((index: number) => {
    setIsOpenModalConfirm(true);
    let status: number = data[index].status === 1 ? 0 : 1;
    setStatuschange(status === 1 ? STATUSTYPE.active : STATUSTYPE.disable);
    setIndexUserChangeStatus(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModalConfirm = () => {
    setIsOpenModalConfirm(false);
    setIndexUserChangeStatus(-1);
  };

  const handleChangeStatusUser = async () => {
    setLoading(true);
    let status: number = data[indexUserChangeStatus].status === 1 ? 0 : 1;
    const result = await dispatch(
      changeStatusUser(data[indexUserChangeStatus].id, status)
    );
    if (result) {
      const newData = JSON.parse(JSON.stringify(data));
      newData[indexUserChangeStatus].status = status;
      setData(newData);
      toast.success<void>(t("change_status_user_successful"));
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    handleCloseModalConfirm();
    setLoading(false);
  };

  const handleDeleteContactCompletely = async () => {
    setLoading(true);
    try {
      if (typeof dataDelete === "number") {
        const result = await dispatch(deleteUser(dataDelete));
        if (result && result > 0) {
          dispatch(fetchUsers(page, searchText))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
          toast.success<void>(t("delete_user_successful"));
          setIsOpenModalConfirmDeleteCompletely(false);
        } else {
          toast.error<void>(t("system_error,_please_try_again_later"));
        }
      }
    } catch (error) {
      setLoading(false);
      setIsOpenModalConfirmDeleteCompletely(false);
    }
    setLoading(false);
  };
  const handleOpenModalConfirmDeleteUser = useCallback(
    async (data: number[]) => {
      setIsOpenModalConfirmDeleteCompletely(true);
      setDataDelete(data[0] ?? null);
    },
    []
  );

  return (
    <>
      <Helmet>
        <title>{t("user_list")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("user_list")}>
          <div></div>
        </HeaderPage>
        <div className={classes.contentPage}>
          <div className={classes.actionContainer}>
            <SearchBar
                value={searchText}
                className={classes.inputSearch}
                handleChange={setSearchText}
                onBlur={handleSearchContact}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    handleSearchContact();
                    const target = e.target as HTMLButtonElement;
                    target.blur();
                  }
                }}
            />
            <RoleBaseComponent permissions={["user_create"]}>
              <LinkButton
                  title={t("create")}
                  className={classes.btnCreate}
                  startIcon={<AddRoundedIcon className={classes.iconAdd} />}
                  link="/user/create"
              />
            </RoleBaseComponent>
          </div>
          {!loading && (
            <Table
              data={data}
              columns={LIST_USER_COLUMN}
              total={total}
              limit={limit}
              pageSize={pageSize}
              isUserManagement={true}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleChangeStatusUser={handleOpenModalConfirm}
              handleOpenModalConfirmDeleteContact={
                handleOpenModalConfirmDeleteUser
              }
              disableCheckbox
            />
          )}
        </div>
      </div>
      {isOpenModalConfirm && (
        <ModalConfirm
          isOpen={isOpenModalConfirm}
          onClose={() => handleCloseModalConfirm()}
          title={t("confirmation")}
          content={t(`are_you_sure_to_${statuschange}_this_user?`)}
          onConfirm={() => handleChangeStatusUser()}
        />
      )}
      {isOpenModalConfirmDeleteCompletely && (
        <ModalConfirm
          isOpen={isOpenModalConfirmDeleteCompletely}
          title={t("confirmation")}
          htmlContent={t("are_you_sure_to_remove_user")}
          onClose={() => {
            setIsOpenModalConfirmDeleteCompletely(false);
          }}
          onConfirm={() => handleDeleteContactCompletely()}
        />
      )}
    </>
  );
}
