import {
  AddRounded,
  CancelOutlined,
  CheckCircleOutlineOutlined,
  RemoveRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandableTableRow from "components/ExpandableTableRow";
import ModalConfirmIsPaid from "components/Modal/ModalConfirmIsPaid";
import ModalDetailCandidateContact from "components/Modal/ModalDetailCadidateContact";
import ModalSendEmail from "components/Modal/ModalSendEmail";
import ModalSendSms from "components/Modal/ModalSendSms";
import RoleBaseComponent from "components/RoleBaseComponent";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfo, hasPermissions } from "redux/auth/auth.service";
import { useAppDispatch } from "redux/store";
import {
  actionButtonSelector,
  stateActionSupport,
} from "store/actionSupportSlice";
import { setDataCallLog } from "store/callLogSlice";
import {
  CALL_LOG_TYPE,
  TASK_TYPE,
  checkPermission,
  getLink,
  getNameFromArray,
} from "utils";
import { LooseObject, SelectDataProp } from "../../models/common";
import { ReactComponent as BrightLightBulb } from "../../assets/images/bright_light_bulb.svg";
import { ReactComponent as LightBulb } from "../../assets/images/light_bulb.svg";
import SwitchButton from "../Buttons/SwitchButton";
import Paginate from "../Pagination";
import { useStyles } from "./styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SeeMoreTooltip from "components/Tooltips/SeeMoreTooltip";

interface TableProps {
  data: LooseObject[];
  columns: LooseObject[];
  handleCheckBox?: Function;
  disableCheckbox?: boolean;
  total: number;
  limit: number;
  pageSize: number;
  currentPage: number;
  onChangeLimit: Function;
  onChangePage: Function;
  setPageSize: Function;
  handleSortTable: Function;
  isShowAction?: boolean;
  actionMenu?: any[];
  handleClickAction?: Function;
  checkedList?: number[];
  handleChangeStatusUser?: Function;
  handleOpenModalConfirmRestore?: Function;
  handleOpenModalConfirmDeleteContact?: Function;
  onClickRow?: Function;
  handleSelectAll?: Function;
  isSelectAllPage?: boolean;
  isOnlineAdmission?: boolean;
  isUserManagement?: boolean;
}

interface IPropRowsBody {
  row: any;
  rowIndex: any;
  column: any;
  handleOpenDialogSendSms: Function;
  handleOpenDialogSendEmail: Function;
  handleOpenModalCallLog: Function;
  handleOpenModalConfirmIsPaid: Function;
  handleOpenDialogCandidateContact: Function;
  handleChangeStatusUser?: Function;
  handleOpenModalConfirmRestore?: Function;
  handleOpenModalConfirmDeleteContact?: Function;
  onClickRow?: Function;
  isUserManagement?: boolean;
}

const TableRowBody: React.FC<IPropRowsBody> = (props) => {
  const {
    row,
    rowIndex,
    column,
    onClickRow,
    handleOpenDialogCandidateContact,
    handleOpenModalConfirmIsPaid,
    handleOpenDialogSendSms,
    handleOpenModalCallLog,
    handleOpenDialogSendEmail,
    handleChangeStatusUser,
    handleOpenModalConfirmRestore,
    handleOpenModalConfirmDeleteContact,
    isUserManagement,
  } = props;
  const { classes } = useStyles();
  const user = getUserInfo();
  const userId = user?.user?.id;
  const stateActionSupport: stateActionSupport =
    useSelector(actionButtonSelector);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  switch (column.type) {
    case "link":
      return (
        <Link to={getLink(column?.href, row?.id)} state={{ isFilter: true }}>
          <p
            className={[
              classes.red,
              isMobileScreen ? classes.maxWidth120 : classes.maxWidth180,
            ].join(" ")}
          >
            {row[column?.name]}
          </p>
        </Link>
      );
    case "string":
      let value;
      if (column.name === "admission_method")
        value = <>{row?.[column?.name]?.id}</>;
      else if (column.name === "aspiration")
        value = row?.[column?.name]?.shorted_name?.toUpperCase();
      return (
        <div
          className={
            column.title !== "task_name" ? classes.alignCenterField : ""
          }
        >
          <p
            className={`${column?.hightLight ? classes.red : ""} ${
              column?.clickAble ? classes.titleClickAble : ""
            }`}
            onClick={() => {
              if (column?.clickAble) {
                onClickRow && onClickRow(row);
              }
            }}
          >
            {["admission_method", "aspiration"].includes(column.name)
              ? value
              : row?.[column?.name] &&
                (row?.[column?.name]?.source ??
                  row?.[column?.name]?.name ??
                  row[column?.name])}
          </p>
          {column.title !== "task_name" && row?.type?.id === TASK_TYPE && (
            <span className={`${classes.iconBeside} ${classes.iconUrgent}`} />
          )}
        </div>
      );
    case "note":
      return <SeeMoreTooltip content={row?.[column?.name] || ""} />;
    case "boolean":
      if (column.name === "update_status")
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row[column?.name] ? <BrightLightBulb /> : <LightBulb />}
          </Box>
        );

      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {column?.permissions && checkPermission([...column?.permissions]) ? (
            <RoleBaseComponent permissions={[...column?.permissions]}>
              <IconButton onClick={() => handleOpenModalConfirmIsPaid(row)}>
                {row[column?.name] ? (
                  <CheckCircleOutlineOutlined color="success" />
                ) : (
                  <CancelOutlined color="error" />
                )}
              </IconButton>
            </RoleBaseComponent>
          ) : row[column?.name] ? (
            <CheckCircleOutlineOutlined color="success" />
          ) : (
            <CancelOutlined color="error" />
          )}
        </Box>
      );
    case "phone":
      return (
        row[column?.name] && (
          <div className={classes.flexField}>
            {!isMobileScreen && (
              <p className={[classes.red, classes.widthField].join(" ")}>
                {row[column?.name]}
              </p>
            )}
            <div className={classes.alignCenterField}>
              <RoleBaseComponent permissions={[...column?.permissionsCall]}>
                <>
                  {row[column.name] &&
                    stateActionSupport.enableCallLog &&
                    (hasPermissions([...column?.hasPermissionsCall]) ||
                      row?.user?.id === userId) && (
                      <IconButton onClick={() => handleOpenModalCallLog(row)}>
                        <span
                          className={`${
                            row?.contacted === 2
                              ? classes.failedCall
                              : row?.contacted === 3
                              ? classes.successfulCall
                              : ""
                          } ${classes.iconCall}`}
                        />
                      </IconButton>
                    )}
                </>
              </RoleBaseComponent>
              <RoleBaseComponent permissions={[...column?.permissionsSms]}>
                <>
                  {(hasPermissions([...column?.hasPermissionsSms]) ||
                    row?.user?.id === userId) &&
                    stateActionSupport.enableSendSms &&
                    row[column?.name] && (
                      <IconButton
                        onClick={() =>
                          handleOpenDialogSendSms(row, column?.isStudent)
                        }
                      >
                        <span
                          className={`${classes.iconBeside} ${classes.iconSms}`}
                        />
                      </IconButton>
                    )}
                </>
              </RoleBaseComponent>
            </div>
          </div>
        )
      );
    case "phone_online_admission":
    case "phone_number":
      return (
        row[column?.name] && (
          <div className={classes.flexField}>
            {!isMobileScreen && (
              <p
                className={[
                  column.type === "phone_online_admission" ? classes.red : "",
                  classes.widthField,
                ].join(" ")}
              >
                {row[column?.name]}
              </p>
            )}
            <div className={classes.alignCenterField}>
              <>
                {row[column.name] && stateActionSupport.enableCallLog && (
                  <IconButton
                    onClick={() =>
                      handleOpenModalCallLog(row, CALL_LOG_TYPE[3].value)
                    }
                  >
                    <span
                      className={`${
                        row?.contacted === 2
                          ? classes.failedCall
                          : row?.contacted === 3
                          ? classes.successfulCall
                          : ""
                      } ${classes.iconCall}`}
                    />
                  </IconButton>
                )}
              </>
            </div>
          </div>
        )
      );
    case "profile_code":
      return (
        row[column?.name] && (
          <div className={classes.flexField}>
            {!isMobileScreen && (
              <p className={[classes.red, classes.widthField120].join(" ")}>
                {row[column?.name]}
              </p>
            )}
            <div className={classes.alignCenterField}>
              <>
                <IconButton
                  style={
                    column.href === "/studentContact/{id}" &&
                    row.application_id === null
                      ? { visibility: "hidden" }
                      : undefined
                  }
                  onClick={() => handleOpenDialogCandidateContact(row)}
                >
                  <VisibilityOutlined />
                </IconButton>
              </>
            </div>
          </div>
        )
      );
    case "email":
      return (
        row[column?.name] && (
          <div className={classes.flexField}>
            {!isMobileScreen && (
              <p className={[classes.red, classes.w160].join(" ")}>
                {row[column.name]}
              </p>
            )}
            <div className={classes.alignCenterField}>
              <RoleBaseComponent permissions={[...column?.permissions]}>
                <>
                  {(hasPermissions([...column?.hasPermissions]) ||
                    row?.user?.id === userId) &&
                    stateActionSupport.enableSendEmail &&
                    row[column?.name] && (
                      <IconButton
                        onClick={() =>
                          handleOpenDialogSendEmail(row, column?.isStudent)
                        }
                      >
                        <span
                          className={`${classes.iconBeside} ${classes.iconEmail}`}
                        />
                      </IconButton>
                    )}
                </>
              </RoleBaseComponent>
            </div>
          </div>
        )
      );
    case "english_language_certificate":
      return (
        <p>
          {row[column?.name] &&
          row[column?.name]?.type &&
          row[column?.name]?.value
            ? `${row[column?.name]?.type.toUpperCase()} : ${
                row[column?.name]?.value
              }`
            : ""}
        </p>
      );
    case "date":
      return (
        row[column?.name] &&
        moment(new Date(row[column?.name])).format("YYYY/MM/DD")
      );
    case "dateTime":
      return (
        <p
          className={
            column?.isPast &&
            moment(row[column.name], "YYYY/MM/DD - HH:mm").isBefore(moment())
              ? classes.red
              : ""
          }
        >
          {row[column?.name] &&
            moment(row[column?.name], "YYYY/MM/DD - HH:mm").format(
              "YYYY/MM/DD - HH:mm"
            )}
        </p>
      );
    case "numberOfarray":
      return (
        row[column?.name] && (
          <p className={`${column?.hightLight ? classes.red : ""}`}>
            {Object.keys(row[column?.name]).length}
          </p>
        )
      );
    case "typeUser":
      return (
        <p className={classes.red}>
          {row[column.name] === 0 ? "Standard" : "Gmail"}
        </p>
      );
    case "roundPeriod":
      return (
        <p>
          {row[column.name]?.id == null ||
          row[column.name]?.interview_date == null
            ? ""
            : `${row[column.name]?.interview_date}`}
        </p>
      );
    case "array":
      return Object.keys(row[column.name]).length > 0
        ? getNameFromArray(row[column.name])
        : "";
    case "action":
      return (
        <div className={classes.wrapAction}>
          {column?.show.includes("switchBtn") && (
            <RoleBaseComponent permissions={[...column?.permissionsSwitch]}>
              <SwitchButton
                checked={Boolean(row[column.name])}
                onClick={() =>
                  handleChangeStatusUser && handleChangeStatusUser(rowIndex)
                }
              />
            </RoleBaseComponent>
          )}
          {column?.show.includes("view") && (
            <IconButton
              style={
                column.href === "/studentContact/{id}" &&
                row.application_id === null
                  ? { visibility: "hidden" }
                  : undefined
              }
              onClick={() => handleOpenDialogCandidateContact(row)}
            >
              <VisibilityOutlined />
            </IconButton>
          )}
          {column?.show.includes("pen") &&
            (column?.token ? (
              <RoleBaseComponent permissions={[...column?.permissionsPen]}>
                <Link to={`${column?.href}${row?.token}`} target="blank">
                  <IconButton className={classes.iconPen}>
                    <CreateRoundedIcon />
                  </IconButton>
                </Link>
              </RoleBaseComponent>
            ) : (
              <RoleBaseComponent permissions={[...column?.permissionsPen]}>
                <Link
                  to={getLink(column?.href, row?.id)}
                  state={{ isFilter: true }}
                >
                  <IconButton className={classes.iconPen}>
                    <CreateRoundedIcon />
                  </IconButton>
                </Link>
              </RoleBaseComponent>
            ))}
          {column?.show.includes("restore") && (
            <IconButton
              className={classes.iconPen}
              onClick={() =>
                handleOpenModalConfirmRestore &&
                handleOpenModalConfirmRestore([row?.id])
              }
            >
              <RefreshIcon />
            </IconButton>
          )}
          {(column?.show.includes("bin") && isUserManagement) && (
            <RoleBaseComponent permissions={[...column?.permissionsDelete]}>
              <IconButton
                className={classes.iconPen}
                onClick={() =>
                  handleOpenModalConfirmDeleteContact &&
                  handleOpenModalConfirmDeleteContact([row?.id])
                }
              >
                <DeleteForeverIcon className={classes.wasteBasket} />
              </IconButton>
            </RoleBaseComponent>
          )}
        </div>
      );
    default:
      return <></>;
  }
};

const Table = ({
  data,
  columns,
  handleCheckBox,
  disableCheckbox,
  total,
  limit,
  pageSize,
  currentPage,
  onChangeLimit,
  onChangePage,
  setPageSize,
  handleSortTable,
  isShowAction,
  actionMenu,
  handleClickAction,
  handleChangeStatusUser,
  checkedList,
  handleOpenModalConfirmRestore,
  handleOpenModalConfirmDeleteContact,
  onClickRow,
  handleSelectAll,
  isSelectAllPage,
  isOnlineAdmission,
  isUserManagement,
}: TableProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [filteredData, setFilteredData] = useState<LooseObject[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialogSendSms, setOpenDialogSendSms] = useState(false);
  const [openDialogSendMasterSms, setOpenDialogSendMasterSms] = useState(false);
  const [dataSendSms, setDataSendSms] = useState<SelectDataProp[]>([]);
  const [dataCandidateContact, setDataCandidateContact] = useState<LooseObject>(
    {}
  );

  const [openDialogSendEmail, setOpenDialogSendEmail] = useState(false);
  const [openDialogSendMasterEmail, setOpenDialogSendMasterEmail] =
    useState(false);
  const [
    openDialogDetailCandidateContact,
    setOpenDialogDetailCandidateContact,
  ] = useState(false);
  const [openModalConfirmIsPaid, setOpenModalConfirmIsPaid] = useState(false);
  const [dataSendEmail, setDataSendEmail] = useState<SelectDataProp[]>([]);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const [anchorElSelectAll, setAnchorElSelectAll] =
    useState<null | HTMLElement>(null);
  const [anchorElSelectAll2, setAnchorElSelectAll2] =
    useState<null | HTMLElement>(null);
  const openSelectAll = Boolean(anchorElSelectAll);
  const openSelectAll2 = Boolean(anchorElSelectAll2);

  const handleOpenModalCallLog = async (
    row: LooseObject,
    type = CALL_LOG_TYPE[0].value
  ) => {
    const dataCallLog = {
      isOpenModal: true,
      contactId: row?.id,
      user: {
        full_name: row?.full_name,
        phone_number: row?.phone_number,
      },
      type: type,
    };
    dispatch(setDataCallLog(dataCallLog));
  };

  const handleOpenDialogSendSms = (row: LooseObject, isStudent?: boolean) => {
    if (isStudent) setOpenDialogSendSms(true);
    else setOpenDialogSendMasterSms(true);
    setDataSendSms([{ id: row?.id, name: row?.phone_number }]);
  };

  const handleCloseDialogSendSms = () => {
    setOpenDialogSendSms(false);
  };

  const handleCloseDialogSendMasterSms = () => {
    setOpenDialogSendMasterSms(false);
  };

  const handleOpenDialogSendEmail = (row: LooseObject, isStudent?: boolean) => {
    if (isStudent) setOpenDialogSendEmail(true);
    else setOpenDialogSendMasterEmail(true);
    setDataSendEmail([{ id: row?.id, name: row?.email }]);
  };

  const handleCloseDialogSendEmail = () => {
    setOpenDialogSendEmail(false);
  };

  const handleCloseDialogSendMasterEmail = () => {
    setOpenDialogSendMasterEmail(false);
  };

  const handleOpenDialogCandidateContact = (row: LooseObject) => {
    setOpenDialogDetailCandidateContact(true);
    setDataCandidateContact(row);
  };

  const handleCloseDialogCandidateContact = () => {
    setOpenDialogDetailCandidateContact(false);
  };

  const handleOpenModalConfirmIsPaid = (row: LooseObject) => {
    setOpenModalConfirmIsPaid(true);
    setDataCandidateContact(row);
  };

  const handleCloseModalConfirmIsPaid = () => {
    setOpenModalConfirmIsPaid(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action: string) => {
    setAnchorEl(null);
    handleClickAction && handleClickAction(action);
  };

  const handleOpenMenuSelect = (
    event: React.MouseEvent<HTMLElement>,
    isFooter?: boolean
  ) => {
    if (isSelectAllPage) {
      setSelectedRows([]);
      handleSelectAll && handleSelectAll();
    } else if (
      selectedRows.length === filteredData.length &&
      filteredData.length > 0
    ) {
      setSelectedRows([]);
      handleCheckBox && handleCheckBox([], false);
    } else if (isFooter) {
      setAnchorElSelectAll2(event.currentTarget);
    } else {
      setAnchorElSelectAll(event.currentTarget);
    }
  };

  const handleRowSelection = (id: number, row: object) => {
    const newSelectedRows: number[] = [...selectedRows];
    const rowIndex: number = newSelectedRows.indexOf(id);
    if (rowIndex === -1) {
      newSelectedRows.push(id);
    } else {
      newSelectedRows.splice(rowIndex, 1);
    }
    setSelectedRows(newSelectedRows);
    handleCheckBox && handleCheckBox(newSelectedRows);
  };

  const handleSelectAllOnPage = (isFooter?: boolean) => {
    if (selectedRows.length === filteredData.length) {
      setSelectedRows([]);
      handleCheckBox && handleCheckBox([], false);
    } else {
      setSelectedRows(filteredData.map((data) => data?.id));
      handleCheckBox && handleCheckBox(filteredData.map((data) => data?.id));
    }
    if (isFooter) {
      setAnchorElSelectAll2(null);
    } else {
      setAnchorElSelectAll(null);
    }
  };

  const handleClickSelectAll = (isFooter?: boolean) => {
    handleSelectAll && handleSelectAll();
    if (isSelectAllPage) {
      setSelectedRows([]);
    }
    if (isFooter) {
      setAnchorElSelectAll2(null);
    } else {
      setAnchorElSelectAll(null);
    }
  };

  useEffect(() => {
    if (checkedList && checkedList.length === 0) {
      setSelectedRows([]);
    }
  }, [checkedList]);

  useEffect(() => {
    if (isSelectAllPage && checkedList && checkedList.length > 0) {
      setSelectedRows(checkedList);
    }
  }, [isSelectAllPage, checkedList]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(
    new Set()
  );

  const handleChangeStateInfo = (rowId: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(rowId)) {
      newExpandedRows.delete(rowId);
    } else {
      newExpandedRows.add(rowId);
    }
    setExpandedRows(newExpandedRows);
  };
  const isRowExpanded = (rowId: number) => {
    return expandedRows.has(rowId);
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  useEffect(() => {
    if (!isMobileScreen) {
      const newExpandedRows = new Set(expandedRows);
      newExpandedRows.clear();
      setExpandedRows(newExpandedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileScreen]);

  const HeaderTitle = useCallback(
    (columnCandidateContact: any) => {
      return (
        <>
          {t(columnCandidateContact.column.title)}
          {!isEmpty(columnCandidateContact.column.typeSort) && (
            <UnfoldMoreIcon fontSize="small" className={classes.arrowTable} />
          )}
        </>
      );
    },
    [classes.arrowTable, t]
  );

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th colSpan={columns.length + 1}>
              <div className={classes.headerTable}>
                {total !== 0 && (
                  <>
                    <div className={classes.countSelected}>
                      {!disableCheckbox && (
                        <>
                          <Button
                            id="button-select-all"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            startIcon={
                              (selectedRows.length === filteredData.length &&
                                filteredData.length > 0) ||
                              isSelectAllPage ? (
                                <CheckBoxIcon
                                  className={classes.inputCheckedALLChecked}
                                />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  className={classes.inputCheckedALL}
                                />
                              )
                            }
                            endIcon={<ArrowDropDownRoundedIcon />}
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleOpenMenuSelect(event)
                            }
                            className={classes.btnSelectAll}
                          ></Button>
                          <Menu
                            aria-labelledby="button-select-all"
                            anchorEl={anchorElSelectAll}
                            open={openSelectAll}
                            onClose={() => setAnchorElSelectAll(null)}
                            PaperProps={{
                              style: {
                                maxHeight: "250px",
                              },
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                handleSelectAllOnPage();
                              }}
                            >
                              <Typography variant="inherit" noWrap>
                                {t("all_on_page")}
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleClickSelectAll()}
                            >
                              <Typography variant="inherit" noWrap>
                                {t("all_pages")}
                              </Typography>
                            </MenuItem>
                          </Menu>
                          <p>{`${t("selected")} (${
                            checkedList
                              ? checkedList.length
                              : selectedRows.length
                          })`}</p>
                          {isShowAction && actionMenu && (
                            <>
                              <Button
                                id="demo-positioned-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                endIcon={<ArrowDropDownRoundedIcon />}
                                onClick={handleClick}
                                className={classes.action}
                              >
                                {t("action")}
                              </Button>
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={(action: string) =>
                                  handleClose(action)
                                }
                              >
                                {actionMenu.map((item: any) => (
                                  <MenuItem
                                    onClick={() => handleClose(item)}
                                    className={classes.menuItem}
                                    key={`${item}-d`}
                                  >
                                    {t(item)}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <Paginate
                      totalRecords={total}
                      limit={limit}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onChangeLimit={onChangeLimit}
                      onChangePage={onChangePage}
                      onSetPageSize={setPageSize}
                    />
                  </>
                )}
              </div>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            {!disableCheckbox && <th className={classes.checkboxRow}></th>}
            {disableCheckbox && <th className={classes.noRow}>{t("no.")}</th>}
            {columns.map((column, index) => {
              return (
                <th
                  key={`${index}-a`}
                  className={classes.titleTable}
                  onClick={() =>
                    handleSortTable(column?.name, column?.typeSort)
                  }
                >
                  {isMobileScreen ? (
                    column.title === "full_name" ? (
                      <HeaderTitle column={column} />
                    ) : null
                  ) : (
                    <HeaderTitle column={column} />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1} className={classes.noData}>
                {t("no_data_found")}
              </td>
            </tr>
          ) : (
            filteredData.map((row: LooseObject, rowIndex) => {
              return (
                <React.Fragment key={`${rowIndex}-e`}>
                  <tr
                    className={`${
                      rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow
                    }`}
                  >
                    {!disableCheckbox && (
                      <td className={classes.checkboxRow}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {isMobileScreen && (
                            <IconButton
                              size="small"
                              color={
                                !isRowExpanded(row.id) ? "success" : "error"
                              }
                              onClick={() => handleChangeStateInfo(row.id)}
                            >
                              {!isRowExpanded(row.id) ? (
                                <AddRounded fontSize="inherit" />
                              ) : (
                                <RemoveRounded fontSize="inherit" />
                              )}
                            </IconButton>
                          )}
                          <input
                            type="checkbox"
                            checked={selectedRows.indexOf(row?.id) !== -1}
                            onChange={() => handleRowSelection(row?.id, row)}
                          />
                        </Box>
                      </td>
                    )}
                    {disableCheckbox && (
                      <td className={classes.noRow}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {isMobileScreen && (
                            <IconButton
                              size="small"
                              color={
                                !isRowExpanded(row.id) ? "success" : "error"
                              }
                              onClick={() => handleChangeStateInfo(row.id)}
                            >
                              {!isRowExpanded(row.id) ? (
                                <AddRounded fontSize="inherit" />
                              ) : (
                                <RemoveRounded fontSize="inherit" />
                              )}
                            </IconButton>
                          )}
                          <p>{rowIndex + 1}</p>
                        </Box>
                      </td>
                    )}
                    {columns.map((column, columnIndex) => {
                      return (
                        <td
                          key={`${columnIndex}-c`}
                          className={`${classes.cellTable}`}
                        >
                          <TableRowBody
                            row={row}
                            rowIndex={rowIndex}
                            column={column}
                            onClickRow={onClickRow}
                            handleOpenDialogSendSms={handleOpenDialogSendSms}
                            handleOpenDialogSendEmail={
                              handleOpenDialogSendEmail
                            }
                            handleOpenModalCallLog={handleOpenModalCallLog}
                            handleOpenDialogCandidateContact={
                              handleOpenDialogCandidateContact
                            }
                            handleOpenModalConfirmIsPaid={
                              handleOpenModalConfirmIsPaid
                            }
                            handleChangeStatusUser={handleChangeStatusUser}
                            handleOpenModalConfirmRestore={
                              handleOpenModalConfirmRestore
                            }
                            handleOpenModalConfirmDeleteContact={
                              handleOpenModalConfirmDeleteContact
                            }
                            isUserManagement={isUserManagement}
                          />
                        </td>
                      );
                    })}
                  </tr>
                  {isRowExpanded(row.id) && (
                    <tr
                      key={`${rowIndex}-b`}
                      className={`${
                        rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow
                      }`}
                    >
                      <td colSpan={columns.length + 1}>
                        <ExpandableTableRow rowData={row} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          )}
        </tbody>
        <thead>
          <tr>
            <th colSpan={columns.length + 1}>
              <div className={classes.footerTable}>
                {total !== 0 && (
                  <>
                    <div>
                      {!disableCheckbox && (
                        <>
                          <Button
                            id="button-select-all-2"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            startIcon={
                              (selectedRows.length === filteredData.length &&
                                filteredData.length > 0) ||
                              isSelectAllPage ? (
                                <CheckBoxIcon
                                  className={classes.inputCheckedALLChecked}
                                />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  className={classes.inputCheckedALL}
                                />
                              )
                            }
                            endIcon={<ArrowDropDownRoundedIcon />}
                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                              handleOpenMenuSelect(event, true)
                            }
                            className={classes.btnSelectAll}
                          ></Button>
                          <Menu
                            aria-labelledby="button-select-all-2"
                            anchorEl={anchorElSelectAll2}
                            open={openSelectAll2}
                            onClose={() => setAnchorElSelectAll2(null)}
                            PaperProps={{
                              style: {
                                maxHeight: "250px",
                              },
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleSelectAllOnPage(true)}
                            >
                              <Typography variant="inherit" noWrap>
                                {t("all_on_page")}
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleClickSelectAll(true)}
                            >
                              <Typography variant="inherit" noWrap>
                                {t("all_pages")}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </div>
                    <Paginate
                      totalRecords={total}
                      limit={limit}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onChangeLimit={onChangeLimit}
                      onChangePage={onChangePage}
                      onSetPageSize={setPageSize}
                    />
                  </>
                )}
              </div>
            </th>
          </tr>
        </thead>
      </table>
      {openDialogSendSms && (
        <ModalSendSms
          isOpen={openDialogSendSms}
          onClose={handleCloseDialogSendSms}
          dataSendSms={dataSendSms}
        />
      )}

      {openDialogSendMasterSms && (
        <ModalSendSms
          isOpen={openDialogSendMasterSms}
          onClose={handleCloseDialogSendMasterSms}
          dataSendSms={dataSendSms}
          isMasterModal
        />
      )}

      {openDialogSendEmail && (
        <ModalSendEmail
          isOpen={openDialogSendEmail}
          onClose={handleCloseDialogSendEmail}
          dataSendEmail={dataSendEmail}
          isOnlineAdmission={isOnlineAdmission}
        />
      )}

      {openDialogSendMasterEmail && (
        <ModalSendEmail
          isOpen={openDialogSendMasterEmail}
          onClose={handleCloseDialogSendMasterEmail}
          dataSendEmail={dataSendEmail}
          isMasterModal
        />
      )}

      {openDialogDetailCandidateContact && (
        <ModalDetailCandidateContact
          isOpen={openDialogDetailCandidateContact}
          onClose={handleCloseDialogCandidateContact}
          dataDetail={dataCandidateContact}
        />
      )}

      {openModalConfirmIsPaid && (
        <ModalConfirmIsPaid
          isOpen={openModalConfirmIsPaid}
          onClose={handleCloseModalConfirmIsPaid}
          dataDetail={dataCandidateContact}
        />
      )}
    </div>
  );
};

export default memo(Table);
