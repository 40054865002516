import React, {useEffect, useRef, useState} from "react";
import {Box, Card, Typography, Grid, MenuItem, Select, FormControl, InputLabel} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";
import DropdownRadio from "components/DropdownRadio";
import FilterTag from "components/FilterTag";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useStyles } from "./../OnlineAdmissionReport/styles";
import {axiosClient} from "../../../services/axiosClient";
import {API} from "../../../utils/api";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../redux/store";
import {
  getListQueryStudent,
} from "../../../store/studentContactSlice";
import {
  searchOptionCandidateSelector
} from "../../../store/registerFormSlice";
import {toast} from "react-toastify";
import moment from "moment/moment";
import BasicDateRange from "../../../components/dropdownDateRangePicker";

type SummaryItem = { label: string; value: number; };
type ChartItem = { name: string; total: number; };

const programs = [
  { id: 1, name_vi: "BJS" },
  { id: 2, name_vi: "BCSE" },
  { id: 3, name_vi: "ECE" },
  { id: 4, name_vi: "EMJM" },
  { id: 5, name_vi: "ESAS" },
  { id: 6, name_vi: "EFTH" },
  { id: 15, name_vi: "GBJS" },
  { id: 16, name_vi: "ESCT" },
  { id: 17, name_vi: "EASC" },
];

const admissionMethods = [
  { id: 1, name_vi: "Đánh giá hồ sơ năng lực và phỏng vấn" },
  { id: 2, name_vi: "Chứng chỉ các kỳ thi (SAT/ACT/A-Level)" },
  { id: 3, name_vi: "Chứng chỉ ngoại ngữ kết hợp điểm thi TN THPT" },
  { id: 4, name_vi: "Điểm thi đánh giá năng lực của ĐHQGHN hoặc ĐHQGHCM" },
  { id: 5, name_vi: "Xét tuyển thẳng và ưu tiên xét tuyển" },
  { id: 6, name_vi: "Xét điểm thi TN THPT" },
];

interface ChartData {
  name: string;
  value: number;
}

export default function StudentReport() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataRender, setDataRender] = useState<any>();
  const [resSource, setResSource] = useState<any>();
  const [summaryData, setSummaryData] = useState<SummaryItem[]>([]);
  const [ctdtData, setCtdtData] = useState<ChartItem[]>([]);
  const [sourceData, setSourceData] = useState<ChartItem[]>([]);
  const [methodData, setMethodeData] = useState<ChartItem[]>([]);
  const [startCreate, setStartCreate] = useState(moment().startOf('year'));
  const [endCreate, setEndCreate] = useState(moment().add(1, 'year').startOf('year'));
  const dispatch = useAppDispatch();
  const searchOption = useSelector(searchOptionCandidateSelector);

  useEffect(() => {
    setLoading(true);
    getReports()
  }, [searchOption]);

  const getReports = async () => {
    const params = {
      start_create_at: startCreate.format('YYYY-MM-DD'),
      end_create_at: endCreate.format('YYYY-MM-DD')
    };
  
    await axiosClient
        .post(API.getStudentReport, params)
        .then((res) => {
          setDataRender(res.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
  }
  
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }

    const fetchData = async () => {
      try {
        const resSource = await axiosClient.get(API.getSource, {
          params: {types: [0, 1]},
        });
        setResSource(resSource.data.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData()

  }, []);

  const applyCallbackCreateDate = (startDate: any, endDate: any) => {
    setStartCreate(startDate);
    setEndCreate(endDate);
  };

  useEffect(() => {
    getReports();
  }, [startCreate, endCreate]);

  useEffect(() => {
    if (!dataRender) return;

    const summary: SummaryItem[] = [
      { label: "Tổng số liên hệ", value: dataRender.contact_count ?? 0 },
      { label: "Tổng số Thí sinh quan tâm", value: dataRender.contact_potential_count ?? 0 },
      { label: "Tổng số Thí sinh đăng ký nguyện vọng", value: dataRender.contact_not_potential_count ?? 0 },
    ];
    setSummaryData(summary);

    const ctdt: ChartItem[] = [];

    programs.forEach((program) => {
      const total = dataRender.aspirations[program.id] || 0;  // Nếu không tìm thấy id trong aspirations, total sẽ là 0
      ctdt.push({
        name: program.name_vi || `Nguyện vọng ${program.id}`,
        total: total,
      } as ChartItem);
    });

    setCtdtData(ctdt);

    const sources: ChartItem[] = [];
    resSource.forEach((source: { id: string | number; name: any; }) => {
      const total = dataRender.sources[source.id] || 0;
      sources.push({
        name: source.name,
        total: total,
      } as ChartItem);
    });

    setSourceData(sources);

    const methods: ChartItem[] = [];
    admissionMethods.forEach((method) => {
      const total = dataRender.methods[method.id] || 0;
      methods.push({
        name: method.name_vi,
        total: total,
      } as ChartItem);
    });

    setMethodeData(methods);
  }, [dataRender]);

  return (
      <>
        <Helmet>
          <title>{t("student_report")}</title>
        </Helmet>
        {loading && <Loading />}
        <div className="container">
          <HeaderPage title={t("student_report")} />
          <div>
            <div className={classes.contentPage}>
              <BasicDateRange
                  title={t("created_date")}
                  start={startCreate}
                  end={endCreate}
                  isReport={true}
                  applyCallback={applyCallbackCreateDate}
              />
              <Box p={3}>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "40px" }}>
                  {summaryData.map((item) => (
                      <div
                          key={item.label}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "240px",
                          }}
                      >
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: '100px',
                              width: '124px',
                              height: '124px',
                              border: '8px solid #1F4E79',
                            }}
                        >
                          <Typography style={{ color: "#1F4E79", fontSize: "32px", fontWeight: "700" }}>
                            {item.value}
                          </Typography>
                        </div>
                        <Typography
                            variant="subtitle1"
                            style={{ color: "#1F4E79", fontSize: "20px", fontWeight: "600", textAlign: "center" }}
                        >
                          {item.label}
                        </Typography>
                      </div>
                  ))}
                </div>

                <Box mt={4} display="flex" gap={4} justifyContent="space-between">
                  <Box flex={1} style={{textAlign: "center"}}>
                    <Typography variant="h6" style={{marginBottom: "10px"}}>THỐNG KÊ TỔNG SỐ LIÊN HỆ THEO CTĐT QUAN TÂM</Typography>
                    <ResponsiveContainer className={classes.chartContainer} width="100%" height={300}>
                      <BarChart style={{ height: "102%" }} data={ctdtData}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="total" fill="#8884d8" name="Tổng số hồ sơ" >
                          <LabelList dataKey="total" position="top" fontSize={10} fill="#000000" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>

                  <Box flex={1} style={{textAlign: "center"}}>
                    <Typography variant="h6" style={{marginBottom: "10px"}}>THỐNG KÊ TỔNG SỐ LIÊN HỆ THEO NGUỒN</Typography>
                    <ResponsiveContainer className={classes.chartContainer} width="100%" height={300}>
                      <BarChart style={{ height: "102%" }} data={sourceData}>
                        <XAxis
                            dataKey="name"
                            interval={0}
                            textAnchor="middle"
                            tick={(props) => {
                              const { x, y, payload } = props;

                              const maxWidth = 100;
                              const fontSize = 10;
                              const maxCharsPerLine = Math.floor(maxWidth / fontSize);
                              let words = payload.value.split(" ");

                              let lines: string[] = [];
                              let currentLine = "";
                              words.forEach((word: string) => {
                                if ((currentLine + word).length > maxCharsPerLine) {
                                  lines.push(currentLine);
                                  currentLine = word;
                                } else {
                                  currentLine += (currentLine ? " " : "") + word;
                                }
                              });

                              if (currentLine) lines.push(currentLine);

                              return (
                                  <text x={x} y={y + 10} textAnchor="middle" fontSize={fontSize}>
                                    {lines.map((line, index) => (
                                        <tspan key={index} x={x} dy={index ? 16 : 0}> {}
                                          {line}
                                        </tspan>
                                    ))}
                                  </text>
                              );
                            }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="total" fill="#8884d8" name="Tổng số hồ sơ" >
                          <LabelList dataKey="total" position="top" fontSize={10} fill="#000000" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>

                  </Box>
                </Box>

                <Box mt={4} style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                  <Typography variant="h6" style={{marginBottom: "10px"}}>THỐNG KÊ TỔNG SỐ LIÊN HỆ ĐÃ ĐĂNG KÝ THEO PTXT</Typography>
                  <ResponsiveContainer className={classes.chartContainer} width="50%" height={300}>
                    <BarChart style={{ height: "102%" }} data={methodData}>
                      <XAxis
                          dataKey="name"
                          interval={0}
                          textAnchor="end"
                          tick={(props) => {
                            const { x, y, payload } = props;
                            const words: string[] = payload.value.match(/.{1,20}/g) || [payload.value];

                            return (
                                <text x={x} y={y + 10} textAnchor="middle" fontSize={12}>
                                  {words.map((word: string, index: number) => (
                                      <tspan key={index} x={x} dy={index * 14}>
                                        {word}
                                      </tspan>
                                  ))}
                                </text>
                            );
                          }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar
                          dataKey="total"
                          fill="#5da9f0"
                          name="Tổng số hồ sơ"
                      >
                        <LabelList dataKey="total" position="top" fontSize={10} fill="#000000" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </>
  );
}
