import { makeStyles } from "tss-react/mui";
import colors from "../../../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  btnCreate: {
    backgroundColor: colors.btnCreate,
    marginRight: "16px",
    '&:hover': {
      backgroundColor: colors.btnCreate,
    },
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px"
  },
  contentPage: {
    backgroundColor: "#FFF",
    margin: "16px",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px"
  },
  inputSearch: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "25vw",
    },
    marginBottom: "10px",
    marginRight: "auto"
  },
}));
