import {exportStudentApplication} from "../store/registerFormSlice";

const host = process.env.REACT_APP_API_URL;

const API = {
  currentUser: host + "user",
  urlLoginGoogle: host + "auth/redirect/google",
  callbackLoginGoogle: host + "auth/callback/google",
  loginStandard: host + "auth/standard",
  logout: host + "auth/logout",
  restfulRoles: host + "roles",
  getRoleById: (roleId: number) => host + `roles/${roleId}`,
  listPermissions: host + "permissions",
  createRole: host + "roles",
  editRole: (roleId: number) => host + `roles/${roleId}`,
  restfulUsers: host + "users",
  changeStatusUser: (userId: number) => host + `users/${userId}/change-status`,
  getCity: host + "cities",
  getDistricts: host + "districts",
  getWard: host + "wards",
  getStatus: host + "contact-statuses",
  getAspirations: host + "aspirations",
  getCountry: host + "countries",
  getSubjectCombination: host + "subject-combinations",
  getSource: host + "contact-sources",
  getRejectReason: host + "reject-reasons",
  getCallStatus: host + "call-statuses",
  studentContact: host + "student-contacts",
  query: host + "contact-queries",
  column: host + "column-managements",
  deleteStudentContact: host + "student-contacts/delete",
  deleteMasterContact: host + "master-contacts/delete",
  applications: host + "contact-applications",
  restfulContactCategory: host + "contact-categories",
  restfulUnitTag: host + "unit-tags",
  applicationQuestion: host + "application-policy",
  masterContact: host + "master-contacts",
  restfulContactPoint: host + "contact-points",
  taskType: host + "task-types",
  taskResult: host + "task-results",
  task: host + "tasks",
  comment: (taskId: any) => host + `tasks/${taskId}/comment`,
  notifications: host + "notifications",
  landingPageReferralLink: (userId: number) => host + `users/${userId}/landing-page-referral-link`,
  chartContactReferralLink: host + "charts/contact-referral-link",
  massUpdateStudent: host + "student-contacts/mass-update",
  massUpdateMaster: host + "master-contacts/mass-update",
  quantityContactReport: (dateFilter: any, dateStart?: any, dateEnd?: any) =>
    host + `reports/quantity-contact?date_filter=${dateFilter}&date_start=${dateStart}&date_end=${dateEnd}`,
  contactState: host + "contact-states",
  detailSmsTemplate: (templateId: any) => host + `sms-queries/sms-templates/${templateId}`,
  getListSmsTemplate: host + "sms-queries/sms-templates",
  sendSms: host + "sms-queries/send",
  getListEmailTemplate: host + "email-queries/email-templates",
  detailEmailTemplate: (templateId: any) => host + `email-queries/email-templates/${templateId}`,
  sendEmail: host + "email-queries/send",
  callInformation: host + "call-queries/call-info",
  updateCallLog: host + "call-queries/update-call-log",
  getContactsFromQueryAndCategory: (listId: any) => host + `contacts/from-queries-and-categories?${listId}`,
  getAdmissionMethods: host + "admission-method",
  registerSchool: host + "admission-register-forms",
  getCandidate: host + "candidate-contact",
  getCandidateDocument: host + "admission-documents",
  updateCandidate: host + "candidate-contact",
  getReportByApplication: host + "candidate-contact/getReportByApplication",
  getReport: host + "candidate-contact/getReport",
  getScore: host + "candidate-contact/getScore",
  getStudentReport: host + "student-contacts/reports",
  contactLog: host + "contact-logs",
  confirmApplicationFee: host + "candidate-contact/applications/fee",
  exportOnlineAdmission: host + "candidate-contact/list/export",
  exportStudentApplication: host + "candidate-contact/list/exportFile",
  importMoneyTransfer: host + "money-transfer-history/read-excel",
  updateCandidateApplicationStatus: (applicationId: any) => host + `candidate-application/${applicationId}`,
  getHighSchool: host + "highschools",
  getAdmissionRound: (aspirationId: any = '') => host + "admission-period" + `?aspiration_id=${aspirationId}`,
  updateAdmissionPeriods: host + "admission-period/mass-update",
  getAdmissionPeriods: host + "admission-period/filter",
  deleteUser: (userId: number) => host + `users/${userId}`,
  getApplicationDetail: host + "candidate-contact/detailData"
}
export { API };
