import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { AppDispatch, RootState } from "redux/store";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { ACEDEMY_CONDUCT, ACEDEMY_PERFORMANCE, GENDER, LANGUAGESKILL } from "utils";
import {deleteQuery, getListQueries, getQuery} from "./querySlice";

export interface optionsRegisterForm {
  country: LooseObject[];
  city: LooseObject[];
  district: LooseObject[];
  highSchool: LooseObject[];
  aspirations: LooseObject[];
  admissionMethods: LooseObject[];
  gender: LooseObject[];
  languageSkill: LooseObject[];
  performance: LooseObject[];
  conduct: LooseObject[];
  admissionRound: LooseObject[];
  admissionPeriods: LooseObject[];
}
export const initialSearchOptionCandidateContact = {
  full_name: "",
  email: "",
  date_of_birth: "",
  text: "",
  online_profile: "",
  offline_profile: "",
  admission_fee: "",
  created_date: {},
  payment_date: {},
  is_paid: {},
  aspiration_ids: [],
  admission_method_ids: [],
  admission_period_ids: []
};

export interface RegisterFormState {
  candidates: LooseObject[];
  error?: LooseObject[];
  notification?: string;
  page: number;
  total: number;
  limit: number;
  searchOption: LooseObject;
  isLoadingImage: boolean;
  admissionFees: any;
  options: optionsRegisterForm;
  listFilter: number[];
  listQueries: LooseObject[];
}

export const initialOptionsRegisterForm = {
  country: [],
  city: [],
  district: [],
  highSchool: [],
  aspirations: [],
  admissionMethods: [],
  gender: GENDER,
  languageSkill: LANGUAGESKILL,
  performance: ACEDEMY_PERFORMANCE,
  conduct: ACEDEMY_CONDUCT,
  admissionRound: [],
  listAdmissionRoundPerAspiration: {},
  admissionPeriods: [],
};

const initialState: RegisterFormState = {
  candidates: [],
  error: [],
  notification: "",
  page: 1,
  total: 0,
  limit: 50,
  searchOption: initialSearchOptionCandidateContact,
  isLoadingImage: false,
  admissionFees: {},
  options: initialOptionsRegisterForm,
  listFilter: [],
  listQueries: [],
};

const registerFormSlice = createSlice({
  name: "registerForm",
  initialState,
  reducers: {
    resetNotify(state) {
      state.notification = "";
    },
    setListQuery: (state, action) => {
      state.listQueries = action.payload;
    },
    setPageCandidate: (state, action) => {
      state.page = action.payload;
    },
    setLimitCandidate: (state, action) => {
      state.limit = action.payload;
    },
    updateSearch: (state, action: PayloadAction<LooseObject>) => {
      state.searchOption = action.payload;
      state.page = 1;
    },
    updateLoadingImage: (state, action: PayloadAction<boolean>) => {
      state.isLoadingImage = action.payload;
    },
    updateOptions: (state, action) => {
      state.options = { ...state.options, ...action.payload };
    },
    updateListFilter: (state, action) => {
      state.listFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(registerSchool.fulfilled, (state, action) => {
        state.notification = action.payload?.data?.email_html;
      })
      .addCase(registerSchool.rejected, (state, action) => {
        let typedPayload = action.payload as AxiosError<any>;
        const err = typedPayload?.response?.data?.errors;
        if (err && Object.keys(err).length !== 0) {
          const array: LooseObject[] = [];
          Object.entries(err).forEach(([key, value]) => {
            array.push({ key: key, message: value });
          });
          state.error = array;
        }
      })
      .addCase(updateCandidate.rejected, (state, action) => {
        let typedPayload = action.payload as AxiosError<any>;
        const err = typedPayload?.response?.data?.errors;
        if (Object.keys(err).length !== 0) {
          const array: LooseObject[] = [];
          Object.entries(err).forEach(([key, value]) => {
            array.push({ key: key, message: value });
          });
          state.error = array;
        }
      })
      .addCase(getAllCandidates.fulfilled, (state, action) => {
        state.candidates = action.payload.data;
        state.total = action.payload.total;
        state.page = action.payload.current_page;
        state.limit = action.payload.per_page;
        state.admissionFees = action.payload.admisson_fee_count;
      })
      .addCase(getOptionsRegisterForm.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(getOptionsforOnlineAdmission.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(getAdmissionMethods.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(getAdmissionPeriods.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(fetchAllFilterCandidates.fulfilled, (state, action) => {
        const arr: number[] = action.payload.data.map((item: LooseObject) => item?.id);
        state.listFilter = arr;
      });
  },
});

export const getOptionsRegisterForm = createAsyncThunk(
  "registerForm/getOptions",
  async (_, { rejectWithValue, fulfillWithValue }) =>  {
    try {
      const resCountries = await axiosClient.get(API.getCountry);
      const resAspirations = await await axios.get(API.getAspirations, {
        params: { types: [0, 1] },
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      const resAdmissionMethods = await axios.get(API.getAdmissionMethods, {
        params: { types: [0, 1] },
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      const resAdmissionRound = await axios.get(API.getAdmissionRound(), {
        params: { types: [0, 1] },
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      let listAdmisonRound = Array.isArray(resAdmissionRound.data.data) ? resAdmissionRound.data.data : [];
      let listAspiration = Array.isArray(resAspirations.data.data) ? resAspirations.data.data : [];
      let listAdmissionRoundPerAspiration: any = {};
      listAspiration.forEach((item: LooseObject) => {
        // @ts-ignore
        listAdmissionRoundPerAspiration[item.id] = [];
      })
      listAdmisonRound.forEach((item: LooseObject) => {
        // @ts-ignore
        listAdmissionRoundPerAspiration[item.aspiration_id].push({
          ...item,
          'name': item?.id === -1 || item?.id === null ? `không có Đợt` : `Đợt ${item?.number}`
        });
      });

      const data: LooseObject = {
        country: Array.isArray(resCountries.data.data) ? resCountries.data.data : [],
        aspirations: listAspiration,
        admissionMethods: Array.isArray(resAdmissionMethods.data.data) ? resAdmissionMethods.data.data : [],
        admissionRound: Array.isArray(listAdmisonRound) ? listAdmisonRound : [],
        listAdmissionRoundPerAspiration: listAdmissionRoundPerAspiration,
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionsforOnlineAdmission = createAsyncThunk(
  "registerForm/getOptionsforOnlineAdmission",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resAspirations = await await axios.get(API.getAspirations, {
        params: { types: [0, 1] },
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      const resCountries = await axiosClient.get(API.getCountry);
      let country = [...resCountries.data.data];
      const index = country.findIndex((item: LooseObject) => item.name === "Vietnam");
      if (index) {
        const vietnam = country[index];
        country.splice(index, 1);
        country.unshift(vietnam);
      }
      const isPaid = [
        { id: 0, label: "Chưa thanh toán", name: "Chưa thanh toán" },
        { id: 1, label: "Đã thanh toán", name: "Đã thanh toán" },
      ];
      const profileStatus = [
        {  id: 0, label: "Chưa đầy đủ", name: "Chưa đầy đủ" },
        {  id: 1, label: "Đầy đủ", name: "Đầy đủ", },
      ];
      const isUpdate = [
        { id: 0, label: "Chưa cập nhật", name: "Chưa cập nhật", value: false },
        { id: 1, label: "Đã cập nhật", name: "Đã cập nhật", value: true },
      ];
      const data: LooseObject = {
        aspirations: Array.isArray(resAspirations.data.data) ? resAspirations.data.data : [],
        country: Array.isArray(country) ? country : [],
        isPaid,
        profileStatus,
        isUpdate
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateFilterOptions = (optionFilter: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    let data: LooseObject = JSON.parse(JSON.stringify(initialSearchOptionCandidateContact));
    Object.keys(optionFilter).forEach((key) => {
      data[key] = optionFilter[key];
    });
    dispatch(updateSearch(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getAdmissionMethods = createAsyncThunk(
  "registerForm/getAdmissionMethods",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resAdmissionMethods = await axios.get(API.getAdmissionMethods, {
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      const data: LooseObject = {
        admissionMethods: Array.isArray(resAdmissionMethods.data.data) ? resAdmissionMethods.data.data : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdmissionPeriods = createAsyncThunk(
  "registerForm/getAdmissionPeriods",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resAdmissionPeriods = await axios.get(API.getAdmissionPeriods, {
        headers: {
          "Content-Type": "application/json",
          "X-localization": "vi",
        },
      });
      const data: LooseObject = {
        admissionPeriods: Array.isArray(resAdmissionPeriods.data.data) ? resAdmissionPeriods.data.data : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllCandidates = createAsyncThunk(
  "registerForm/getAllCandidates",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const stateCandidates = getState() as RootState;
    try {
      const params: LooseObject = {
        page: stateCandidates.registerForm.page,
        limit: stateCandidates.registerForm.limit,
      };
      Object.entries(stateCandidates.registerForm.searchOption as LooseObject).map(([key, value]) => {
        if (value && (typeof value === "string" || typeof value === "boolean")) {
          params[key] = value;
        } else if (key === "created_date") {
          params["create_date_start"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
          params["create_date_end"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
        } else if (key === "payment_date") {
          params["payment_from_timestamp"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
          params["payment_to_timestamp"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
        } if (key === "admission_period_ids") {
          params[key] = value.map((item: LooseObject) => item.name);
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (["is_paid", "online_profile", "offline_profile"].includes(key)) {
          params[key] = value.id;
        } else if (key === "updated_profile") {
          params[key] = params[key] === true ? true : value?.id === 1;
        }
        return null;
      });
      const response = await axiosClient.get(API.getCandidate, { params: { ...params } });
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAllFilterCandidates = createAsyncThunk(
  "registerForm/fetchAllFilterCandidates",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const { registerForm } = getState() as {
      registerForm: RegisterFormState;
    };
    const { searchOption } = registerForm;

    try {
      const params: LooseObject = {
        limit: "*",
        select_field: ["id"],
      };

      Object.entries(searchOption).map(([key, value]) => {
        if (value && typeof value === "string") {
          params[key] = value;
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (value.name) {
          params[key] = value.id;
        }
        return null;
      });

      const response = await axiosClient.get(API.getCandidate, {params: {...params}});
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmApplicationFee = createAsyncThunk(
  "registerForm/confirmApplicationFee",
  async (param: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.post(API.confirmApplicationFee, param);
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSearchOption =
  (option: LooseObject | string | boolean, field: string) => async (dispatch: AppDispatch, getState: any) => {
    try {
      let searchOption = getState().registerForm.searchOption;
      let searchData: LooseObject = JSON.parse(JSON.stringify(searchOption));
      if (field !== "text" && typeof option !== "boolean" && typeof option !== "string" && Array.isArray(searchData[field])) {
        if (field === "admission_period_ids") {
          searchData[field] = searchData[field].map((item: LooseObject) => item.name).includes(option.name)
              ? searchData[field].filter((item: LooseObject) => item.name !== option.name)
              : [...searchData[field], option];
        } else {
          searchData[field] = searchData[field].map((item: LooseObject) => item.id).includes(option.id)
              ? searchData[field].filter((item: LooseObject) => item.id !== option.id)
              : [...searchData[field], option];
        }
      } else if (typeof option === "boolean" && !option) {
        delete searchData[field];
      } else {
        searchData[field] = option;
      }
      dispatch(updateSearch(searchData));
      return true;
    } catch (error) {
      return false;
    }
  };

export const registerSchool = createAsyncThunk(
  "registerForm/registerSchool",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      let formData = new FormData();
      for (const key in data) {
        if (data[key] && typeof data[key] === "object" && Object.keys(data[key]).length > 0) {
          for (const subKey in data[key]) {
            if (Array.isArray(data[key][subKey])) {
              data[key][subKey].forEach((item: any, index: number) => {
                formData.append(`${key}[${subKey}][${index}]`, item);
              });
            } else {
              formData.append(`${key}[${subKey}]`, data[key][subKey]);
            }
          }
        } else if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      const response = await axios.post(API.registerSchool, formData, {
        headers: { "Content-Type": "multipart/form-data", "X-localization": "vi" },
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCandidate = createAsyncThunk(
  "registerForm/updateCandidate",
  async (form: { id: any; token: any; data: any }, { rejectWithValue, fulfillWithValue }) => {
    const { id, token, data } = form;
    try {
      let formData = new FormData();
      for (const key in data) {
        if (data[key] && typeof data[key] === "object" && Object.keys(data[key]).length > 0) {
          for (const subKey in data[key]) {
            if (Array.isArray(data[key][subKey])) {
              data[key][subKey].forEach((item: any, index: number) => {
                formData.append(`${key}[${subKey}][${index}]`, item);
              });
            } else {
              formData.append(`${key}[${subKey}]`, data[key][subKey]);
            }
          }
        } else if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      const response = await axios.post(`${API.updateCandidate}/${id}`, formData, {
        params: { token: token },
        headers: { "Content-Type": "multipart/form-data", "X-localization": "vi" },
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCandidateInformation = (token: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axios.get(`${API.getCandidate}/general-info`, {
      params: { token: token },
      headers: { "Content-Type": "multipart/form-data", "X-localization": "vi" },
    });
    if (response.status === 200) {
      let data = response.data.data;
      let dataResponse: LooseObject = {};
      dataResponse.city_id = data?.city?.id;
      const { city, ...restData } = data;
      dataResponse = { ...restData, ...dataResponse };
      return dataResponse;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getCandidateDocument = (token: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(API.getCandidateDocument, {
      params: { token: token },
      headers: { "Content-Type": "multipart/form-data", "X-localization": "vi" },
    });
    if (response.status === 200) {
      let data = response.data.data;
      dispatch(updateLoadingImage(true));
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteFieldSearch = (field: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const searchOption = getState().registerForm.searchOption;
    let data: LooseObject = JSON.parse(JSON.stringify(searchOption));
    if (Array.isArray(data[field])) {
      data[field] = [];
    } else {
      data[field] = "";
    }

    dispatch(updateSearch(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const exportOnlineAdmission =
  (type: number, listId?: number[]) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const searchOption = getState().registerForm.searchOption;

      let params: LooseObject = {};
      if (type === 1) {
        Object.entries(searchOption).map(([key, value]) => {
          if (value && typeof value === "string") {
            params[key] = value;
          } else if (
            value &&
            typeof value === "object" &&
            "startDate" in value &&
            "endDate" in value &&
            key === "created_date"
          ) {
            params["create_date_start"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
            params["create_date_end"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
          } else if (
            value &&
            typeof value === "object" &&
            "startDate" in value &&
            "endDate" in value &&
            key === "payment_date"
          ) {
            params["payment_from_timestamp"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
            params["payment_to_timestamp"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
          } else if (value && Array.isArray(value)) {
            params[key] = value.map((item: LooseObject) => item.id);
          } else if (value && typeof value === "object" && "id" in value && key === "is_paid") {
            params[key] = value.id;
          }
          return null;
        });
      } else if (type === 0 && listId) {
        params.candidate_has_aspiration_ids = [...listId];
      }
      params.export_type = type;
      params.limit = "*";

      const response = await axiosClient.get(API.exportOnlineAdmission, {
        params: { ...params },
        responseType: "blob",
      });
      if (response) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
 };

export const exportStudentApplication =
  (type: number, listId?: number[]) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const searchOption = getState().registerForm.searchOption;
      
      let params: LooseObject = {};
      if (type === 1) {
        Object.entries(searchOption).map(([key, value]) => {
          if (value && typeof value === "string") {
            params[key] = value;
          } else if (
            value &&
            typeof value === "object" &&
            "startDate" in value &&
            "endDate" in value &&
            key === "created_date"
          ) {
            params["create_date_start"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
            params["create_date_end"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
          } else if (
            value &&
            typeof value === "object" &&
            "startDate" in value &&
            "endDate" in value &&
            key === "payment_date"
          ) {
            params["payment_from_timestamp"] = value?.startDate && moment(value?.startDate).format("YYYY-MM-DD");
            params["payment_to_timestamp"] = value?.endDate && moment(value?.endDate).format("YYYY-MM-DD");
          } else if (value && Array.isArray(value)) {
            params[key] = value.map((item: LooseObject) => item.id);
          } else if (value && typeof value === "object" && "id" in value && key === "is_paid") {
            params[key] = value.id;
          }
          return null;
        });
      } else if (type === 0 && listId) {
        params.candidate_has_aspiration_ids = [...listId];
      }
      params.export_type = type;
      params.limit = "*";
      
      const response = await axiosClient.get(API.exportStudentApplication, {
        params: { ...params },
        responseType: "blob",
      });
      if (response) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const importMoneyTransfer = (data: File, type: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("import_file", data);
    bodyFormData.append("type", type.toString());

    const response = await axiosClient.post(API.importMoneyTransfer, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getQueryStudent = (id: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(getQuery(id));
    if (response) {
      let data: LooseObject = JSON.parse(JSON.stringify(initialSearchOptionCandidateContact));
      Object.keys(response.params).forEach((key) => {
        data[key] = response.params[key];
      });
      dispatch(updateSearch(data));
      
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getListQueryStudent = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(getListQueries(1));
    
    if (response) {
      dispatch(setListQuery(response));
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteQueryStudent = (id: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(deleteQuery(id));
    if (response) {
      let listQueries = getState().registerForm.listQueries;
      const data = listQueries.filter((item: LooseObject) => item.id !== id);
      dispatch(setListQuery(data));
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateCandidateApplicationStatus = createAsyncThunk(
  "registerForm/updateCandidateApplicationStatus",
  async (form: { id: any; data: any }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resAspiration = await axiosClient.post(API.updateCandidateApplicationStatus(form.id), form.data);
      return fulfillWithValue(resAspiration.data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHighSchoolByDistrict = (district: number) => async () => {
  try {
    const url: string = API.getHighSchool;
    const response = await axiosClient.get(url, { params: { district_ids: [district] } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const {
  setPageCandidate,
  setLimitCandidate,
  updateSearch,
  resetNotify,
  updateLoadingImage,
  updateOptions,
  updateListFilter,
  setListQuery
} =
  registerFormSlice.actions;

export default registerFormSlice;

export const candidatesSelector = (state: RootState) => state.registerForm.candidates;
export const pageCandidateContactSelector = (state: RootState) => state.registerForm.page;
export const totalCandidateContactSelector = (state: RootState) => state.registerForm.total;
export const limitCandidateContactSelector = (state: RootState) => state.registerForm.limit;
export const searchOptionCandidateSelector = (state: RootState) => state.registerForm.searchOption;
export const isLoadingImageSelector = (state: RootState) => state.registerForm.isLoadingImage;
export const admissionFeesSelector = (state: RootState) => state.registerForm.admissionFees;
