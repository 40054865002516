import React, {StrictMode, useCallback, useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HeaderPage from "components/HeaderPage";
import {useStyles} from "./styles";
import {AssignmentOutlined} from "@mui/icons-material";
import {
    Grid, Select
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CardCollapse from "components/CardCollapse";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {RootState, useAppDispatch} from "../../../../redux/store";
import {
    getStudentAdmissionPeriodPageData,
    getStudentAdmissionRounds, updatePeriods
} from "store/actionStudentAdmissionPeriodSlice";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import InputDateTime from "components/inputDateTime";
import InputCustom from "components/InputCustom/InputCustom";
import BaseButton from "components/Buttons/BaseButton";
import ProtectedComponent from "../../../../components/ProtectedComponent";
import moment from 'moment';
import { MenuItem } from "@mui/material";

export default function StudentAdmissionPeriod() {
    const dispatch = useAppDispatch();
    const yearSelect = [
        { value: 2024 },
        { value: 2025 }
    ];
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [admissionYear, setAdmissionYear] = useState<number | null>(new Date().getFullYear());
    const fetchedAspirations = useSelector((state: RootState) => state.studentAdmissionPeriods.aspirations);
    const [aspirations, setAspirations] = useState<any>({});
    const fetchedAdmissionPeriods = useSelector((state: RootState) => state.studentAdmissionPeriods.admissionPeriods);
    const [admissionPeriods, setAdmissionPeriods] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [initOpenCard, setInitOpenCard] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        'fields': Yup.array().of(Yup.object().shape({
            'periods': Yup.array().of(
                Yup.object().shape({
                    'start_date': Yup.string().nullable()
                        .transform((value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value),
                    'end_date': Yup.string().nullable()
                        .transform((value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value)
                        .test(
                        'is-greater',
                        t('end_date_should_be_greater_than_start_date'),
                        function (value) {
                            const { start_date } = this.parent;
                            const startDateMoment = moment(start_date, "YYYY-MM-DD HH:mm:ss");
                            const endDateMoment = moment(value);
                            if (startDateMoment.isValid() && endDateMoment.isValid()) {
                                return endDateMoment.isSameOrAfter(startDateMoment);
                            }
                            return true;
                        }
                    ),
                    'interview_date': Yup.string().nullable()
                        .transform((value) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value),
                    'id': Yup.number().nullable()
                })
            ),
            'aspiration_id': Yup.number().nullable()
        })),
    });

    type StudentAdmissionPeriodForm = Yup.InferType<typeof validationSchema>;

    type NestedFieldArrayProps = {
        parentFieldIndex: number;
    };

    // validation
    type KeyError = keyof StudentAdmissionPeriodForm;
    const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        resetField,
        setFocus
    } = useForm<StudentAdmissionPeriodForm>({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const {
        fields,
        append: appendAspirationField,
        update: updateAspirationField,
    } = useFieldArray({
        control,
        name: "fields"
    });

    React.useEffect(() => {
        if (errors) {
            const fields = errors?.['fields'] && Array.isArray(errors?.['fields']) ? errors?.['fields'] : [];
            fields.forEach((field: any, fieldIndex: any) => {
                const periods = field?.['periods'] && Array.isArray(field?.['periods']) ? field?.['periods'] : [];
                Object.keys(periods).forEach((periodIndex: any) => {
                    if (periods[periodIndex]?.['start_date']) {
                        setFocus(`fields.${fieldIndex}.periods.${periodIndex}.start_date`);
                    }
                    if (periods[periodIndex]?.['end_date']) {
                        setFocus(`fields.${fieldIndex}.periods.${periodIndex}.end_date`);
                    }
                })
            })
        }
    }, [errors]);

    function RenderPeriods({parentFieldIndex}: NestedFieldArrayProps) {
        const periods = getValues(`fields.${parentFieldIndex}.periods`) || [];
        const periodLength = periods.length;
        return (<>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',  margin: '1rem 0'}} >
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '1rem 0'}} >
                    <span className={classes.textYear}>{t('year')}:</span>
                    <Select
                        labelId="admission-year-label"
                        value={admissionYear}
                        className={classes.inputSelect}
                        onChange={(event) => {
                            const newYear = event.target.value as number;
                            setAdmissionYear(newYear);
                        }}
                    >
                        {yearSelect.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                { admissionYear === new Date().getFullYear() && (
                    <BaseButton
                        title={t('add_more')}
                        className={classes.btnAddMore}
                        onClick={() => {
                            const parentField = getValues(`fields.${parentFieldIndex}`);
                            updateAspirationField(parentFieldIndex, {
                                ...parentField,
                                'periods': [...periods, {
                                    'start_date': null,
                                    'end_date': null,
                                    'interview_date': null,
                                    'id': null
                                }],
                            });
                        }}
                        startIcon={<AddRoundedIcon className={classes.iconAdd} />}
                    />
                )}
            </div>
            <Grid container rowSpacing={3} columnSpacing={{sm: 1, md: 2, lg:3}}>
                {periods.map((period, index) => (
                    <Grid xs={12} sm={12} md={12} lg={6} xl={4} item key={parentFieldIndex + '_' + index}>
                        <div className={classes.formControl}>
                            <p className={`${classes.label}`}>{t('admission_period')}</p>
                            <InputDateTime
                                isPast={true}
                                control={control}
                                data={(getValues(`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.interview_date`))?.toString()}
                                nameRegister={`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.interview_date`}
                            />
                        </div>
                        <div className={classes.formControl}>
                            <p className={`${classes.label}`}>{t('start_date')}</p>
                            <InputDateTime
                                isPast={true}
                                control={control}
                                data={(getValues(`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.start_date`))?.toString()}
                                nameRegister={`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.start_date`}
                                resetField={resetField}
                            />
                        </div>
                        <div className={classes.formControl}>
                            <p className={`${classes.label}`}>{t('end_date')}</p>
                            <InputDateTime
                                isPast={true}
                                control={control}
                                data={(getValues(`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.end_date`))?.toString()}
                                nameRegister={`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}.end_date`}
                                resetField={resetField}
                            />
                        </div>
                        <div className={classes.error}>
                            {errors?.['fields']?.[parentFieldIndex]?.['periods']?.[periodLength - 1 - index]?.['end_date']?.message}
                        </div>
                        <BaseButton
                            title={t('clear')}
                            className={classes.btnClear}
                            onClick={() => {
                                const parentField = getValues(`fields.${parentFieldIndex}`);
                                const periodToRemove = getValues(`fields.${parentFieldIndex}.periods.${periodLength - 1 - index}`);
                                if (!periodToRemove) { return; }

                                const newPeriods = periods.filter(p => p !== periodToRemove);

                                updateAspirationField(parentFieldIndex, {
                                    ...parentField,
                                    'periods': newPeriods,
                                });
                            }}
                            startIcon={<DeleteForeverRoundedIcon className={classes.iconClear} />}
                        />
                    </Grid>
                ))}
            </Grid>
        </>);

    }

    const submitForm = async (data: StudentAdmissionPeriodForm) => {
        setLoading(true);
        try {
            const currentYear = new Date().getFullYear();
            const result: any = await dispatch(updatePeriods({ admissionYear: admissionYear ?? currentYear, data }));
            if (result.meta.requestStatus === "fulfilled") {
                toast.success<void>(t("update_information_successfully"));
                window.scrollTo({ top: 0, behavior: "smooth" });
            } else if (result?.payload?.response?.status === 422) {
                toast.error<void>(
                    `${t("some_fields_contain_invalid_data", { ns: "home" })} (code: ${result.payload.response.status})`,
                    { autoClose: 5000 }
                );
            } else {
                toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${result.payload.response.status})`);
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        } catch (error) {
            toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${error})`);
            setLoading(false);
        }
        setLoading(false);
    };

    const getData = async () => {
        setLoading(true);
        const response = await dispatch(getStudentAdmissionPeriodPageData());
        if (response.meta.requestStatus === "rejected") {
            toast.error<void>(t("could_not_get_aspiration_data"));
        } else {
            // fulfilled in extraReducers
        }
        if (admissionYear != null) {
            await dispatch(getStudentAdmissionRounds(admissionYear));
        }
        if (response.meta.requestStatus === "rejected") {
            toast.error<void>(t("could_not_get_admission_period_data"));
        } else {
            // fulfilled in extraReducers
        }
        setLoading(false);
    }

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getData()
    }, [admissionYear]);

    const initializeFields = (fetchedAdmissionPeriods: any) => {
        Object.keys(fetchedAdmissionPeriods).forEach((aspirationId: any) => {
            const parsedAspirationId = parseInt(aspirationId);
            const currentFields = getValues('fields');
            const periods = fetchedAdmissionPeriods[parsedAspirationId];

            const fieldIndex = currentFields?.findIndex((field: any) => field.aspiration_id === parsedAspirationId);

            if (fieldIndex === undefined || fieldIndex === -1) {
                appendAspirationField({
                    'periods': periods,
                    'aspiration_id': parsedAspirationId
                });
            } else {
                updateAspirationField(fieldIndex, {
                    'periods': periods,
                    'aspiration_id': parsedAspirationId
                });
            }
        });
    };

    useEffect(() => {
        if (fetchedAspirations) {
            setAspirations(fetchedAspirations);
        }
    }, [fetchedAspirations]);

    useEffect(() => {
        if (fetchedAdmissionPeriods) {
            setAdmissionPeriods(fetchedAdmissionPeriods);
            initializeFields(fetchedAdmissionPeriods);
        }
    }, [fetchedAdmissionPeriods]);

    function getAspirationNameFromFieldIndex(index: number)
    {
        const fieldAspirationId = getValues(`fields.${index}.aspiration_id`) || null;
        if (!fieldAspirationId) {
            return null;
        }
        const aspiration: any = Object.values(fetchedAspirations).filter((aspiration: any) => aspiration.id === fieldAspirationId)[0];
        return aspiration?.name;
    }

    return (
        <>
            <Helmet>
                <title>{t("admission_period_config")}</title>
            </Helmet>
            <StrictMode>
                <form onSubmit={handleSubmit(submitForm)} className="container">
                <HeaderPage title={t("admission_period_config")}>
                    <></>
                </HeaderPage>
                <div className={classes.contentPage}>
                    <CardCollapse
                        title={t("admission_period_config_for_interview_method").toUpperCase()}
                        iconTittle={<AssignmentOutlined sx={{ color: "#fff", paddingRight: "5px" }} />}
                        className={classes.headerCard}
                        childrens={
                            <div className={classes.containerCardContent}>
                                <div style={{padding: '20px'}}>
                                    {
                                        aspirations && fields.map((field, index) => (
                                            <CardCollapse key={'card_' + index}
                                                          initShow={index === 0}
                                              iconTittle={<AssignmentOutlined sx={{ color: "#fff", paddingRight: "5px" }} />}
                                              title={getAspirationNameFromFieldIndex(index)}
                                              className={classes.subHeaderCard}
                                              childrens={
                                                  <div>
                                                      <RenderPeriods parentFieldIndex={index}/>
                                                  </div>
                                              }
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    />
                </div>
                    <ProtectedComponent permissions={["online_admission_edit"]}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <button className={classes.btnSubmit} type={'submit'}>Submit</button>
                        </div>
                    </ProtectedComponent>
            </form>
            </StrictMode>
        </>
    );
}
