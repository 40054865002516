import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
	contentPage: {
		backgroundColor: colors.backgroundLight,
		margin: "16px",
		boxSizing: "border-box",
		borderRadius: "8px",
		padding: "16px",
	},
	wrapSearchFilter: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "16px",
	},
	btnLeft: {
		backgroundColor: "#6A7A83",
		"&:hover": {
			backgroundColor: "#6A7A83",
		},
	},
	iconNumber: {
		backgroundColor: "#FFFFFF",
		borderRadius: "8px",
		fontSize: "12px !important",
		fontWeight: 700,
		color: colors.defaultColorText,
		padding: "0 8px",
		height: "16px",
		display: "flex",
		alignItems: "center",
	},
	containerSearch: {
		display: "flex",
		flexDirection: "column",
		boxSizing: "border-box",
		width: "100%",
	},
	btnCancel: {
		backgroundColor: "#FFFFFF",
		color: colors.defaultColorText,
		fontWeight: 600,
		"&:hover": {
			backgroundColor: "#FFFFFF",
		},
	},
	wrapDropdown: {
		"& .daterangepicker" :{
			left: "0px !important",
		},
		display: "flex",
		alignItems: "center",
		padding: "6px 6px 6px 0",
	},
	inputSearch: {
		[theme.breakpoints.up("xs")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "300px",
		},
		[theme.breakpoints.up("md")]: {
			width: "430px",
		},
	},
	dropdown: {
		marginRight: "16px",
	},
	containerFilter: {
		display: "flex",
		flexWrap: "wrap",
	},
	clearAll: {
		borderRadius: "12px",
		height: "24px",
		display: "flex",
		alignItems: "center",
		backgroundColor: colors.buttonClear,
		padding: "0 10px",
		width: "fit-content",
		margin: "5px",
		cursor: "pointer",
		"& p": {
			fontSize: "12px",
			fontWeight: 400,
			color: colors.backgroundLight,
		},
		"&:hover": {
			opacity: "0.8",
			transition: "ease-in 0.2s",
			boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
		},
	},
	results: {
		fontSize: "14px",
		color: colors.buttonClear,
		marginLeft: "5px",
		marginTop: "10px",
		fontWeight: 700,
	},
	containerBtn: {
		display: "flex",
		flexWrap: "wrap",
		flexGrow: 1,
		[theme.breakpoints.up("sm")]: {
			justifyContent: "flex-end",
		},
	},
	inconNumber: {
		backgroundColor: "#FFFFFF",
		borderRadius: "8px",
		fontSize: "12px !important",
		fontWeight: 700,
		color: colors.defaultColorText,
		fontFamily: "Roboto",
		padding: "0 8px",
		height: "16px",
		display: "flex",
		alignItems: "center",
	},
	btn: {
		marginBottom: "10px",
		marginRight: "20px",
		backgroundColor: "#4A4358",
		"&:hover": {
			backgroundColor: "#4A4358",
		},
	},
	btnUpdatedProfile: {
		height: "fit-content",
		color: "#4E4E4E",
		fontSize: "12px",
		fontWeight: 700,
		borderRadius: "12px",
		marginBottom: "10px",
	},
	pinkButton: {
		backgroundColor: "#EF12BF33",
		"&:hover": {
			backgroundColor: "#EF12BF33",
		}
	},
	purpleButton: {
		backgroundColor: "#5B1C4E33",
		"&:hover": {
			backgroundColor: "#5B1C4E33",
		},
	},
	btnInputFile: {
		minWidth: "32px",
		height: "30px",
		padding: "0 16px",
		display: "flex",
		cursor: "pointer",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#4A4358",
		fontSize: "14px",
		fontWeight: 400,
		color: "#FFFFFF",
		borderRadius: "4px",
		marginRight: "10px",
		marginBottom: "10px",
		"& input[type='file']": {
			opacity: 0,
			width: 0,
			height: 0,
		},
		"&:hover": {
			backgroundColor: "#4A4358",
			opacity: 0.8,
			transition: "ease-in 0.2s",
			boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
		},
	},
	btnText: {
		marginLeft: "10px",
	},
	contentModalExport: {
		textAlign: "left",
	},
	nameExport: {
		fontWeight: 700,
		overflowWrap: "break-word",
	},
	wrapStatusFilter: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
	},
	justifyContentEnd: {
		justifyContent: "end",
	},
	btnAdmissionFees: {
		borderRadius: "12px",
		marginRight: "10px",
		marginBottom: "10px",
		fontSize: "12px",
		padding: "5px 10px",
		height: "fit-content",
	},
	activeAdmissionFeesButton: {
		backgroundColor: "#4A4358",
		color: "#FFFFFF",
		fontWeight: "bold",
		"&:hover": {
			backgroundColor: "#4A4358",
		}
	},
	normalAdmissionFeesButton: {
		backgroundColor: "#605BFF1A",
		color: "#4E4E4E",
		"&:hover": {
			backgroundColor: "#605BFF1A",
		},
	},
	chartContainer : {
		"& svg": {
			height: "104% !important",
		}
	}
}));
