import {CollectionsBookmarkOutlined, FileUploadOutlined, PersonSearchOutlined} from "@mui/icons-material";
import { ReactComponent as LogoReports } from "../../../assets/images/reports.svg";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import DropdownRadio from "components/DropdownRadio";
import FilterTag from "components/FilterTag";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import ModalConfirm from "components/Modal/ModalConfirm";
import Table from "components/Table";
import SearchBar from "components/inputSearch";
import { LooseObject } from "models/common";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { getUnits } from "store/listStudentContactSlice";
import { useLocation } from "react-router-dom";
import {
  admissionFeesSelector,
  candidatesSelector,
  deleteFieldSearch,
  getAdmissionMethods,
  getAllCandidates,
  getOptionsforOnlineAdmission,
  initialOptionsRegisterForm,
  initialSearchOptionCandidateContact,
  limitCandidateContactSelector,
  optionsRegisterForm,
  pageCandidateContactSelector,
  searchOptionCandidateSelector,
  setLimitCandidate,
  setPageCandidate,
  totalCandidateContactSelector,
  updateSearch,
  updateSearchOption,
  updateListFilter,
  getQueryStudent,
  fetchAllFilterCandidates, deleteQueryStudent, updateOptions, updateFilterOptions, exportStudentApplication,
} from "store/registerFormSlice";
import {ACTION_CANDIDATES_LIST, FILTER_ONLINE_ADMISSION_FIELD, STUDENT_APPLICATION_COLUMNS} from "utils/constant";
import { formatSearchContent, sortTable } from "utils/helper";
import BaseButton from "./../../../components/Buttons/BaseButton/index";
import { useStyles } from "./styles";
import Dropdown from "components/Dropdown";
import BasicDateRange from "../../../components/dropdownDateRangePicker";
import RoleBaseComponent from "../../../components/RoleBaseComponent";
import DropdownQuery from "../../../components/DropdownQuery";
import {getListQueryStudent} from "../../../store/studentContactSlice";
import ModalFilter from "../../../components/Modal/ModalFilter";
import {saveQuery} from "../../../store/querySlice";

export default function StudentApplication() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchAdmissionFee, setSearchAdmissionFee] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const candidates = useSelector(candidatesSelector);
  const page = useSelector(pageCandidateContactSelector);
  const totalCandidates = useSelector(totalCandidateContactSelector);
  const candidatePerPage = useSelector(limitCandidateContactSelector);
  const searchOption = useSelector(searchOptionCandidateSelector);
  const admissionFees = useSelector(admissionFeesSelector);
  const [limit, setLimit] = useState<number>(candidatePerPage);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [checkedList, setCheckedList] = useState<number[] | []>([]);
  const [isShowAction, setIsShowAction] = useState<boolean>(false);
  const [isShowClearAll, setIsShowClearAll] = useState<boolean>(false);
  const [optionsOnlineProflie, setOptionsOnlineProflie] = useState<LooseObject>({});
  const [optionsOfflineProflie, setOptionsOfflineProflie] = useState<LooseObject>({});
  const total = useSelector((state: RootState) => state.registerForm.total);
  const [isOpenModalConfirmExport, setIsOpenModalConfirmExport] = useState<boolean>(false);
  const allOptions = useSelector((state: RootState) => state.registerForm.options);
  const numberAdvancedSearch = 0;
  const [options, setOptions] = useState<optionsRegisterForm>(initialOptionsRegisterForm);
  const listFilter = useSelector((state: RootState) => state.registerForm.listFilter);
  const [isSelectAllPage, setIsSelectAllPage] = useState<boolean>(false);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
  const [data, setData] = useState<LooseObject[]>([]);
  const [isSearchListContact, setIsSearchListContact] = useState<boolean>(false);
  const [searchProfileUpdated, setSearchProfileUpdated] = useState<boolean>(false);

  const columnsTable = useMemo(() => {
    if (isMobileScreen)
      return STUDENT_APPLICATION_COLUMNS.filter((column) => ["full_name", "profile_code"].includes(column.title));
    return STUDENT_APPLICATION_COLUMNS;
  }, [isMobileScreen]);
  const listQueries = useSelector(
    (state: RootState) => state.studentcontacts.listQueries
  );
  
  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getUnits());
    await dispatch(getOptionsforOnlineAdmission());
    await dispatch(getAdmissionMethods());
    await dispatch(getListQueryStudent());
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }

    if (Object.keys(searchOption).length > 0) {
      handleClearAllTagSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setLimit(candidatePerPage);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    await dispatch(getAllCandidates());
    setIsShowAction(false);
    setLoading(false);
  };

  useEffect(() => {
    setCheckedList(listFilter);
  }, [listFilter]);

  useEffect(() => {
    if (!checkedList || checkedList.length === 0) {
      setIsSelectAllPage(false);
    }
  }, [checkedList]);

  useEffect(() => {
    setCheckedList([]);
    if (!location.state?.triggerUpdate) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, candidatePerPage, searchOption]);

  useEffect(() => {
    setData(candidates);
    if (!isSelectAllPage) {
      setCheckedList([]);
    }
  }, [candidates]);

  useEffect(() => {
    if (allOptions) {
      setOptions(allOptions);
    }
  }, [allOptions]);
  
  useEffect(() => {
    let check: boolean = false;
    if (searchOption) {
      Object.keys(searchOption).map((item) => {
        if (
          (Array.isArray(searchOption[item]) && searchOption[item].length !== 0) ||
          (typeof searchOption[item] === "object" && Object.keys(searchOption[item]).length !== 0) ||
          (typeof searchOption[item] === "boolean" && (searchOption[item] || item === "updated_profile"))
        ) {
          check = true;
          return null;
        }
        return null;
      });
    }
    setIsShowClearAll(check);
  }, [searchOption]);
  
  const applyCallbackCreateDate = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setCurrentPage(1);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "created_date"));
    fetchData();
  };
  const handlePageClick = (page: number = 1) => {
    dispatch(setPageCandidate(page));
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    if (!isSelectAllPage) {
      setCheckedList([]);
      setIsShowAction(false);
    }
    dispatch(getAllCandidates())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = useCallback((checkbox: number[]) => {
    setCheckedList(checkbox);
    if (checkbox.length > 0) {
      setIsShowAction(true);
    } else {
      setIsShowAction(false);
    }
  }, []);

  const handleClickAction = useCallback((action: string) => {
    if (action === "export") {
      setIsOpenModalConfirmExport(true);
    }
  }, []);

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllPage]);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setLimitCandidate(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = useCallback(() => {
    if (isSelectAllPage) {
      dispatch(updateListFilter([]));
      setIsShowAction(false);
      setIsSelectAllPage(false);
    } else {
      dispatch(fetchAllFilterCandidates());
      setIsShowAction(true);
      setIsSelectAllPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllPage]);

  const handleDeleteFieldSearch = (field: string) => {
    switch (field) {
      case "text":
        setSearchText("");
        break;
      case "online_profile":
        setOptionsOnlineProflie({});
        break;
      case "offline_profile":
        setOptionsOfflineProflie({});
        break;
      case "admission_fee":
        setSearchAdmissionFee("");
        break;
      default:
        break;
    }

    dispatch(deleteFieldSearch(field));
  };

  const handleClearAllTagSearch = () => {
    dispatch(updateSearch(initialSearchOptionCandidateContact));
    setSearchText("");
    setOptionsOnlineProflie({});
    setOptionsOfflineProflie({});
    setSearchAdmissionFee("");
  };

  const handleSearchList = () => {
    if (searchText === searchOption?.text) {
      return;
    }
    dispatch(updateSearchOption(searchText, "text"));
  };

  const handleSearchAdmissionFee = (admissionFee: string) => {
    setSearchAdmissionFee(admissionFee)
    dispatch(updateSearchOption(admissionFee, "admission_fee"));
  };

  const handleClickMenuItem = (row: LooseObject, field: string) => {
    if (field === "online_profile") {
      setOptionsOnlineProflie(row);
    } else if (field === "offline_profile") {
      setOptionsOfflineProflie(row);
    }
    dispatch(updateSearchOption(row, field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const optionsFilterOnlineProfile = useMemo(() => {
    return [
      { id: 1, name: t("complete_profile") },
      { id: 0, name: t("incomplete_profile") },
    ];
  }, [t]);

  const optionsFilterOfflineProfile = useMemo(() => {
    return [
      { id: 1, name: t("complete_profile") },
      { id: 0, name: t("incomplete_profile") },
    ];
  }, [t]);

  const handleExportContact = async () => {
    setLoading(true);
    let response: any;
    if (checkedList.length !== 0) {
      response = await dispatch(exportStudentApplication(0, checkedList));
    } else {
      response = await dispatch(exportStudentApplication(1));
    }
    if (response) {
      try {
        const href = window.URL.createObjectURL(response);
        const fileName = "student-application-" + moment().format("DD-MM-YYYY");
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        toast.success<void>(t("export_successful"));
      } catch (error) {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }

    setLoading(false);
    setIsOpenModalConfirmExport(false);
  };
  
  const handleOpenModalFilter = async () => {
    setIsOpenModalFilter(true);
  };
  
  const handleGetQuery = (id: number) => {
    dispatch(getQueryStudent(id));
  };
  
  const handleDeleteQuery = async (id: number) => {
    const check = await dispatch(deleteQueryStudent(id));
    if (check) {
      toast.success<void>(t("filter_removed_successfully"));
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
  };
  
  const handleSearchFilter = (
    data: LooseObject,
    option: LooseObject
  ) => {
    let obj: LooseObject = getValueFromFilterSearch(data);
    dispatch(updateOptions(option));
    dispatch(updateFilterOptions(obj));
    setIsOpenModalFilter(false);
  };
  
  const handleSaveQuery = async (data: any) => {
    setLoading(true);
    let obj: LooseObject = getValueFromFilterSearch(data);
    const name: string = data.name;
    delete obj.name;
    if (Object.keys(obj).length === 0) {
      toast.warning<void>(t("please_fill_in_the_data_in_the_form"));
      setLoading(false);
      return;
    }
    const query = {
      name: name,
      params: { ...obj },
      type: 1,
    };
    const check = await dispatch(saveQuery(query));
    if (check) {
      toast.success<void>(t("filter_created_successfully"));
      dispatch(getListQueryStudent());
      setIsOpenModalFilter(false);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };
  
  const getValueFromFilterSearch = (data: LooseObject) => {
    let obj: LooseObject = {}
    Object.entries(data).forEach(([key, value]) => {
      if ((key === "created_at_from" || key === "created_at_to") && value) {
        obj["created_date"] ??= {};
        obj["created_date"][key === "created_at_from" ? "startDate" : "endDate"] = moment(value);
      } else if (
        value !== undefined &&
        value !== null &&
        (typeof value === "string" ? value.trim() !== "" : Array.isArray(value) && value.length !== 0)
      ) {
        obj[key] = value;
      }  else if (
        typeof value === "object" &&
        value !== null &&
        Object.keys(value).length !== 0 &&
        "id" in value
      )  {
        if (key === "updated_profile") {
          obj[key] = value.id === 1;
        }  else if ( ["aspiration_ids", "admission_method_ids", "admission_period_ids"].includes(key)) {
          obj[key] = Array.isArray(value) ? value : [value];
        }
        else {
          obj[key] = { id: value.id, name: value.name };
        }
      } else if (
        ["is_paid", "online_profile", "offline_profile"].includes(key) &&
        (value === 0 || value === 1)
      ) {
        obj[key] = value;
      }
    });
    return obj;
  };
  
  const handleSearchProfileUpdated = () => {
    setSearchProfileUpdated((prev) => {
      const newValue = !prev;
      dispatch(updateSearchOption(newValue, "updated_profile"));
      return newValue;
    });
  };
  
  useEffect(() => {
    if (location.state?.triggerUpdate) {
      dispatch(updateSearchOption(true, "updated_profile"));
      setSearchProfileUpdated(true)
      fetchData()
    }
  }, [location.state]);
  
  return (
    <>
      <Helmet>
        <title>{t("student_application")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("student_application")} />
        <div>
          <div className={classes.contentPage}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <div className={classes.wrapSearchFilter}>
                  <div className={classes.containerSearch}>
                    <SearchBar
                      value={searchText}
                      className={classes.inputSearch}
                      handleChange={setSearchText}
                      onBlur={handleSearchList}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleSearchList();
                          const target = e.target as HTMLButtonElement;
                          target.blur();
                        }
                      }}
                    />
                    <div className={classes.wrapDropdown}>
                      <DropdownRadio
                        title={t("online_profile")}
                        className={classes.dropdown}
                        options={optionsFilterOnlineProfile}
                        onClickItem={handleClickMenuItem}
                        fieldSearch="online_profile"
                        selectedItem={optionsOnlineProflie}
                      />
                      <DropdownRadio
                        title={t("offline_profile")}
                        className={classes.dropdown}
                        options={optionsFilterOfflineProfile}
                        onClickItem={handleClickMenuItem}
                        fieldSearch="offline_profile"
                        selectedItem={optionsOfflineProflie}
                      />
                      <Dropdown
                        title={t("admission_method")}
                        className={classes.dropdown}
                        options={options.admissionMethods}
                        withCheckbox
                        onClickItem={handleClickMenuItem}
                        fieldSearch="admission_method_ids"
                        searchOption={searchOption}
                      />
                      <Dropdown
                        title={t("aspirations")}
                        className={classes.dropdown}
                        options={options.aspirations}
                        withCheckbox
                        onClickItem={handleClickMenuItem}
                        fieldSearch="aspiration_ids"
                        searchOption={searchOption}
                      />
                      <BasicDateRange
                        title={t("created_date")}
                        start={startCreate}
                        end={endCreate}
                        applyCallback={applyCallbackCreateDate}
                      />
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={classes.containerBtn}>
                  <BaseButton
                    title={isMobileScreen ? "" : t("advanced_search")}
                    className={`${classes.btn}`}
                    endIcon={<span className={classes.inconNumber}>{numberAdvancedSearch}</span>}
                    onClick={() => handleOpenModalFilter()}
                    icon={isMobileScreen ? <PersonSearchOutlined /> : null}
                  />
                  
                  <RoleBaseComponent
                    permissions={["student_contact_view_saved_query_own"]}
                  >
                    <DropdownQuery
                      title={isMobileScreen ? "" : t("saved_query")}
                      icon={
                        isMobileScreen ? <CollectionsBookmarkOutlined /> : null
                      }
                      options={listQueries}
                      className={`${classes.btn} ${classes.btnLeft}`}
                      onClickItem={handleGetQuery}
                      onDelete={handleDeleteQuery}
                      endIcon={
                        <span className={classes.iconNumber}>
                          {listQueries.length}
                        </span>
                      }
                      withSearch
                    />
                  </RoleBaseComponent>

                  <BaseButton
                    title={isMobileScreen ? "" : t("reports")}
                    className={`${classes.btn}`}
                    startIcon={isMobileScreen ? null : <LogoReports />}
                    icon={isMobileScreen ? <LogoReports /> : null}
                    showToolTip={isMobileScreen}
                    textToolTip={t("reports")}
                    onClick={() => {
                      navigate(`/studentApplicationReport`);
                    }}
                  />

                  <BaseButton
                    title={isMobileScreen ? "" : t("export")}
                    className={`${classes.btn}`}
                    onClick={() => {
                      if (checkedList.length === 0 && total === 0) {
                        toast.warning<void>(t("no_data_found"));
                      } else {
                        setIsOpenModalConfirmExport(true);
                      }
                    }}
                    startIcon={isMobileScreen ? null : <FileUploadOutlined />}
                    icon={isMobileScreen ? <FileUploadOutlined /> : null}
                    showToolTip={isMobileScreen}
                    textToolTip={t("export")}
                  />
                </div>
              </Grid>
            </Grid>

            <div className={classes.containerFilter}>
              {Object.entries(searchOption).map(([category, options]) => {
                let text: any = category as any;
                if (category === "updated_profile" && typeof options === "boolean") {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options ? t("Có") : t("Không")}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                if (
                  options &&
                  typeof options === "object" &&
                  ["created_date", "payment_date"].includes(category) &&
                  "startDate" in options &&
                  "endDate" in options &&
                  options?.startDate &&
                  options?.endDate
                ) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${moment(options?.startDate).format("YYYY/MM/DD")} -> ${moment(
                        options?.endDate
                      ).format("YYYY/MM/DD")}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (Array.isArray(options) && options.length !== 0) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${formatSearchContent(options, t("or"))}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && (typeof options === "string" || typeof options === "boolean")) {
                  let field;
                  if (typeof options === "boolean") {
                    field = t(text);
                  } else if (text === "admission_fee") {
                    field = t(options);
                  } else {
                    field = `${t(text)}: ${options}`
                  }

                  return (
                    <FilterTag
                      title={text}
                      field={field}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "object" && "name" in options && options?.name) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options?.name}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                return null;
              })}

              {isShowClearAll && (
                <div className={classes.clearAll} onClick={() => handleClearAllTagSearch()}>
                  <p>{t("clear_all")}</p>
                </div>
              )}
            </div>
            <div className={classes.results}>{isShowClearAll && `${totalCandidates} ${t("results")}`}</div>
          </div>
          <div className={classes.contentPage}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className={classes.wrapStatusFilter}>
                  {admissionFees &&
                    Object.entries(admissionFees).map(([admissionFee, value]) => (
                      <Box key={admissionFee}>
                        <BaseButton
                          title={`${t(admissionFee)} (${value})`}
                          className={`${classes.btnAdmissionFees} ${(searchAdmissionFee === admissionFee) ? classes.activeAdmissionFeesButton : classes.normalAdmissionFeesButton}`}
                          onClick={() => handleSearchAdmissionFee(admissionFee)}
                        />
                      </Box>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={`${classes.wrapStatusFilter} ${classes.justifyContentEnd}`}>
                  <BaseButton
                    title={t("updated_profile")}
                    className={`${classes.btnUpdatedProfile} ${searchProfileUpdated ? classes.pinkButton : classes.purpleButton}`}
                    onClick={() => handleSearchProfileUpdated()}
                  />
                </Box>
              </Grid>
            </Grid>
            <Table
              data={data}
              columns={columnsTable}
              total={totalCandidates}
              limit={limit}
              pageSize={candidatePerPage}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleCheckBox={handleCheckBox}
              isShowAction={isShowAction}
              actionMenu={ACTION_CANDIDATES_LIST as any[]}
              handleClickAction={handleClickAction}
              checkedList={checkedList}
              handleSelectAll={handleSelectAll}
              isSelectAllPage={isSelectAllPage}
            />
          </div>
        </div>
      </div>
      {isOpenModalFilter && (
        <ModalFilter
          isOpen={isOpenModalFilter}
          onClose={() => setIsOpenModalFilter(false)}
          optionsSelect={options}
          onConfirmSearch={handleSearchFilter}
          onConfirmSaveQuery={handleSaveQuery}
          filterField={FILTER_ONLINE_ADMISSION_FIELD}
          filterOptions={searchOption}
          isSearchListContact={isSearchListContact}
          handleSearchList={handleSearchList}
        />
      )}
      {isOpenModalConfirmExport && (
        <ModalConfirm
          isOpen={isOpenModalConfirmExport}
          title={t("export")}
          htmlContent={
            checkedList.length === 0 ? (
              <p className={classes.contentModalExport}>
                <span className={classes.nameExport}>{t("export_according_to_search")}</span>
                {t("numbers")}
                {` (${total})`}
              </p>
            ) : (
              <p className={classes.contentModalExport}>
                <span className={classes.nameExport}>{`${t("numbers")}: `}</span>
                {checkedList.length}
              </p>
            )
          }
          onClose={() => {
            setIsOpenModalConfirmExport(false);
          }}
          onConfirm={() => handleExportContact()}
          contentNo="close"
          contentYes="export"
        />
      )}
    </>
  );
}
