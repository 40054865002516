import { LooseObject } from "models/common";
import { useStyles } from "./styles";

interface TabBarProp {
  selected: number;
  data: LooseObject[];
  className?: string;
  handleClick: Function;
  isDisable?: Boolean;
  enableDisableLogic?: boolean;
}

export default function TabBar({ selected, data, className, handleClick, isDisable, enableDisableLogic = false }: TabBarProp) {
  const { classes } = useStyles();

  const getTabClass = (index: number, length: number, selectedValue: number, itemValue: number) => {
    const isFirstTab = index === 0;
    const isLastTab = index === length - 1;

    if (isFirstTab) {
      return selectedValue === itemValue ? classes.tabStartSelected : classes.tabStart;
    } else if (isLastTab) {
      return selectedValue === itemValue ? classes.tabEndSelected : "";
    } else {
      return selectedValue === itemValue ? classes.tabCenterSelected : classes.tabCenter;
    }
  };

  const isTabDisabled = (itemId: number, selectedValue: number) => {
    if (!enableDisableLogic) return false;
    return (selectedValue >= 3 && itemId < 3) || (selectedValue < 3 && itemId >= 3);
  };


  return (
    <div className={`${classes.container} ${className || ""}`}>
      {Array.isArray(data) &&
        data.map((item: LooseObject, index: number) => {
          const disabled = isTabDisabled(item.id, selected);

          return (
            <div
              key={item?.id}
              className={`${classes.tab} ${getTabClass(index, data.length, selected, item.id)} ${disabled ? classes.isDisable : ""
                }`}
              onClick={() => {
                if (disabled || selected === item?.id) {
                  return;
                }
                handleClick(item);
              }}
            >
              {item?.name}
            </div>
          );
        })}
    </div>
  );
}
