import { makeStyles } from "tss-react/mui";

import iconEmail from "../../assets/images/email.svg";
import iconSms from "../../assets/images/sms.svg";
import iconCall from "../../assets/images/call.svg";
import iconUrgent from "../../assets/images/urgent.svg";

import { Theme } from "@mui/material";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    boxSizing: "border-box",
    overflowY: "auto",
    position: "relative",
  },
  table: {
    width: "100%",
    maxWidth: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    textAlign: "left",
    "& tr": {
      height: "40px",
    },
    "& th": {
      backgroundColor: "#A0A9AF",
      color: "#F8F9FA",
      fontSize: "14px",
      fontWeight: 500,
    },
    "& td": {
      fontSize: "14px",
      fontWeight: 400,
    },
    "& a": {
      textDecoration: "none",
      "& p:hover": {
        color: colors.btnPrimary,
      },
    },
  },
  titleTable: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "0 5px",
  },
  cellTable: {
    maxWidth: "280px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0 5px",
    "& p": {
      maxWidth: "280px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  evenRow: {
    backgroundColor: colors.backgroundLight,
  },
  oddRow: {
    backgroundColor: "#F2F2F2",
  },
  checkboxRow: {
    [theme.breakpoints.up("sm")]: {
      width: "30px",
    },
    paddingLeft: "5px",
  },
  noRow: {
    width: "50px",
    paddingLeft: "10px",
  },
  noData: {
    textAlign: "center",
    fontSize: "14px",
  },
  footerTable: {
    backgroundColor: "#7C8286",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    [theme.breakpoints.between("xs", "md")]: {
      flexDirection: "column",
      height: "auto",
      alignItems: "start",
    },
  },
  headerTable: {
    backgroundColor: "#7C8286",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    [theme.breakpoints.between("xs", "md")]: {
      flexDirection: "column",
      height: "auto",
      alignItems: "start",
    },
  },
  countSelected: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    "& p": {
      fontSize: "14px",
      fontWeight: 700,
      color: colors.backgroundLight,
      marginLeft: "14px",
      [theme.breakpoints.between("xs", "md")]: {
        margin: "3px",
      },
    },
  },
  red: {
    color: "#F2756F",
  },
  arrowTable: {
    position: "relative",
    marginBottom: "-5px",
    marginLeft: "10px",
  },
  btnSelectAll: {
    backgroundColor: colors.backgroundLight,
    color: colors.defaultColorText,
    minWidth: "unset",
    borderRadius: "4px",
    marginLeft: "2px",
    padding: "3px 8px",
    "&:hover": {
      backgroundColor: colors.backgroundLight,
      opacity: 0.8,
    },
    "& .MuiButton-startIcon, .MuiButton-endIcon": {
      margin: 0,
    },
    [theme.breakpoints.between("xs", "md")]: {
      margin: "5px 10px",
    },
  },
  inputCheckedALL: {
    fontSize: "16px !important",
  },
  inputCheckedALLChecked: {
    fontSize: "16px !important",
    color: "#2977ff",
  },
  action: {
    marginLeft: "60px",
    textTransform: "none",
    color: colors.backgroundLight,
    fontWeight: 700,
    "& .MuiMenuItem-root": {
      fontWeight: 400,
      fontSize: "14px",
    },
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: "10px",
    },
  },
  menuItem: {
    fontWeight: 400,
    fontSize: "14px",
    minWidth: "80px",
    height: "25px",
  },
  iconPen: {
    color: colors.btnPrimary,
  },
  flexField: {
    display: "flex",
  },
  iconBeside: {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: colors.btnPrimary,
  },
  widthField: {
    width: "100px",
  },
  widthField120: {
    width: "120px",
  },
  w160: {
    width: "160px",
  },
  alignCenterField: {
    display: "flex",
    alignItems: "center",
  },
  iconMenu: {
    display: "block",
    width: "24px",
    height: "24px",
    backgroundColor: colors.btnPrimary,
  },
  iconEmail: {
    maskImage: `url(${iconEmail})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  iconSms: {
    maskImage: `url(${iconSms})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  iconCall: {
    display: "block",
    width: "20px",
    height: "20px",
    maskImage: `url(${iconCall})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
    backgroundColor: "#cccccc",
  },
  successfulCall: {
    backgroundColor: colors.btnPrimary + "!important",
  },
  failedCall: {
    backgroundColor: "#F2756F !important",
  },
  iconUrgent: {
    backgroundColor: "#e74c3c",
    maskImage: `url(${iconUrgent})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  wrapAction: {
    display: "flex",
    alignItems: "center",
  },
  wasteBasket: {
    color: "#FF0000",
  },
  titleClickAble: {
    color: "#F2756F",
    cursor: "pointer",
    "&:hover": {
      color: "#333",
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  popupLog: {
    padding: "10px",
    display: "flex",
  },
  maxWidth180: {
    maxWidth: "180px !important",
  },
  maxWidth120: {
    maxWidth: "120px !important",
  },
}));
