import { makeStyles } from "tss-react/mui";
import logoContact from "../../assets/images/contact.svg";
import logoMarketing from "../../assets/images/marketing.svg";
import logoSales from "../../assets/images/sales.svg";
import logoReports from "../../assets/images/reports.svg";
import logoOnlineAdmission from "../../assets/images/online_admission.svg";
import logoStudentApplication from "../../assets/images/student_applications.svg";
import logoSettings from "../../assets/images/settings.svg";
import colors from "../../utils/colors";

export const useStyles = makeStyles()(() => ({
  btnMenu: {
    backgroundColor: colors.btnDark,
    display: "block",
    fontWeight: "500",
    padding: "0 15px",
    color: colors.backgroundLight,
    textTransform: "none",
    fontFamily: "Roboto",
    fontSize: "16px",
    width: "100%",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > span": {
        marginRight: "8px",
      },
    },
    "&:hover": {
      backgroundColor: "#5c5769",
    },
  },
  iconMenu: {
    display: "block",
    width: "20px",
    height: "20px",
    minWidth: "20px",
    backgroundColor: "white",
    maskRepeat: "no-repeat",
  },
  iconContact: {
    maskImage: `url(${logoContact})`,
  },
  iconMarketing: {
    maskImage: `url(${logoMarketing})`,
  },
  iconSales: {
    maskImage: `url(${logoSales})`,
  },
  iconReports: {
    maskImage: `url(${logoReports})`,
  },
  iconSettings: {
    maskImage: `url(${logoSettings})`,
  },
  iconOnlineAdmission: {
    maskImage: `url(${logoOnlineAdmission})`,
  },
  iconStudentApplication: {
    maskImage: `url(${logoStudentApplication})`,
  },
  isActive: {
    color: "#F2756F",
  },
  isActiveIcon: {
    backgroundColor: "#F2756F !important",
  },
  menuItem: {
    minWidth: "80px",
    fontFamily: "Roboto",
    color: colors.defaultColorText,
  },
}));
