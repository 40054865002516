import React, {useEffect, useState} from "react";
import {Box, Typography, MenuItem, Select, FormControl} from "@mui/material";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList} from "recharts";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import {axiosClient} from "../../../services/axiosClient";
import {API} from "../../../utils/api";
import moment from "moment/moment";
import BaseButton from "../../../components/Buttons/BaseButton";
import {useNavigate} from "react-router-dom";
import BasicDateRange from "../../../components/dropdownDateRangePicker";
import {admissionMethods, programs} from "../../../utils";

type SummaryItem = { label: string; value: number; };
type CtdtItem = { name: string; total: number; paid: number; };

interface ChartData {
	name: string;
	value: number;
}

interface DailyItem {
	date: string;
	total_applications: number;
	method_1_count: number;
	other_methods_count: number;
}


export default function OnlineAdmissionReport() {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [dataRender, setDataRender] = useState<any>();
	const [summaryData, setSummaryData] = useState<SummaryItem[]>([]);
	const [ctdtData, setCtdtData] = useState<CtdtItem[]>([]);
	const [dailyData, setDailyData] = useState<DailyItem[]>([]);
	const [selectedProgram, setSelectedProgram] = useState<number>(programs[0].id);
	const [ptxtData, setPtxtData] = useState<ChartData[]>([]);
	const [startCreate, setStartCreate] = useState(moment().startOf('year'));
	const [endCreate, setEndCreate] = useState(moment().add(1, 'year').startOf('year'));
	
	useEffect(() => {
		setLoading(true);
		const params = {
			create_date_start: startCreate.format('YYYY-MM-DD'),
			create_date_end: endCreate.format('YYYY-MM-DD')
		};
		
		axiosClient
			.get(API.getReport, { params: { ...params } })
			.then((res) => {
				setDataRender(res.data);
				setTimeout(() => {
					setLoading(false);
				}, 500);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	}, [startCreate, endCreate]);
	
	const generateDateRange = (start: moment.Moment, end: moment.Moment) => {
		const dates = [];
		let current = start.clone();
		while (current.isBefore(end)) {
			dates.push({
				date: current.format("DD/MM/YYYY"),
				total_applications: 0,
				method_1_count: 0,
				other_methods_count: 0
			});
			current.add(1, "day");
		}
		return dates;
	};
	
	useEffect(() => {
		if (!dataRender) return;
		
		const summary: SummaryItem[] = [
			{ label: "Tổng số hồ sơ", value: dataRender.report_by_admission[0]?.total_count ?? 0 },
			{ label: "Tổng hồ sơ PT phỏng vấn", value: dataRender.report_by_admission[0]?.method_1_count ?? 0 },
			{ label: "Tổng hồ sơ PT khác", value: dataRender.report_by_admission[0]?.other_methods_count ?? 0 },
		];
		setSummaryData(summary);
		
		const ctdt: CtdtItem[] = programs.map((p) => {
			const item = dataRender.report_by_aspiration.find(
				(i : any) => i.aspiration_id === p.id
			)
			return {
				name: p.name_vi,
				total: item?.total_count ?? 0,
				paid: Number.isNaN(Number(item?.paid_count)) ? 0 : Number(item?.paid_count),
			}
		})
		setCtdtData(ctdt);
		
		const daily: DailyItem[] = dataRender.report_by_registration_date.length > 0
			? dataRender.report_by_registration_date.map((item: any) => ({
				date: moment(item.registration_date).format("DD/MM/YYYY"),
				total_applications: item.total_applications,
				method_1_count: item.method_1_count,
				other_methods_count: item.other_methods_count,
			}))
			: generateDateRange(startCreate, endCreate);
		
		setDailyData(daily);
		
		const programKey = selectedProgram.toString();
		
		const item = dataRender.report_by_aspiration_and_admission[programKey] || {};
		
		const programData = admissionMethods.map((method) => {
			return {
				name: method.name_vi,
				value: (item && item[method.id]) ?? 0,
			};
		});
		
		setPtxtData(programData);
	}, [dataRender, selectedProgram]);
	
	const onClickBack = () => {
		navigate("/onlineAdmission")
	}
	
	const applyCallbackCreateDate = (startDate: any, endDate: any) => {
		setStartCreate(startDate);
		setEndCreate(endDate);
	};
	
	return (
		<>
			<Helmet>
				<title>{t("online_admissions_report")}</title>
			</Helmet>
			{loading && <Loading />}
			<div className="container">
				<HeaderPage title={t("online_admissions_report")} />
				<div>
					<div className={classes.contentPage}>
						<BasicDateRange
							title={t("created_date")}
							start={startCreate}
							end={endCreate}
							isReport={true}
							applyCallback={applyCallbackCreateDate}
						/>
						<Box p={3}>
							<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "40px" }}>
								{summaryData.map((item) => (
									<div
										key={item.label}
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "240px",
										}}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												borderRadius: '100px',
												width: '124px',
												height: '124px',
												border: '8px solid #1F4E79',
											}}
										>
											<Typography style={{ color: "#1F4E79", fontSize: "32px", fontWeight: "700" }}>
												{item.value}
											</Typography>
										</div>
										<Typography
											variant="subtitle1"
											style={{ color: "#1F4E79", fontSize: "20px", fontWeight: "600", textAlign: "center" }}
										>
											{item.label}
										</Typography>
									</div>
								))}
							</div>
							
							
							<Box mt={1} display="flex" gap={2} justifyContent="space-between">
								<Box flex={1}>
									<Typography variant="h6" pb={5} align="center">TỔNG SỐ HỒ SƠ THEO CTĐT</Typography>
									<ResponsiveContainer width="100%" height={300}>
										<BarChart data={ctdtData}>
											<XAxis dataKey="name" interval={0} />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="total" fill="#8979FF" name="Tổng số hồ sơ" barSize={20}>
												<LabelList dataKey="total" position="top" fontSize={10} fill="#000000" />
											</Bar>
											<Bar dataKey="paid" fill="#FF928A" name="Đã thanh toán" barSize={20}>
												<LabelList dataKey="paid" position="top" fontSize={10} fill="#000000" />
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</Box>
								
								<Box flex={1}>
									<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
										<Typography variant="h6" align="center" mr={2}>TỔNG SỐ HỒ SƠ THEO PTXT</Typography>
										<FormControl size="small">
											<Select
												value={selectedProgram}
												onChange={(e) => setSelectedProgram(Number(e.target.value))}
											>
												{programs.map((program) => (
													<MenuItem sx={{ typography: "h6" }} key={program.id} value={program.id}>
														{program.name_vi}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>
									
									<ResponsiveContainer width="100%" height={300}>
										<BarChart data={ptxtData}>
											<XAxis
												dataKey="name"
												interval={0}
												tick={(props) => {
													const { x, y, payload } = props;
													const words: string[] = payload.value.match(/.{1,20}/g) || [payload.value];
													
													return (
														<text x={x} y={y + 10} textAnchor="middle" fontSize={12}>
															{words.map((word: string, index: number) => (
																<tspan key={index} x={x} dy={index * 14}>
																	{word}
																</tspan>
															))}
														</text>
													);
												}}
											/>
											<YAxis />
											<Tooltip />
											<Bar dataKey="value" fill="#8979FF" name="Số hồ sơ" barSize={30}>
												<LabelList dataKey="value" position="top" fontSize={10} fill="#000000" />
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</Box>
							</Box>
							
							<Box mt={2}>
								<Typography variant="h6" align="center">THỐNG KÊ TỔNG SỐ HỒ SƠ THEO NGÀY ĐĂNG KÝ</Typography>
								<ResponsiveContainer width="100%" height={380}>
									<BarChart margin={{ bottom: 80 }} data={dailyData}>
										<XAxis
											dataKey="date"
											angle={-45}
											textAnchor="end"
											interval={0}
										/>
										<YAxis />
										<Tooltip />
										<Legend layout="horizontal" verticalAlign="top" align="center" />
										<Bar dataKey="other_methods_count" stackId="a" fill="#8979FF" name="Phương thức khác">
										</Bar>
										<Bar dataKey="method_1_count" stackId="a" fill="#FF928A" name="Phương thức phỏng vấn">
											<LabelList dataKey="total_applications" position="top" fontSize={10} fill="#000000" />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</Box>
						</Box>
					</div>
				</div>
				<BaseButton sx={{ ml: 4 }} title={t("back")} className={classes.btnCancel} onClick={() => onClickBack()} />
			</div>
		</>
	);
}
