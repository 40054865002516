import React, {useEffect, useState} from "react";
import {Box, Card, Typography, Grid} from "@mui/material";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList} from "recharts";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import moment from "moment/moment";

import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../redux/store";
import {axiosClient} from "../../../services/axiosClient";
import {API} from "../../../utils/api";
import BaseButton from "../../../components/Buttons/BaseButton";
import BasicDateRange from "../../../components/dropdownDateRangePicker";
import {admissionMethods, programs} from "../../../utils";

type SummaryItem = { label: string; value: number; };
type ReviewData = {
	name: string;
	total_online: number;
	total_offline: number;
};

type SimpleData = {
	name: string;
	total: number;
};

export default function StudentApplicationReport() {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [dataRender, setDataRender] = useState<any>();
	const [summaryData, setSummaryData] = useState<SummaryItem[]>([]);
	const [ctdtData, setCtdtData] = useState<SimpleData[]>([]);
	const [ctdtReviewData, setCtdtReviewData] = useState<ReviewData[]>([]);
	const [ptxtReviewData, setPtxtReviewData] = useState<ReviewData[]>([]);
	const dispatch = useAppDispatch();
	const [startCreate, setStartCreate] = useState(moment().startOf('year'));
	const [endCreate, setEndCreate] = useState(moment().add(1, 'year').startOf('year'));
	
	useEffect(() => {
		setLoading(true);
		const params = {
			create_date_start: startCreate.format('YYYY-MM-DD'),
			create_date_end: endCreate.format('YYYY-MM-DD')
		};
		
		axiosClient
			.get(API.getReportByApplication, { params: { ...params } })
			.then((res) => {
				setDataRender(res.data);
				setTimeout(() => {
					setLoading(false);
				}, 500);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	}, [startCreate, endCreate]);
	
	useEffect(() => {
		if (!dataRender) return;
		
		const summary: SummaryItem[] = [
			{ label: "Tổng số hồ sơ", value: dataRender.report_by_review_and_update_status[0]?.total_count ?? 0 },
			{ label: "Tổng hồ sơ online đầy đủ", value: dataRender.report_by_review_and_update_status[0]?.online_profile_status_count ?? 0 },
			{ label: "Tổng hồ sơ offline đầy đủ", value: dataRender.report_by_review_and_update_status[0]?.offline_profile_status_count ?? 0 },
		];
		setSummaryData(summary);
		
		const ctdt = programs.map((p) => {
			const item = dataRender.report_total_aspiration.find(
				(i: any) => i.aspiration_id === p.id
			);
			return {
				name: p.name_vi,
				total: item?.total_count?? 0,
			}
		})
		setCtdtData(ctdt);
		
		const ctdtReview = programs.map((p) => {
			const item = dataRender.report_total_aspiration_by_review.find(
        (i: any) => i.aspiration_id === p.id
      );
      
      return {
        name: p.name_vi,
        total_online: item?.online_profile_status_count?? 0,
        total_offline: item?.offline_profile_status_count?? 0,
      };
		})
		
		setCtdtReviewData(ctdtReview);
		
		const ptxtReview = admissionMethods.map((method) => {
			const item = dataRender.report_total_method_by_review.find(
				(i: any) => i.admission_method_id === method.id
			);
			
			return {
				name: method.name_vi,
				total_online: item?.online_profile_status_count ?? 0,
				total_offline: item?.offline_profile_status_count ?? 0,
			};
		});
		setPtxtReviewData(ptxtReview);
		
	}, [dataRender]);
	
	const onClickBack = () => {
		navigate("/studentApplication")
	}
	
	const handleClick = () => {
		navigate("/studentApplication", { state: { triggerUpdate: true } });
	};
	
	const applyCallbackCreateDate = (startDate: any, endDate: any) => {
		setStartCreate(startDate);
		setEndCreate(endDate);
	};
	
	return (
		<>
			<Helmet>
				<title>{t("student_application_report")}</title>
			</Helmet>
			{loading && <Loading />}
			<div className="container">
				<HeaderPage title={t("student_application_report")} />
				<div>
					<div className={classes.contentPage}>
						<BasicDateRange
							title={t("created_date")}
							start={startCreate}
							end={endCreate}
							isReport={true}
							applyCallback={applyCallbackCreateDate}
						/>
						<Box p={3}>
							<Grid container spacing={3}>
								{summaryData.map((item) => (
									<Grid item xs={12} sm={3} key={item.label} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
										<Card style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: '100px', width: '124px', height: '124px', border: '8px solid #1F4E79' }}>
											<Typography style={{ color: "#1F4E79", fontSize: "32px", fontWeight: "700" }}>{item.value}</Typography>
										</Card>
										<Typography variant="subtitle1" style={{ color: "#1F4E79", fontSize: "20px", fontWeight: "600" }}>{item.label}</Typography>
									</Grid>
								))}
								<Grid item xs={12} sm={3} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<Card
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											borderRadius: '100px',
											width: '124px',
											height: '124px',
											border: '8px solid #FF0000',
											cursor: "pointer",
										}}
										onClick={handleClick}
									>
										<Typography style={{ color: "#FF0000", fontSize: "32px", fontWeight: "700" }}>
											{ dataRender?.report_by_review_and_update_status[0]?.update_status_count ?? 0 }
										</Typography>
									</Card>
									<Typography variant="subtitle1" style={{ color: "#1F4E79", fontSize: "20px", fontWeight: "600" }}>Tổng số hồ sơ mới update</Typography>
								</Grid>
							</Grid>
							
							<Box
								mt={4}
								display="flex"
								gap={2}
								justifyContent="space-between"
								flexWrap="wrap"
							>
								<Box flex={{ xs: 1, md: 1 }}>
									<Typography variant="h6" align="center">
										TỔNG SỐ HỒ SƠ THEO CTĐT
									</Typography>
									<ResponsiveContainer width="100%" height={300}>
										<BarChart data={ctdtData}>
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="total" fill="#8979FF" name="Tổng số hồ sơ" barSize={20}>
												<LabelList dataKey="total" position="top" fontSize={10} fill="#000000" />
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</Box>
								
								<Box flex={{ xs: 1, md: 1 }}>
									<Typography variant="h6" align="center">
										TỔNG SỐ HỒ SƠ ĐẦY ĐỦ THEO CTĐT
									</Typography>
									<ResponsiveContainer width="100%" height={300}>
										<BarChart data={ctdtReviewData}>
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="total_online" fill="#8979FF" name="HS Online" barSize={20}>
												<LabelList dataKey="total_online" position="top" fontSize={10} fill="#000000" />
											</Bar>
											<Bar dataKey="total_offline" fill="#FF928A" name="HS Offline" barSize={20}>
												<LabelList dataKey="total_offline" position="top" fontSize={10} fill="#000000" />
                      </Bar>
										</BarChart>
									</ResponsiveContainer>
								</Box>
							</Box>
							<Box flex={{ xs: 1, md: 1 }}>
								<Typography variant="h6" align="center">
									TỔNG SỐ HỒ SƠ ĐẦY ĐỦ THEO PTXT
								</Typography>
								<ResponsiveContainer width="100%" height={300}>
									<BarChart data={ptxtReviewData}>
										<XAxis
											dataKey="name"
											interval={0}
											tick={(props) => {
												const { x, y, payload } = props;
												const words: string[] = payload.value.match(/.{1,20}/g) || [payload.value];
												
												return (
													<text x={x} y={y + 10} textAnchor="middle" fontSize={12}>
														{words.map((word: string, index: number) => (
															<tspan key={index} x={x} dy={index * 14}>
																{word}
															</tspan>
														))}
													</text>
												);
											}}
										/>
										<YAxis />
										<Tooltip />
										<Legend verticalAlign="top" align="center" layout="horizontal" wrapperStyle={{ padding: 10, fontSize: "12px" }} />
										<Bar dataKey="total_online" fill="#8979FF" name="HS Online" barSize={20}>
											<LabelList dataKey="total_online" position="top" fontSize={10} fill="#000000" />
                    </Bar>
										<Bar dataKey="total_offline" fill="#FF928A" name="HS Offline" barSize={20}>
											<LabelList dataKey="total_offline" position="top" fontSize={10} fill="#000000" />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</Box>
						</Box>
					</div>
				</div>
				<BaseButton sx={{ ml: 4 }} title={t("back")} className={classes.btnCancel} onClick={() => onClickBack()} />
			</div>
		</>
	);
}
