import { makeStyles } from "tss-react/mui";
import colors from "../../../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  inputList: {
    width: "20rem",
    zIndex: 1000,
    marginLeft: 10,
  },
  contentPage: {
    backgroundColor: colors.backgroundLight,
    margin: "16px",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px",
  },
  wrapSearchFilter: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  containerBtn: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    flexGrow: 1,
  },
  inputSearch: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "25vw",
    },
  },
  btnCreate: {
    backgroundColor: colors.btnCreate,
    "&:hover": {
      backgroundColor: colors.btnCreate,
    },
  },
  btnMiddle: {
    backgroundColor: "#4A4358",
    "&:hover": {
      backgroundColor: "#4A4358",
    },
  },
  btnLeft: {
    backgroundColor: "#6A7A83",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  btn: {
    marginBottom: "10px",
    marginRight: "10px",
  },
  iconAdd: {
    fontSize: "29px !important",
  },
  dropdown: {
    marginRight: "16px",
  },
  containerFilter: {
    display: "flex",
    flexWrap: "wrap",
  },
  wrapStatusFilterTab: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  wrapStatusFilter: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
  },
  wrapTab: {
    display: "flex",
    justifyContent: "flex-end",
    ".MuiTab-root": {
      padding: "0 10px",
      textTransform: "unset",
      fontSize: "14px",
      fontWeight: 600,
      color: colors.defaultColorText,
      minWidth: "unset",
      "&:hover": {
        opacity: "0.8",
        transition: "ease-in 0.2s",
      },
      borderBottom: "1px solid #A0A9AF",
      minHeight: "30px",
    },
    ".Mui-selected": {
      color: "#F2756F !important",
    },
    ".MuiTabs-indicator ": {
      backgroundColor: "#F2756F",
      height: "1px",
    },
  },
  btnStatus: {
    borderRadius: "12px",
    backgroundColor: "rgba(96, 91, 255, 0.10)",
    marginRight: "10px",
    marginBottom: "10px",
    fontSize: "12px",
    padding: "5px 10px",
    cursor: "pointer",
    color: "#4E4E4E",
    "&:hover": {
      opacity: "0.8",
      transition: "ease-in 0.2s",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  btnStatusAll: {
    fontWeight: 700,
  },
  emptyStatus: {
    backgroundColor: "#F2F2F2",
  },
  selectedStatus: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
  clearAll: {
    borderRadius: "12px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#D63916",
    padding: "0 10px",
    width: "fit-content",
    margin: "5px",
    cursor: "pointer",
    "& p": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#FFFFFF",
    },
    "&:hover": {
      opacity: "0.8",
      transition: "ease-in 0.2s",
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
  },
  iconNumber: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    fontSize: "12px !important",
    fontWeight: 700,
    color: colors.defaultColorText,
    padding: "0 8px",
    height: "16px",
    display: "flex",
    alignItems: "center",
  },
  nameDelete: {
    fontWeight: 700,
    overflowWrap: "break-word",
  },
  results: {
    fontSize: "14px",
    color: "#D63916",
    marginLeft: "5px",
    marginTop: "10px",
    fontWeight: 700,
  },
  contentModalExport: {
    textAlign: "left",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center"
  }
}));
