import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#4E4E4E',
    boxShadow: theme.shadows[1],
    maxWidth: 250,
    fontSize: 14,
    border: '1px solid #F5F5F5',
    padding: "11px 14px",
    whiteSpace: 'pre-line',
  },
}));

export default LightTooltip;
