import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Email } from "@mui/icons-material";
import { DialogActions, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import AutoCompleteForm from "components/Forms/AutoCompleteForm";
import CKEditorForm from "components/Forms/CKEditorForm";
import InputTextForm from "components/Forms/InputTextForm";
import SelectForm from "components/Forms/SelectForm";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { isEmpty } from "lodash";
import { SelectDataProp } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import contactCategorySlice, {
  contactCategoryListWithoutCurrentContactIdSelector,
  fetchContactCategoriesWithoutCurrentContactId,
} from "store/contactCategorySlice";
import { contactPointsForSelectedSelector, getContactsFromQueryAndCategory } from "store/contactPointSlice";
import {
  contentEmailSelector,
  detailEmailTemplate,
  getListEmailTemplate,
  listEmailTemplateSelector,
  sendEmailToContactUser,
  subjectEmailSelector,
} from "store/sendSmsAndEmailSlice";
import {
  MASTER_CONTACT_TYPE,
  STUDENT_CONTACT_TYPE,
  convertToArrayId,
  renderListQueriesAndCategoriesParams,
  uniqueTwoArray,
} from "utils";
import * as Yup from "yup";
import { useStyles } from "./styles";
import querySlice, { fetchQuery, querySelector } from "store/querySlice";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  dataSendEmail: SelectDataProp[];
  isMasterModal?: boolean;
  listId?: number[];
  isOnlineAdmission?: boolean;
}

export default function ModalSendEmail({ isOpen, onClose, isMasterModal, dataSendEmail, listId, isOnlineAdmission }: IProps) {
  const dispatch = useAppDispatch();
  const contentEmail = useSelector(contentEmailSelector);
  const subjectEmail = useSelector(subjectEmailSelector);
  const listEmailTemplates = useSelector(listEmailTemplateSelector);
  const contacts = useSelector(contactPointsForSelectedSelector);

  useEffect(() => {
    dispatch<any>(
      contactCategorySlice.actions.setTypeContactCategory(isMasterModal ? MASTER_CONTACT_TYPE : STUDENT_CONTACT_TYPE)
    );
    dispatch<any>(fetchContactCategoriesWithoutCurrentContactId());
    dispatch<any>(querySlice.actions.setTypeContact(isMasterModal ? MASTER_CONTACT_TYPE : STUDENT_CONTACT_TYPE));
    dispatch<any>(fetchQuery());
  }, [dispatch, isMasterModal]);
  const listCategories = useSelector(contactCategoryListWithoutCurrentContactIdSelector);
  const listQueries = useSelector(querySelector);

  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  const { t } = useTranslation();
  const { classes } = useStyles();

  const validationSchema = Yup.object().shape({
    contact_ids: Yup.array().min(1, t("this_field_is_required")),
    content: Yup.string().required(t("this_field_is_required")),
    email_template_id: Yup.string().notRequired(),
    subject: Yup.string().required(t("this_field_is_required")),
    contact_category_ids: Yup.array().notRequired(),
    contact_query_ids: Yup.array().notRequired(),
  });

  type FilterSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FilterSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contact_ids: [],
      content: "",
      email_template_id: "",
      subject: "",
      contact_category_ids: [],
      contact_query_ids: [],
    },
  });

  const emailTemplateId = watch("email_template_id");
  const selectedQuery = watch("contact_query_ids");
  const selectedListContact = watch("contact_category_ids");

  const convertToListParams = renderListQueriesAndCategoriesParams(
    convertToArrayId(selectedQuery ?? []),
    convertToArrayId(selectedListContact ?? [])
  );

  const getContacts = useMemo(() => {
    return async (listParams: any) => {
      await dispatch(getContactsFromQueryAndCategory(listParams));
    };
  }, [dispatch]);

  useEffect(() => {
    getContacts(convertToListParams);
  }, [convertToListParams, getContacts]);

  const contactsShorten = useMemo(() => {
    return contacts.map((item: any) => ({ id: item.id, name: item.email }));
  }, [contacts]);

  const mergeContacts = useMemo(() => {
    return uniqueTwoArray(contactsShorten, dataSendEmail);
  }, [contactsShorten, dataSendEmail]);

  const filterEmptyEmail = useMemo(() => {
    return mergeContacts?.filter((item: any) => !isEmpty(item.name));
  }, [mergeContacts]);

  useEffect(() => {
    setValue("contact_ids", listId && listId.length > 0 ? listId : filterEmptyEmail);
    setValue("content", contentEmail as string);
    setValue("subject", subjectEmail as string);
  }, [dataSendEmail, contentEmail, setValue, subjectEmail, filterEmptyEmail, listId]);

  const getContentEmailTemplate = useMemo(() => {
    return async (templateId: any) => {
      await dispatch(detailEmailTemplate(templateId));
      await dispatch(getListEmailTemplate());
    };
  }, [dispatch]);

  useEffect(() => {
    getContentEmailTemplate(emailTemplateId);
  }, [getContentEmailTemplate, emailTemplateId]);

  const handleClose = () => {
    if (isDirty) {
      return setIsOpenModalConfirmLeaveForm(true);
    }
    onClose();
  };

  const onSubmitSms = async (data: FilterSubmitForm) => {
    const dataSubmit = {
      ...data,
      contact_ids: listId && listId.length > 0 ? listId : convertToArrayId(data.contact_ids ?? []),
      contact_category_ids: convertToArrayId(data.contact_category_ids ?? []),
      contact_query_ids: convertToArrayId(data.contact_query_ids ?? []),
      is_online_admission: isOnlineAdmission,
    };

    await dispatch(sendEmailToContactUser(dataSubmit));
    toast.success<void>(t("send_email_success"));
    reset();
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const getErrorsValidate = (name: any) => {
    return errors[name as keyof typeof errors]?.message;
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"md"} open={isOpen} onClose={handleClose} scroll={"paper"}>
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <Email />
            <p>{t("send_email")}</p>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
            <Close />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          <form>
            <AutoCompleteForm
              limitTags={5}
              widthLabel={120}
              key={"contact_ids"}
              data={listId && listId.length > 0 ? listId : filterEmptyEmail}
              title={t("email_address")}
              control={control}
              name="contact_ids"
              errors={getErrorsValidate("contact_ids")}
              isMultiple
              isRequired
              fullWidth
              showAmountContact
              isDisabled={listId && listId.length > 0}
              isHideTag={listId && listId.length > 0}
            />
            {!isOnlineAdmission && (
              <AutoCompleteForm
                widthLabel={120}
                key={"contact_category_ids"}
                data={listCategories}
                title={t("select_list")}
                control={control}
                name="contact_category_ids"
                isMultiple
                fullWidth
              />
            )}
            {!isOnlineAdmission && (
              <AutoCompleteForm
                widthLabel={120}
                key={"contact_query_ids"}
                data={listQueries}
                title={t("select_query")}
                control={control}
                name="contact_query_ids"
                isMultiple
                fullWidth
              />
            )}
            <SelectForm
              errors={getErrorsValidate("email_template_id")}
              widthLabel={120}
              data={listEmailTemplates}
              title={t("select_email_template")}
              control={control}
              name="email_template_id"
              fullWidth
            />

            <InputTextForm
              isRequired
              fullWidth
              errors={getErrorsValidate("subject")}
              widthLabel={120}
              title={t("subject")}
              register={register}
              name="subject"
            />

            <CKEditorForm
              name={"content"}
              control={control}
              title={t("content")}
              widthLabel={120}
              errors={getErrorsValidate("content")}
              uploadImageHandler={async (file: File) => {
                return URL.createObjectURL(file);
              }}
              isRequired
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleClose} />
          <BaseButton
            title={t("send_email")}
            className={classes.btnSearch}
            isSubmit={false}
            onClick={handleSubmit(onSubmitSms)}
          />
        </DialogActions>
      </Dialog>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          reset();
          setIsOpenModalConfirmLeaveForm(false);
          setTimeout(() => {
            onClose();
          }, 100);
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </div>
  );
}
