import React, {useEffect, useState} from "react";
import {admissionImageTypes, imageTitles, reviewTitles} from "../Modal/ModalDetailCadidateContact/admissionData";
import { ReactComponent as BrightLightBulb } from "../../assets/images/bright_light_bulb.svg";
import { ReactComponent as LightBulb } from "../../assets/images/light_bulb.svg";
import {axiosClient} from "../../services/axiosClient";
import {LooseObject} from "../../models/common";
import {useStyles} from "../Modal/ModalDetailCadidateContact/styles";
import {useLocation} from "react-router-dom";
import {setReview, setUpdateStatus} from "../../store/reviewSlice";
import {useAppDispatch} from "../../redux/store";
import {API} from "../../utils/api";
import {LANGUAGESKILL} from "../../utils";

interface IProps {
	dataDetail: LooseObject;
  activeApplicationId?: number;
}
export default function CandidateInfoComponent({ dataDetail, activeApplicationId } : IProps) :React.JSX.Element {
	const [reviewData, setReviewData] = useState<any>();
	const [dataRender, setDataRender] = useState<any>();
	const location = useLocation();
	const [loading, setLoading] = useState<boolean>(false);
	const admissionMethodId = dataRender?.admission_method?.id;
	const {classes} = useStyles();
	const isOnlineAdmission = location.pathname === "/onlineAdmission";
	const dispatch = useAppDispatch();
	const getTextConduct = (value?: number): string => {
		const mapping: Record<number, string> = {
			1: "Tốt",
			2: "Khá",
			3: "Trung bình",
			4: "Yếu",
		};
		
		return mapping[value ?? 0] || "";
	};
	
	const getTextPerformance = (value?: number): string => {
		const mapping: Record<number, string> = {
			1: "Giỏi",
			2: "Khá",
			3: "Trung bình",
			4: "Yếu",
		};
		
		return mapping[value ?? 0] || "";
	};
	
	useEffect(() => {
		dispatch(setReview(reviewData));
	}, [reviewData, dispatch]);
		
		useEffect(() => {
			if (!reviewData) return;
			
			const allOnlineProfiles = Object.values(reviewData as Record<string, any>)
				.filter(item => typeof item === "object" && item !== null)
				.every(item => item.online_profile === 1);
			const allOfflineProfiles = Object.values(reviewData as Record<string, any>)
				.filter(item => typeof item === "object" && item !== null)
				.every(item => item.offline_profile === 1);
			if (allOfflineProfiles && reviewData.offline_profile_status !== 1) {
				setReviewData((prev: any) => ({
					...prev,
					offline_profile_status: 1,
				}));
			} else if (!allOfflineProfiles && reviewData.offline_profile_status === 1) {
				setReviewData((prev: any) => ({
					...prev,
					offline_profile_status: 0,
				}));
			}
			if (allOnlineProfiles && reviewData.online_profile_status !== 1) {
				setReviewData((prev: any) => ({
					...prev,
					online_profile_status: 1,
				}));
			} else if (!allOnlineProfiles && reviewData.online_profile_status === 1) {
				setReviewData((prev: any) => ({
					...prev,
					online_profile_status: 0,
				}));
			}
		}, [reviewData]);
		
		useEffect(() => {
			setLoading(true);
      axiosClient.get(API.getApplicationDetail, {
        params: {application_id: activeApplicationId ?? dataDetail.id},
      })
				.then((res) => {
					setDataRender(res.data);
					setReviewData(res.data.review);
					dispatch(setUpdateStatus(res.data.update_status));
					setTimeout(() => {
						setLoading(false);
					}, 500);
				})
				.catch((e) => {
					console.error(e);
					setLoading(false);
				});
		}, [dataDetail, activeApplicationId]);
	
	// @ts-ignore
	return (
		<div className="register-form position-relative" style={{margin: "auto"}}>
			<div>
				<table style={{textAlign: 'center', paddingTop: '30px', marginBottom: '30px', width: '100%'}}>
					<tbody>
					<tr>
						<td>ĐẠI HỌC QUỐC GIA HÀ NỘI</td>
						<td className={classes.textBold}>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</td>
					</tr>
					<tr>
						<td className={`${classes.textBold} ${classes.textUnderline}`}>TRƯỜNG ĐẠI HỌC VIỆT NHẬT</td>
						<td className={`${classes.textBold} ${classes.textUnderline}`}>Độc lập - Tự do - Hạnh phúc</td>
					</tr>
					</tbody>
				</table>
				
				<div style={{textAlign: "center", paddingBottom: 30}}>
					<div className={`${classes.textBold}`}>
						PHIẾU ĐĂNG KÝ XÉT TUYỂN ĐẠI HỌC CHÍNH QUY NĂM 2025
					</div>
					<div className={`${classes.textBold} ${classes.textItalic}`}>
						Kính gửi: Hội đồng Tuyển sinh Trường Đại học Việt Nhật – ĐHQGHN
					</div>
				</div>
				
				<table style={{width: "90%", margin: "auto"}}>
					<tbody>
					<tr>
						<td>
							<span className={`${classes.textBold}`}>MHS: {dataRender?.profile_code}</span>
						</td>
					</tr>
					</tbody>
				</table>
				
				<table style={{width: "86%", margin: "auto", lineHeight: "1.8em"}}>
					<tbody>
					<tr>
						<td style={{width: "4cm", height: "6cm"}}>
							<div className={classes.photoBox}>
								Ảnh 4cm x 6cm
							</div>
						</td>
						<td
							style={{
								textAlign: "left",
								verticalAlign: "baseline",
								paddingLeft: 10,
								position: "relative"
							}}
						>
							{
								dataRender?.score !== undefined && dataRender?.score !== null && (
									<div style={{
										position: "absolute",
										top: 0,
										right: 0,
										padding: "30px",
										border: "8px solid #f62300",
										borderRadius: "50%",
										transform: "rotate(-10deg)"
									}}>
										<span style={{color: "#f62300", fontWeight: 1000, fontSize: "28px"}}>{dataRender?.score}</span>
									</div>
								)
							}
							<div>Họ và tên: {dataRender?.candidate_contact?.full_name}</div>
							<div>
								Ngày tháng năm sinh: {dataRender?.candidate_contact?.date_of_birth
								? new Date(dataRender.candidate_contact.date_of_birth)
									.toLocaleDateString("vi-VN")
								: ""}
							</div>
							<div style={{verticalAlign: 'middle'}}>
								Giới tính:
								<label>
									&nbsp;Nam
									<input
										type="checkbox"
										checked={dataRender?.candidate_contact?.gender === 1}
										readOnly
									/>
								</label>
								<label>
									&nbsp;Nữ
									<input
										type="checkbox"
										checked={dataRender?.candidate_contact?.gender === 2}
										readOnly
									/>
								</label>
								<label>
									&nbsp;Khác
									<input
										type="checkbox"
										checked={dataRender?.candidate_contact?.gender === 3}
										readOnly
									/>
								</label>
							</div>
							<div>Số CMND/CCCD: {dataRender?.candidate_contact?.citizen_id}</div>
							<div>
								Địa chỉ liên lạc: {dataRender?.candidate_contact?.address}
							</div>
							<div>SĐT: {dataRender?.candidate_contact?.phone_number} Email: {dataRender?.candidate_contact?.email}</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							Tên và địa chỉ trường THPT: {dataRender?.school_address}
						</td>
					</tr>
					<tr>
						<td colSpan={2}>Chương trình đào tạo ứng tuyển: {dataRender?.aspiration?.name_vi}</td>
					</tr>
					<tr>
						<td colSpan={2}>Phương thức xét tuyển: {dataRender?.admission_method?.name}</td>
					</tr>
					{dataRender?.admission_method_id === 1 && (
						<tr>
							<td colSpan={2}>Đăng ký đánh giá năng lực ngoại ngữ: {
								LANGUAGESKILL.find(lang => lang.id === (dataRender?.language_assessment ?? dataRender?.candidate_contact?.language_assessment))
								? LANGUAGESKILL.find(lang => lang.id === (dataRender?.language_assessment ?? dataRender?.candidate_contact?.language_assessment))?.name
								: 'Tiếng Anh'}</td>
						</tr>
					)}
					<tr>
						<td colSpan={2} style={{textIndent: "1cm"}}>
							Tôi cam đoan những lời khai trong Phiếu đăng ký xét tuyển này là đúng sự thật. Nếu sai tôi sẽ hoàn
							toàn chịu
							trách nhiệm và chịu xử lý theo Quy chế tuyển sinh đại học hiện hành của ĐHQGHN và của Bộ Giáo dục và
							Đào tạo.
						</td>
					</tr>
					</tbody>
				</table>
				
				<table style={{width: "100%", margin: "auto", textAlign: "center"}}>
					<tbody>
					<tr>
						<td style={{width: "10cm"}}></td>
						<td>
							<div>
								Hà Nội, ngày {new Date(dataRender?.created_at).getDate()}
								&nbsp;tháng {new Date(dataRender?.created_at).getMonth() + 1}
								&nbsp;năm {new Date(dataRender?.created_at).getFullYear()}
							</div>
							<div className={`${classes.textBold}`}>Thí sinh</div>
							<div className={`${classes.textItalic}`}>(ký, ghi rõ họ tên)</div>
							<div style={{marginTop: "1cm"}}>{dataRender?.candidate_contact?.full_name}</div>
						</td>
					</tr>
					</tbody>
				</table>
				<table className={`${classes.tableBordered}`}
				       style={{margin: "auto", textAlign: "center", marginTop: "30px", width: '100%'}}>
					<tbody>
					<tr>
						<td colSpan={2} className={`${classes.tableBordered}`} style={{width: '70%'}}>
							<strong>QUÁ TRÌNH HỌC TẬP BẬC TRUNG HỌC PHỔ THÔNG/ ACADEMIC HISTORY</strong>
						</td>
					</tr>
					<tr style={{lineHeight: "1.5em"}}>
						<td colSpan={2} className={`${classes.tableBordered}`} style={{textAlign: "left"}}>
							<div style={{paddingLeft: "10px"}}>
								<div style={{display: 'flex'}}>
									{isOnlineAdmission && (
										<div>
											{dataRender?.update_status?.highschool_id === 1 ?
												(<BrightLightBulb style={{width: "35px", height: "40px"}}/>)
												: (<LightBulb style={{width: "35px", height: "40px"}}/>)}
										</div>
									)}
									<div>1. Tên trường/School name: {dataRender?.candidate_contact?.highschool_name}</div>
								</div>
								<div style={{display: 'flex'}}>
									{isOnlineAdmission && (
										<div>
											{dataRender?.update_status?.academic_results === 1 ?
												<BrightLightBulb style={{width: "35px", height: "40px"}}/>
												: <LightBulb style={{width: "35px", height: "40px"}}/>}
										</div>
									)}
									<div>2. Kết quả học tập THPT/High school academic results:</div>
								</div>
							</div>
							<table style={{
								width: "90%",
								margin: '20px auto',
								border: "1px solid black",
								borderCollapse: "collapse"
							}}>
								<tbody style={{textAlign: "center"}}>
								<tr>
									<td className={`${classes.tableBordered}`} colSpan={2}>Lớp</td>
									<td className={`${classes.tableBordered}`}>Hạnh kiểm/Academic conduct</td>
									<td className={`${classes.tableBordered}`}>Học lực/Academic performance</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`} rowSpan={2}>Lớp 10/Grade 10</td>
									<td className={`${classes.tableBordered}`}>Học kỳ 1/ 1st semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_10_1)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_10_1)}</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`}>Học kỳ 2/ 2nd semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_10_2)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_10_2)}</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`} rowSpan={2}>Lớp 11/Grade 11</td>
									<td className={`${classes.tableBordered}`}>Học kỳ 1/ 1st semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_11_1)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_11_1)}</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`}>Học kỳ 2/ 2nd semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_11_2)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_11_2)}</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`} rowSpan={2}>Lớp 12/Grade 12</td>
									<td className={`${classes.tableBordered}`}>Học kỳ 1/ 1st semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_12_1)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_12_1)}</td>
								</tr>
								<tr>
									<td className={`${classes.tableBordered}`}>Học kỳ 2/ 2nd semester</td>
									<td
										className={`${classes.tableBordered}`}>{getTextConduct(dataRender?.candidate_study_result?.academy_conduct_12_2)}</td>
									<td
										className={`${classes.tableBordered}`}>{getTextPerformance(dataRender?.candidate_study_result?.academy_performance_12_2)}</td>
								</tr>
								</tbody>
							</table>
						</td>
						<td colSpan={2} style={{
							padding: '10px',
							verticalAlign: 'top',
							display: isOnlineAdmission ? 'table-cell' : 'none'
						}}>
						
						</td>
					</tr>
					<tr>
						<td colSpan={2} className={`${classes.tableBordered}`}>
							<strong>NỘI DUNG DỰ TUYỂN/ APPLICATION INFORMATION</strong>
						</td>
					</tr>
					<tr>
						<td colSpan={2} className={`${classes.tableBordered}`} style={{textAlign: "left"}}>
							<div style={{padding: '5px 25px'}}>3. Ngành học/ Subject: {dataRender?.aspiration?.name}</div>
							<div style={{padding: '5px 25px'}}>4. Phương thức ứng tuyển/ Admission
								method: {dataRender?.admission_method?.name}</div>
						</td>
					</tr>
					{admissionImageTypes[admissionMethodId]?.map((imageType, index) => (
						<tr key={imageType}>
							<td className={`${classes.tableBordered}`}>
								<div>
									<div style={{textAlign: 'left', display: 'flex'}}>
										{isOnlineAdmission && (
											<div>
												{dataRender?.update_status?.[imageType] === 1 ?
													<BrightLightBulb style={{width: "35px", height: "40px"}}/>
													: <LightBulb style={{width: "35px", height: "40px"}}/>}
											</div>
										)}
										<div style={{padding: '5px', margin: '0px'}}>
											<strong>{index + 12}. {imageTitles[imageType] || imageType}:</strong>
										</div>
									</div>
									{dataRender?.image_path?.[imageType]?.map((imagePath: { url: string }, index: number) => (
										<div key={`${imageType}_${index}`}
										     style={{textAlign: 'center'}}>
											<img
												src={imagePath.url}
												alt={`${imageType}_${index + 1}`}
												style={{maxWidth: '500px', display: 'block', marginTop: '5px', padding: '10px'}}
											/>
										</div>
									))}
								</div>
							</td>
							{reviewData?.[imageType] ? (
								<td colSpan={2} style={{
									padding: '10px',
									verticalAlign: 'top',
									display: isOnlineAdmission ? 'table-cell' : 'none'
								}}>
									<table className={classes.tableCustom}>
										<thead>
										<tr>
											<th colSpan={2}
											    style={{border: '1px solid black', padding: '8px', backgroundColor: '#f0f0f0'}}>
												{reviewTitles[imageType] || "Tình trạng hồ sơ"}
											</th>
										</tr>
										<tr>
											<td
												style={{
													border: '1px solid black',
													padding: '8px',
													textAlign: 'center',
													fontWeight: 'bold'
												}}>HS Online
											</td>
											<td
												style={{
													border: '1px solid black',
													padding: '8px',
													textAlign: 'center',
													fontWeight: 'bold'
												}}>HS Offline
											</td>
										</tr>
										</thead>
										<tbody>
										<tr>
											<td style={{border: '1px solid black', textAlign: 'center'}}>
												<input
													type="checkbox"
													checked={reviewData?.[imageType]?.online_profile === 1}
													onChange={(e) =>
														setReviewData((prev: { [x: string]: any; }) => ({
															...prev,
															[imageType]: {
																...prev[imageType],
																online_profile: e.target.checked ? 1 : 0,
															},
														}))
													}
												/> Đầy đủ
											</td>
											<td style={{border: '1px solid black', textAlign: 'center'}}>
												<input
													type="checkbox"
													checked={reviewData?.[imageType]?.offline_profile === 1}
													onChange={(e) =>
														setReviewData((prev: { [x: string]: any; }) => ({
															...prev,
															[imageType]: {
																...prev[imageType],
																offline_profile: e.target.checked ? 1 : 0,
															},
														}))
													}
												/> Đầy đủ
											</td>
										</tr>
										<tr>
											<td colSpan={2} className={classes.tableCellCustom}>
												<textarea
													className={classes.textCustom}
													value={reviewData?.[imageType]?.note || ""}
													onChange={(e) =>
														setReviewData((prev: { [x: string]: any; }) => ({
															...prev,
															[imageType]: {
																...prev[imageType],
																note: e.target.value,
															},
														}))
													}
													placeholder="Nhập ghi chú"
												/>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							) : dataRender?.update_status?.[imageType] === 1 ? (
								<td colSpan={2} style={{
									padding: '10px',
									verticalAlign: 'top',
									textAlign: 'center',
									fontWeight: 'bold',
									fontStyle: 'italic',
								}}>
									Mục này đã được xoá.
								</td>
							) : null}
						</tr>
					))}
					
					<tr>
						<td className={`${classes.tableBordered}`} colSpan={2}>
							<div>
								<strong>
									CAM KẾT CỦA ỨNG VIÊN/ CANDIDATE OATH
								</strong>
							</div>
						</td>
					</tr>
					<tr>
						<td className={`${classes.tableBordered}`} style={{textAlign: "left", padding: '10px'}} colSpan={2}>
							<p>
								Tôi cam đoan rằng toàn bộ thông tin trong bản đăng ký này và thông tin đính kèm là hoàn toàn chính
								xác và đầy đủ, và có thể được kiểm chứng bằng văn bản gốc.
							</p>
							<p>
								<strong className={classes.textUnderline}>LƯU Ý</strong>: Xin lưu ý rằng VJU hoàn toàn có quyền
								từ chối không cho sinh viên nhập học hoặc chấm dứt việc học tập của sinh viên nếu phát hiện
								anh/chị cung cấp thông tin không chính xác hoặc thiếu sót.
							</p>
							<p>
								Nếu được trúng tuyển, thí sinh sẽ bắt buộc phải cung cấp đầy đủ các hồ sơ yêu cầu
							</p>
							<p>
								I certify that all information included with the application for admission is accurate and
								complete, and may be verified with an original document/ official source.
							</p>
							<p>
								<strong className={classes.textUnderline}>NOTE</strong>: Please note that VJU reserves the right
								to refuse admission or to terminate a student's attendance should it be discovered that he/she has
								made a false statement or has omitted significant information.
							</p>
							<p>
								If you are selected, you will be required to provide evidence proof of your qualifications.
							</p>
						</td>
					</tr>
					</tbody>
				</table>
			
			</div>
		</div>
	)
}
