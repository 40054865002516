import React, {useEffect, useRef, useState} from "react";
import {Box, Paper, TextField, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useStyles} from "./styles";
import FooterRegister from "../LandingPageAdmissionRegister/FooterRegister";
import {Link} from "react-router-dom";
import logo from "../../../assets/images/logo-school.svg";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import {Controller, useForm} from "react-hook-form";
import {axiosClient} from "../../../services/axiosClient";
import {API} from "../../../utils/api";
import Loading from "../../../components/Loading/Loading";
import BaseButton from "../../../components/Buttons/BaseButton";

const generateCaptcha = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 5; i++) {
        captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return captcha;
};

const drawCaptcha = (canvasRef: any, captcha: string) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#f0f0f0";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    for (let i = 0; i < 100; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const size = Math.random() * 3;
        const colors = ["black", "gray", "white"];
        ctx.fillStyle = colors[Math.floor(Math.random() * colors.length)];
        ctx.beginPath();
        ctx.arc(x, y, size, 0, 2 * Math.PI);
        ctx.fill();
    }
    
    ctx.font = "30px Arial";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    
    const letterSpacing = 30;
    const startX = (canvas.width - captcha.length * letterSpacing) / 2;
    const centerY = canvas.height / 2;
    
    for (let i = 0; i < captcha.length; i++) {
        const char = captcha[i];
        const angle = (Math.random() - 0.5) * 0.6;
        
        ctx.save();
        ctx.translate(startX + i * letterSpacing, centerY);
        ctx.rotate(angle);
        ctx.fillText(char, 0, 0);
        ctx.restore();
    }
};

export default function ScoreLookup() {
    const {
        control,
        handleSubmit,
        setError,
        formState: {errors},
        reset
    } = useForm();
    const {t} = useTranslation();
    const {classes} = useStyles();
    const [captcha, setCaptcha] = useState("");
    const canvasRef = useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        if (data.captcha !== captcha) {
            setError("captcha", {type: "manual", message: "Vui lòng nhập đúng mã capcha"});
            refreshCaptcha();
            return;
        }

        setLoading(true);

        axiosClient
            .get(API.getScore, {
                params: {profile_code: data.profileCode}
            })
            .then((res) => {
                if (res.data.dataScore === null) {
                    setError("profileCode", {type: "manual", message: "Vui lòng nhập đúng mã hồ sơ"});
                    refreshCaptcha();
                } else {
                    setData(res.data);
                    setIsSubmitted(true);
                }
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const refreshCaptcha = () => {
        const newCaptcha = generateCaptcha();
        setCaptcha(newCaptcha);
        drawCaptcha(canvasRef, newCaptcha);
    };
    
    useEffect(() => {
        if (!isSubmitted) {
            const newCaptcha = generateCaptcha();
            setCaptcha(newCaptcha);
            drawCaptcha(canvasRef, newCaptcha);
        }
    }, [isSubmitted]);
    
    const onClickBack = () => {
        setIsSubmitted(false);
        setData(null);
        reset();
    };
    
    return (
        <div
            className={classes.container}
            style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            {loading && <Loading />}
            <Helmet>
                <title>TRA CỨU ĐIỂM XÉT TUYỂN</title>
            </Helmet>

            <div className={classes.header}>
                <div className={classes.wrapHeader}>
                    <Link to="https://vju.ac.vn" target="blank">
                        <img src={logo} alt="logo" className={classes.logo}/>
                    </Link>
                </div>
            </div>

            <div
                className={classes.content}
                style={{
                    marginTop: "70px",
                }}
            >
                <Paper elevation={3} sx={{
                    maxWidth: {xs: "95%", sm: 600, md: 750},
                    margin: "auto",
                    p: {xs: 4, sm: 6, md: 10},
                    borderRadius: 2,
                    textAlign: "center",
                    position: "relative",
                }}>
                    <Typography sx={{fontWeight: 700, fontSize: 32}}>
                        TRA CỨU ĐIỂM XÉT TUYỂN
                    </Typography>
                    {!isSubmitted ? (
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 2}}>
                            <Typography sx={{fontWeight: 500, fontSize: 20, textAlign: "left"}}>
                                Mã hồ sơ
                            </Typography>
                            <Box sx={{display: "flex", justifyContent: "flex-start"}}>
                                <Controller
                                    name="profileCode"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Vui lòng nhập mã hồ sơ",
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            sx={{width: {xs: "100%", sm: 400, md: 500}}}
                                            placeholder="Nhập mã hồ sơ"
                                            error={!!errors.profileCode}
                                            helperText={typeof errors.profileCode?.message === "string" ? errors.profileCode.message : ""}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{mb: 2, mt: 2, textAlign: "left"}}>
                                <Typography sx={{fontWeight: 500, fontSize: 20, textAlign: "left"}}>
                                    Captcha
                                </Typography>
                                <Box sx={{
                                    width: {xs: "100%", sm: 700},
                                    height: "auto",
                                    mb: 2,
                                    textAlign: "left",
                                    display: "flex",
                                    border: "1px solid #ccc",
                                    flexDirection: {xs: "column", sm: "row"},
                                    p: {xs: 0, sm: 3},
                                }}>
                                        <canvas ref={canvasRef} width="170" height="50" style={{
                                            border: "1px solid #ccc",
                                            maxWidth: "100%",
                                        }}></canvas>
                                        <CachedIcon fontSize="large" color="primary" sx={{pt: 3, pl: 2, pr: 2}}
                                                    onClick={refreshCaptcha}/>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                            minHeight: 70,
                                            pt: 2
                                        }}>
                                            <Controller
                                                name="captcha"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: "Vui lòng nhập mã captcha"}}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        placeholder="Nhập chuỗi mã"
                                                        variant="outlined"
                                                        sx={{
                                                            width: {xs: "100%", sm: 350},
                                                            minHeight: 56,
                                                        }}
                                                        error={!!errors.captcha}
                                                        helperText={typeof errors.captcha?.message === "string" ? errors.captcha.message : " "}
                                                    />
                                                )}
                                            />
                                    </Box>
                                </Box>
                            </Box>
                            <Button type="submit" variant="contained"
                                    sx={{
                                        backgroundColor: "#4A4358",
                                        fontSize: 20,
                                        width: {xs: "100%", sm: 350}
                                    }}>
                                Kết quả
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", mt: 4}}>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontWeight: 700, fontSize: { xs: 20, sm: 24, md: 28 } }}>
                                    Điểm của bạn:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 800,
                                        fontSize: { xs: 50, sm: 70, md: 100 },
                                        color: "#FF0000",
                                    }}
                                >
                                    {data?.dataScore?.score ?? "Chưa có điểm"}
                                </Typography>
                            </Box>
                            <Box sx={{
                                textAlign: "left",
                                mt: 4,
                                width: "100%",
                                maxWidth: 600,
                                backgroundColor: "#f0f0f0",
                                p: {xs: 2, sm: 3, md: 4},
                                borderRadius: 2,
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                            }}>
                                <Typography sx={{fontWeight: 700, fontSize: 20}}>Kết quả tìm kiếm</Typography>
                                <Typography sx={{fontWeight: 400, fontSize: 18, pl: 2, pt: 2}}>
                                    Họ và tên: {data?.dataScore?.name ?? "Không có dữ liệu"}
                                </Typography>
                                <Typography className={classes.text}>
                                    Căn cước: {data?.dataScore?.citizen_id ?? "Không có dữ liệu"}
                                </Typography>
                                <Typography className={classes.text}>
                                    Mã hồ sơ: {data?.profile_code ?? "Không có dữ liệu"}
                                </Typography>
                                <Typography className={classes.text}>
                                    Phương thức xét
                                    tuyển: {data?.dataScore?.admission_method_name ?? "Không có dữ liệu"}
                                </Typography>
                                <Typography className={classes.text}>
                                    Chương trình đào tạo: {data?.dataScore?.aspiration_name ?? "Không có dữ liệu"}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {isSubmitted && (
                      <Box sx={{ width: "100%", textAlign: "left", mt: 2 }}>
                          <BaseButton
                            title={t("back")}
                            className={classes.btnCancel}
                            onClick={onClickBack}
                            sx={{
                                position: "absolute",
                                bottom: 16,
                                left: 16,
                            }}
                          />
                      </Box>
                    )}
                </Paper>
            </div>

            <FooterRegister/>
        </div>
    );

}
