import {
  CollectionsBookmarkOutlined,
  DeleteForeverOutlined,
  FileUploadOutlined,
  PermContactCalendarOutlined,
  PersonOffOutlined,
  PersonSearchOutlined,
  RecentActorsOutlined,
  SaveAltOutlined,
} from "@mui/icons-material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Grid, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import DropdownQuery from "components/DropdownQuery";
import ModalChangeColumns from "components/Modal/ModalChangeColumn";
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalFilter from "components/Modal/ModalFilter";
import ModalMassUpdate from "components/Modal/ModalMassUpdate";
import ModalSendEmail from "components/Modal/ModalSendEmail";
import ModalSendSms from "components/Modal/ModalSendSms";
import ModalTask from "components/Modal/ModalTask";
import RoleBaseComponent from "components/RoleBaseComponent";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hasPermissions } from "redux/auth/auth.service";
import { saveQuery } from "store/querySlice";
import {
  createTask,
  getTaskResults,
  getTaskTypes,
  setContactsForTask,
} from "store/taskSlice";
import {
  ACTION_STUDENT_CONTACT,
  ACTION_STUDENT_CONTACT_TRASH,
  FILTER_STUDENT_CONTACT_FIELD,
  LIST_STUDENT_CONTACT_COLUMN,
  STUDENT_CONTACT_TYPE,
} from "utils/constant";
import BaseButton from "../../../../../components/Buttons/BaseButton";
import LinkButton from "../../../../../components/Buttons/LinkButton";
import Dropdown from "../../../../../components/Dropdown";
import FilterTag from "../../../../../components/FilterTag";
import HeaderPage from "../../../../../components/HeaderPage";
import Loading from "../../../../../components/Loading/Loading";
import Select from "../../../../../components/Select";
import SearchBar from "../../../../../components/inputSearch";
import {
  LooseObject,
  SelectDataProp,
  optionsStudentContact,
} from "../../../../../models/common";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import {
  deleteFieldSearch,
  deleteQueryStudent,
  deleteStudentContact,
  deleteStudentContactCompletely,
  exportStudentContact,
  fetchAllFilterStudentContacts,
  fetchStudentContacts,
  getListQueryStudent,
  getOptionsListForStudent,
  getOptionsStudentContact,
  getQueryStudent,
  getStates,
  getStudentColumns,
  getStudentContactForOption,
  initialOptions,
  initialSearchFilterStudent,
  massUpdateContact,
  restoreStudent,
  saveStudentColumns,
  selectedTabStudentContactSelector,
  setPageSize,
  setSelectedTab,
  updateFilterOptions,
  updateListFilter,
  updateOptionListContact,
  updateOptions,
  updateSearch,
  updateSearchOption,
} from "../../../../../store/studentContactSlice";
import { formatSearchContent, sortTable } from "../../../../../utils/helper";
import Table from "./../../../../../components/Table/index";
import BasicDateRange from "components/dropdownDateRangePicker";
import { useStyles } from "./styles";
import _ from "lodash";
import { showColumnAvailable } from "utils";

export default function StudentContacts() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [data, setData] = useState<LooseObject[]>([]);
  const states = useSelector((state: RootState) => state.studentcontacts.state);
  const contacts = useSelector(
    (state: RootState) => state.studentcontacts.contacts
  );
  const page = useSelector((state: RootState) => state.studentcontacts.page);
  const total = useSelector((state: RootState) => state.studentcontacts.total);
  const pageSize = useSelector(
    (state: RootState) => state.studentcontacts.limit
  );
  const searchOption = useSelector(
    (state: RootState) => state.studentcontacts.searchOption
  );
  const selectedTab = useSelector(selectedTabStudentContactSelector);
  const allOptions = useSelector(
    (state: RootState) => state.studentcontacts.options
  );
  const [options, setOptions] = useState<optionsStudentContact>(initialOptions);
  const [limit, setLimit] = useState<number>(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [checkedList, setCheckedList] = useState<number[] | []>([]);
  const [isShowAction, setIsShowAction] = useState<boolean>(false);
  const [totalContact, setTotalContact] = useState<number>(0);
  const [isShowClearAll, setIsShowClearAll] = useState<boolean>(false);
  const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
  const listQueries = useSelector(
    (state: RootState) => state.studentcontacts.listQueries
  );
  const columns = useSelector(
    (state: RootState) => state.studentcontacts.columns
  );
  const [numberAdvancedSearch, setNumberAdvancedSearch] = useState<number>(0);
  const [isOpenModalChangeColumns, setIsOpenModalChangeColumns] =
    useState<boolean>(false);
  const [columnList, setColumnList] = useState<LooseObject[]>(
    LIST_STUDENT_CONTACT_COLUMN
  );
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] =
    useState<boolean>(false);
  const [isOpenModalConfirmExport, setIsOpenModalConfirmExport] =
    useState<boolean>(false);
  const isHasOption = useSelector(
    (state: RootState) => state.studentcontacts.isHasOption
  );
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [action, setAction] = useState<string[]>(ACTION_STUDENT_CONTACT);
  const [isOpenModalConfirmRestore, setIsOpenModalConfirmRestore] =
    useState<boolean>(false);
  const [listRestore, setListRestore] = useState<number[]>([]);
  const [
    isOpenModalConfirmDeleteCompletely,
    setIsOpenModalConfirmDeleteCompletely,
  ] = useState<boolean>(false);
  const [listDelete, setListDelete] = useState<number[]>([]);
  const hasViewGlobalPermission = useState(
    hasPermissions(["student_contact_view_global"])
  )[0];
  const [isSearchListContact, setIsSearchListContact] =
    useState<boolean>(false);
  const [isOpenModalMassUpdate, setIsOpenModalMassUpdate] =
    useState<boolean>(false);
  const [isSearchContact, setIsSearchContact] = useState<boolean>(false);
  const [isOpenModalAddTask, setIsOpenModalAddTask] = useState<boolean>(false);
  const [listContactUpdate, setListContactUpdate] = useState<LooseObject[]>([]);
  const [searchText, setSearchText] = useState<string>(searchOption?.text);
  const [openModalSendSms, setOpenModalSendSms] = useState(false);
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const [listSendSms, setListSendSms] = useState<SelectDataProp[]>([]);
  const [listSendEmail, setListSendEmail] = useState<SelectDataProp[]>([]);
  const listFilter = useSelector(
    (state: RootState) => state.studentcontacts.listFilter
  );
  const [isSelectAllPage, setIsSelectAllPage] = useState<boolean>(false);

  const isMobileScreenSmallSize = useMediaQuery(
    theme.breakpoints.between("xs", "sm")
  );
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));

  useEffect(() => {
    if (!hasViewGlobalPermission && selectedTab === 0) {
      dispatch(setSelectedTab(1));
    }
  }, [selectedTab, hasViewGlobalPermission, dispatch]);

  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getStates());
    if (!isHasOption) {
      await dispatch(getOptionsStudentContact());
    }
    await dispatch(getListQueryStudent());
    await dispatch(getStudentColumns());
    dispatch(getOptionsListForStudent());
    setIsFirstLoad(false);
    setLoading(false);
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCheckedList(listFilter);
  }, [listFilter]);

  useEffect(() => {
    if (!checkedList || checkedList.length === 0) {
      setIsSelectAllPage(false);
    }
  }, [checkedList]);

  useEffect(() => {
    const obj = getValueFromFilterSearch(searchOption);
    delete obj["aspirations"];
    delete obj["text"];

    setNumberAdvancedSearch(Object.keys(obj).length);
  }, [searchOption]);

  useEffect(() => {
    if (allOptions) {
      setOptions(allOptions);
    }
  }, [allOptions]);

  useEffect(() => {
    if (states) {
      const sum = states.reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + currentValue.contacts_count,
        0
      );
      setTotalContact(sum);
    }
  }, [states]);

  useEffect(() => {
    if (selectedTab === 3) {
      setAction(ACTION_STUDENT_CONTACT_TRASH);
    }
  }, [selectedTab]);

  const fetchData = async () => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    await dispatch(fetchStudentContacts(page));
    setIsShowAction(false);
    if (!isFirstLoad) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCheckedList([]);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, pageSize, selectedTab, searchOption]);

  useEffect(() => {
    setData(contacts);
    if (!isSelectAllPage) {
      setCheckedList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (Object.keys(columns).length !== 0) {
      const dataColumn = showColumnAvailable(
        columns,
        LIST_STUDENT_CONTACT_COLUMN,
        selectedTab
      );
      setColumnList(dataColumn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    let check: boolean = false;
    if (searchOption) {
      Object.keys(searchOption).map((item) => {
        if (searchOption[item].length !== 0) {
          check = true;
          return null;
        }
        return null;
      });
    }
    setIsShowClearAll(check);
  }, [searchOption]);

  useEffect(() => {
    if (isMobileScreenSmallSize) {
      const columnInMobileScreen = columnList.filter((column) =>
        ["full_name", "email", "phone_number", "action"].includes(column.title)
      );

      setColumnList(columnInMobileScreen);
    } else {
      if (Object.keys(columns).length !== 0) {
        const dataColumn = showColumnAvailable(
          columns,
          LIST_STUDENT_CONTACT_COLUMN,
          selectedTab
        );
        setColumnList(dataColumn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, isMobileScreenSmallSize]);

  const handleClickMenuItem = (row: LooseObject | string, field: string) => {
    dispatch(updateSearchOption(row, field));
  };

  const handleDeleteFieldSearch = (field: string) => {
    if (field === "text") {
      setSearchText("");
    }
    dispatch(deleteFieldSearch(field));
  };

  const handleSearchContact = () => {
    if (searchText === searchOption?.text) {
      return;
    }
    dispatch(updateSearchOption(searchText, "text"));
  };

  const handlePageClick = (page: number = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    if (!isSelectAllPage) {
      setCheckedList([]);
      setIsShowAction(false);
    }
    dispatch(fetchStudentContacts(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = useCallback((checkbox: number[]) => {
    setCheckedList(checkbox);
    if (checkbox.length > 0) {
      setIsShowAction(true);
    } else {
      setIsShowAction(false);
    }
  }, []);

  const handleClickAction = useCallback(
    async (action: string) => {
      if (action === "delete") {
        setIsOpenModalConfirmDelete(true);
      }
      if (action === "export") {
        setIsOpenModalConfirmExport(true);
      }
      if (action === "restore") {
        setListRestore(checkedList);
        setIsOpenModalConfirmRestore(true);
      }
      if (action === "delete_completely") {
        setListDelete(checkedList);
        setIsOpenModalConfirmDeleteCompletely(true);
      }
      if (action === "send_email") {
        setLoading(true);
        if (!isSelectAllPage) {
          let list: SelectDataProp[] = [];
          checkedList.forEach((item: number) => {
            const index = contacts.findIndex(
              (element: SelectDataProp) => element?.id === item
            );
            if (index !== -1) {
              list.push({
                id: contacts[index]?.id,
                name: contacts[index]?.email,
              });
            }
          });
          setListSendEmail(list);
        }
        setOpenModalSendEmail(true);
        setLoading(false);
      }
      if (action === "send_sms") {
        setLoading(true);
        if (!isSelectAllPage) {
          let list: SelectDataProp[] = [];
          checkedList.forEach((item: number) => {
            const index = contacts.findIndex(
              (element: SelectDataProp) => element?.id === item
            );
            if (index !== -1) {
              list.push({
                id: contacts[index]?.id,
                name: contacts[index]?.phone_number,
              });
            }
          });
          setListSendSms(list);
        }
        setOpenModalSendSms(true);
        setLoading(false);
      }
      if (action === "mass_update") {
        setLoading(true);
        if (!isSelectAllPage) {
          let list: LooseObject[] = [];
          checkedList.forEach((item: number) => {
            const index = contacts.findIndex(
              (element: LooseObject) => element?.id === item
            );
            if (index !== -1) {
              list.push({
                id: contacts[index]?.id,
                label: contacts[index]?.full_name,
              });
            }
          });
          setListContactUpdate(list);
        }
        setIsOpenModalMassUpdate(true);
        setLoading(false);
      }
      if (action === "add_task") {
        setLoading(true);
        if (!isSelectAllPage) {
          let list: LooseObject[] = [];
          checkedList.forEach((item: number) => {
            const index = contacts.findIndex(
              (element: LooseObject) => element?.id === item
            );
            if (index !== -1) {
              list.push({
                id: contacts[index]?.id,
                label: contacts[index]?.full_name,
              });
            }
          });
          setListContactUpdate(list);
        }
        handleOpenModalAddTask();
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedList, contacts]
  );

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(
    async (page: number) => {
      handlePageClick(page);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelectAllPage]
  );

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setPageSize(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelectAll = useCallback(() => {
    if (isSelectAllPage) {
      dispatch(updateListFilter([]));
      setIsShowAction(false);
      setIsSelectAllPage(false);
    } else {
      dispatch(fetchAllFilterStudentContacts());
      setIsShowAction(true);
      setIsSelectAllPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllPage]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
    const newColumn = [...columnList];
    const index: number = newColumn.findIndex(
      (element: LooseObject) => element.title === "full_name"
    );
    let action: LooseObject = _.cloneDeep(
      LIST_STUDENT_CONTACT_COLUMN[LIST_STUDENT_CONTACT_COLUMN.length - 1]
    );
    if (newValue === 3) {
      action = { ...action, show: ["restore", "bin"] };
      setAction(ACTION_STUDENT_CONTACT_TRASH);
      if (index !== -1) {
        newColumn[index].type = "string";
      }
    } else if (columnList[columnList.length - 1]?.show.includes("bin")) {
      setAction(ACTION_STUDENT_CONTACT);
      if (index !== -1) {
        newColumn[index].type = "link";
      }
    }
    newColumn.pop();
    newColumn.push(action);
    setColumnList(newColumn);
  };

  const handleChangeFilterTag = (item: LooseObject, isReset?: boolean) => {
    let newOption: LooseObject = JSON.parse(JSON.stringify(searchOption));
    if (isReset) {
      newOption.states = [];
      dispatch(updateSearch(newOption));
    } else {
      const indexOption = searchOption.states.findIndex(
        (element: LooseObject) => element.id === item.id
      );
      if (indexOption !== -1) {
        newOption.states.splice(indexOption, 1);
      } else {
        newOption.states.push(item);
      }
      dispatch(updateSearch(newOption));
    }
  };

  const handleOpenModalFilter = async () => {
    setIsOpenModalFilter(true);
  };

  const getValueFromFilterSearch = (data: LooseObject) => {
    let obj: LooseObject = {};
    Object.entries(data).forEach(([key, value]) => {
      if (key === "filter_date_of_acceptance" && value !== "") {
        obj[key] = moment(value).format("YYYY-MM-DD");
      } else if (
        (value && typeof value === "string") ||
        (Array.isArray(value) && value.length !== 0)
      ) {
        obj[key] = value;
      } else if (
        typeof value === "object" &&
        Object.keys(value).length !== 0 &&
        value.id
      ) {
        obj[key] = { id: value.id, name: value.name };
      } else if (
        value &&
        (key === "filter_emailed_from" || key === "filter_emailed_to" || key === "filter_acceptance_from" || key === "filter_acceptance_to")
      ) {
        obj[key] = moment(value).format("YYYY-MM-DD HH:mm:ss");
      } else if (
        value &&
        (key === "contact_without_email_only" ||
          key === "contact_with_email_only")
      ) {
        obj[key] = "true";
      } else if (
        value &&
        (key === "year_of_concern" || key === "year_of_concern")
      ) {
        obj[key] = value.getFullYear().toString();
      }
    });
    return obj;
  };

  const handleSearchFilter = (
    data: LooseObject,
    option: optionsStudentContact
  ) => {
    let obj: LooseObject = getValueFromFilterSearch(data);
    dispatch(updateOptions(option));
    dispatch(updateFilterOptions(obj));
    setIsOpenModalFilter(false);
  };

  const handleClearAllTagSearch = () => {
    dispatch(updateSearch(initialSearchFilterStudent));
    let option = JSON.parse(JSON.stringify(options));
    option.city = [];
    option.district = [];
    dispatch(updateOptions(option));
    setSearchText("");
  };

  const handleSaveQuery = async (data: any) => {
    setLoading(true);
    let obj: LooseObject = getValueFromFilterSearch(data);
    const name: string = data.name;
    delete obj.name;
    if (Object.keys(obj).length === 0) {
      toast.warning<void>(t("please_fill_in_the_data_in_the_form"));
      setLoading(false);
      return;
    }
    const query = {
      name: name,
      params: { ...obj },
      type: 1,
    };
    const check = await dispatch(saveQuery(query));
    if (check) {
      toast.success<void>(t("filter_created_successfully"));
      dispatch(getListQueryStudent());
      setIsOpenModalFilter(false);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleGetQuery = (id: number) => {
    dispatch(getQueryStudent(id));
  };

  const handleDeleteQuery = async (id: number) => {
    const check = await dispatch(deleteQueryStudent(id));
    if (check) {
      toast.success<void>(t("filter_removed_successfully"));
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
  };

  const handleOpenModalChooseColumns = async () => {
    setLoading(true);
    if (!columns.displayed) {
      await dispatch(getStudentColumns());
    }
    setIsOpenModalChangeColumns(true);
    setLoading(false);
  };

  const handleChangeColumn = async (data: LooseObject[]) => {
    setLoading(true);
    if (
      "displayed" in data &&
      Array.isArray(data?.displayed) &&
      data?.displayed?.length === 0
    ) {
      toast.warning<void>(
        t("it_must_be_at_least_1_field_in_the_displayed_column")
      );
    } else {
      const check = await dispatch(saveStudentColumns(data));
      if (check) {
        toast.success<void>(t("change_column_successfully"));
        setIsOpenModalChangeColumns(false);
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    }
    setLoading(false);
  };

  const handleDeleteContact = async () => {
    setLoading(true);
    try {
      const result = await dispatch(deleteStudentContact(checkedList));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("delete_student_contact_successful"));
        setIsOpenModalConfirmDelete(false);
        setCheckedList([]);
        fetchData();
        dispatch(getStates());
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } catch (error) {
      setLoading(false);
      setIsOpenModalConfirmDelete(false);
    }
    setLoading(false);
  };

  const handleDeleteContactCompletely = async () => {
    setLoading(true);
    try {
      const result = await dispatch(deleteStudentContactCompletely(listDelete));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("delete_student_contact_successful"));
        setIsOpenModalConfirmDeleteCompletely(false);
        setListDelete([]);
        fetchData();
        dispatch(getStates());
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } catch (error) {
      setLoading(false);
      setIsOpenModalConfirmDeleteCompletely(false);
      setListDelete([]);
    }
    setLoading(false);
  };

  const handleExportContact = async () => {
    setLoading(true);
    let response: any;
    if (checkedList.length !== 0) {
      response = await dispatch(exportStudentContact(0, checkedList));
    } else {
      response = await dispatch(exportStudentContact(1));
    }
    if (response) {
      try {
        const href = window.URL.createObjectURL(response);
        const fileName = "contacts-" + moment().format("DD-MM-YYYY");
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        toast.success<void>(t("export_contact_successful"));
      } catch (error) {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }

    setLoading(false);
    setIsOpenModalConfirmExport(false);
  };

  const handleRestoreStudent = async () => {
    setLoading(true);
    const result = await dispatch(restoreStudent(listRestore));
    if (result) {
      toast.success<void>(t("restore_contact_successful"));
      fetchData();
      setIsOpenModalConfirmRestore(false);
      setListRestore([]);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleOpenModalConfirmRestore = useCallback(async (data: number[]) => {
    setListRestore(data);
    setIsOpenModalConfirmRestore(true);
  }, []);

  const handleOpenModalConfirmDeleteContact = useCallback(
    async (data: number[]) => {
      setListDelete(data);
      setIsOpenModalConfirmDeleteCompletely(true);
    },
    []
  );

  const handleSearchList = async (text: string) => {
    setIsSearchListContact(true);
    await dispatch(updateOptionListContact(text));
    setIsSearchListContact(false);
  };

  const handleChangeListContact = (data: LooseObject) => {
    dispatch(updateSearchOption(data ?? "", "filter_contact_list_id"));
  };

  const handleMassUpdate = async (data: LooseObject) => {
    setLoading(true);
    const result = await dispatch(massUpdateContact(data));
    if (result) {
      toast.success<void>(t("mass_update_contact_successful"));
      setIsOpenModalMassUpdate(false);
      setListContactUpdate([]);
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleSearchContactToAddTask = async (text: string) => {
    setIsSearchContact(true);
    const newContacts = await dispatch(getStudentContactForOption(text));
    if (newContacts) {
      let listContact: LooseObject[] = [];
      newContacts.forEach((item: LooseObject) => {
        listContact.push({ id: item?.id, label: item?.full_name });
      });
      dispatch(setContactsForTask(listContact));
    }
    setIsSearchContact(false);
  };

  const handleOpenModalAddTask = async () => {
    setLoading(true);
    await dispatch(getTaskTypes());
    await dispatch(getTaskResults());
    setIsOpenModalAddTask(true);
    setLoading(false);
  };

  const handleCloseModalSendSms = () => {
    setOpenModalSendSms(false);
  };
  const handleCloseModalSendEmail = () => {
    setOpenModalSendEmail(false);
  };

  const htmlContentRemove = (checkArray: [] | number[], titleNotify: any) => {
    return (
      <p>
        {titleNotify}{" "}
        <span className={classes.nameDelete}>
          {`${checkArray.length > 1 ? t("these_contacts") : t("this_contact")
            }?`}
        </span>
      </p>
    );
  };

  const handleAddTask = async (data: LooseObject) => {
    setLoading(true);
    let newData = { ...data };
    newData.contact_type = STUDENT_CONTACT_TYPE;
    const response = await dispatch(createTask(newData));
    if (response) {
      toast.success<void>(t("create_task_successful"));
      setIsOpenModalAddTask(false);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const applyCallbackCreateDate = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setCurrentPage(1);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "created_date"));
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>{t("student_contacts")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("students")}>
          <Select
            options={options.list}
            data={searchOption.filter_contact_list_id}
            className={classes.inputList}
            placeholder={t("select_list")}
            onChangeSearch={handleSearchList}
            isSearching={isSearchListContact}
            onchange={(value: LooseObject) => {
              handleChangeListContact(value);
            }}
            isObjectSubmit
          />
        </HeaderPage>
        <div>
          <div className={classes.contentPage}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={6}>
                <div className={classes.containerSearch}>
                  <SearchBar
                    value={searchText}
                    className={classes.inputSearch}
                    handleChange={setSearchText}
                    onBlur={handleSearchContact}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        handleSearchContact();
                        const target = e.target as HTMLButtonElement;
                        target.blur();
                      }
                    }}
                  />

                  <div className={classes.flexAlignCenter}>
                    <Dropdown
                      title={t("country")}
                      className={classes.dropdown}
                      options={options.country}
                      withCheckbox
                      withSearch
                      onClickItem={handleClickMenuItem}
                      fieldSearch="countries"
                      searchOption={searchOption}
                    />
                    <Dropdown
                      title={`${t("province")}/${t("city")}`}
                      className={classes.dropdown}
                      options={options.city}
                      withCheckbox
                      withSearch
                      onClickItem={handleClickMenuItem}
                      fieldSearch="cities"
                      searchOption={searchOption}
                      isSubMenu
                    />
                    <Dropdown
                      title={t("district")}
                      className={classes.dropdown}
                      options={options.district}
                      withCheckbox
                      withSearch
                      onClickItem={handleClickMenuItem}
                      fieldSearch="districts"
                      searchOption={searchOption}
                      isSubMenu
                    />
                    <Dropdown
                      title={t("aspirations")}
                      className={classes.dropdown}
                      options={options.aspiration}
                      withCheckbox
                      onClickItem={handleClickMenuItem}
                      fieldSearch="aspirations"
                      searchOption={searchOption}
                    />
                    <BasicDateRange
                      title={t("created_date")}
                      start={startCreate}
                      end={endCreate}
                      applyCallback={applyCallbackCreateDate}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.containerBtn}>
                  <BaseButton
                    showToolTip={isMobileScreen}
                    textToolTip={t("advanced_search")}
                    title={isMobileScreen ? "" : t("advanced_search")}
                    className={`${classes.btn} ${classes.btnLeft}`}
                    onClick={() => handleOpenModalFilter()}
                    endIcon={
                      <span className={classes.iconNumber}>
                        {numberAdvancedSearch}
                      </span>
                    }
                    icon={isMobileScreen ? <PersonSearchOutlined /> : null}
                  />
                  <RoleBaseComponent
                    permissions={["student_contact_view_saved_query_own"]}
                  >
                    <DropdownQuery
                      title={isMobileScreen ? "" : t("saved_query")}
                      icon={
                        isMobileScreen ? <CollectionsBookmarkOutlined /> : null
                      }
                      options={listQueries}
                      className={`${classes.btn} ${classes.btnLeft}`}
                      onClickItem={handleGetQuery}
                      onDelete={handleDeleteQuery}
                      endIcon={
                        <span className={classes.iconNumber}>
                          {listQueries.length}
                        </span>
                      }
                      withSearch
                    />
                  </RoleBaseComponent>
                  <RoleBaseComponent permissions={["student_contact_import"]}>
                    <LinkButton
                      showToolTip={isMobileScreen}
                      textToolTip={t("import")}
                      title={isMobileScreen ? "" : t("import")}
                      icon={isMobileScreen ? <SaveAltOutlined /> : null}
                      className={`${classes.btn} ${classes.btnLeft}`}
                      link="/studentContact/import"
                    />
                  </RoleBaseComponent>
                  <RoleBaseComponent permissions={["student_contact_export"]}>
                    <BaseButton
                      title={isMobileScreen ? "" : t("export")}
                      className={`${classes.btn} ${classes.btnLeft}`}
                      onClick={() => {
                        if (checkedList.length === 0 && total === 0) {
                          toast.warning<void>(t("no_data_found"));
                        } else {
                          setIsOpenModalConfirmExport(true);
                        }
                      }}
                      isDisabled={selectedTab === 3}
                      icon={isMobileScreen ? <FileUploadOutlined /> : null}
                    />
                  </RoleBaseComponent>
                  <RoleBaseComponent permissions={["student_contact_columns"]}>
                    <BaseButton
                      title={isMobileScreen ? "" : t("columns")}
                      icon={isMobileScreen ? <FormatListBulletedIcon /> : null}
                      startIcon={
                        isMobileScreen ? null : <FormatListBulletedIcon />
                      }
                      className={`${classes.btn} ${classes.btnMiddle}`}
                      onClick={() => handleOpenModalChooseColumns()}
                    />
                  </RoleBaseComponent>
                  <RoleBaseComponent permissions={["student_contact_create"]}>
                    <LinkButton
                      showToolTip={isMobileScreen}
                      textToolTip={t("create")}
                      title={isMobileScreen ? "" : t("create")}
                      className={`${classes.btnCreate}`}
                      startIcon={
                        isMobileScreen ? null : (
                          <AddRoundedIcon className={classes.iconAdd} />
                        )
                      }
                      link="/studentContact/create"
                      icon={
                        isMobileScreen ? (
                          <AddRoundedIcon className={classes.iconAdd} />
                        ) : null
                      }
                    />
                  </RoleBaseComponent>
                </div>
              </Grid>
            </Grid>

            <div className={classes.containerFilter}>
              {Object.entries(searchOption).map(([category, options]) => {
                let text: any = category as any;
                if (Array.isArray(options) && options.length !== 0) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${formatSearchContent(
                        options,
                        t("or")
                      )}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "string") {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (
                  options &&
                  typeof options === "object" &&
                  "name" in options &&
                  options?.name
                ) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options?.name}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (
                  options &&
                  typeof options === "object" &&
                  category === "created_date" &&
                  "startDate" in options &&
                  options?.startDate &&
                  "endDate" in options &&
                  options?.endDate
                ) {
                  const startDateFormatted = moment(options.startDate).format(
                    "YYYY/MM/DD"
                  );
                  const endDateFormatted = moment(options.endDate).format(
                    "YYYY/MM/DD"
                  );
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(
                        text
                      )}: ${startDateFormatted} -> ${endDateFormatted}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                return null;
              })}

              {isShowClearAll && (
                <div
                  className={classes.clearAll}
                  onClick={() => handleClearAllTagSearch()}
                >
                  <p>{t("clear_all")}</p>
                </div>
              )}
            </div>
            <div className={classes.results}>
              {isShowClearAll && `${total} ${t("results")}`}
            </div>
          </div>
          <div className={classes.contentPage}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={6}>
                <div className={classes.wrapStatusFilter}>
                  <div
                    className={`${classes.btnStatus} ${classes.btnStatusAll} ${totalContact === 0 && classes.emptyStatus
                      } ${searchOption.states.length === 0 && classes.selectedStatus
                      }`}
                    onClick={() => handleChangeFilterTag({}, true)}
                  >{`${t("all")} (${totalContact})`}</div>
                  {states.map((item: LooseObject) => (
                    <div
                      key={`${item.id} - a`}
                      className={`${classes.btnStatus} ${item.contacts_count === 0 && classes.emptyStatus
                        }  ${searchOption.states.findIndex(
                          (element: LooseObject) => element.id === item.id
                        ) !== -1 && classes.selectedStatus
                        }`}
                      onClick={() => handleChangeFilterTag(item)}
                    >{`${item?.name} (${item?.contacts_count})`}</div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.wrapTab}>
                  <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    sx={{ minHeight: "30px", height: "30px" }}
                  >
                    {hasViewGlobalPermission && (
                      <Tab
                        value={0}
                        label={
                          isMobileScreen ? (
                            <RecentActorsOutlined />
                          ) : (
                            t("all_contacts")
                          )
                        }
                      />
                    )}
                    <Tab
                      value={2}
                      label={
                        isMobileScreen ? <PersonOffOutlined /> : t("blacklist")
                      }
                    />
                    <Tab
                      value={1}
                      label={
                        isMobileScreen ? (
                          <PermContactCalendarOutlined />
                        ) : (
                          t("my_contacts")
                        )
                      }
                    />
                    <Tab
                      value={3}
                      label={
                        isMobileScreen ? <DeleteForeverOutlined /> : t("trash")
                      }
                    />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
            <Table
              data={data}
              columns={columnList}
              total={total}
              limit={limit}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleCheckBox={handleCheckBox}
              isShowAction={isShowAction}
              actionMenu={action as any[]}
              handleClickAction={handleClickAction}
              checkedList={checkedList}
              handleOpenModalConfirmRestore={handleOpenModalConfirmRestore}
              handleOpenModalConfirmDeleteContact={
                handleOpenModalConfirmDeleteContact
              }
              handleSelectAll={handleSelectAll}
              isSelectAllPage={isSelectAllPage}
            />
          </div>
        </div>
      </div>
      {isOpenModalFilter && (
        <ModalFilter
          isOpen={isOpenModalFilter}
          onClose={() => setIsOpenModalFilter(false)}
          optionsSelect={options}
          onConfirmSearch={handleSearchFilter}
          onConfirmSaveQuery={handleSaveQuery}
          filterField={FILTER_STUDENT_CONTACT_FIELD}
          filterOptions={searchOption}
          isSearchListContact={isSearchListContact}
          handleSearchList={handleSearchList}
        />
      )}
      {isOpenModalChangeColumns && (
        <ModalChangeColumns
          isOpen={isOpenModalChangeColumns}
          onClose={() => setIsOpenModalChangeColumns(false)}
          onConfirm={handleChangeColumn}
          column={columns}
        />
      )}
      {isOpenModalConfirmDelete && (
        <ModalConfirm
          isOpen={isOpenModalConfirmDelete}
          title={t("confirmation")}
          htmlContent={htmlContentRemove(
            checkedList,
            t("are_you_sure_to_remove")
          )}
          onClose={() => {
            setIsOpenModalConfirmDelete(false);
          }}
          onConfirm={() => handleDeleteContact()}
        />
      )}
      {isOpenModalConfirmDeleteCompletely && (
        <ModalConfirm
          isOpen={isOpenModalConfirmDeleteCompletely}
          title={t("confirmation")}
          htmlContent={htmlContentRemove(
            listDelete,
            t("are_you_sure_to_remove")
          )}
          onClose={() => {
            setIsOpenModalConfirmDeleteCompletely(false);
            setListDelete([]);
          }}
          onConfirm={() => handleDeleteContactCompletely()}
        />
      )}
      {isOpenModalConfirmExport && (
        <ModalConfirm
          isOpen={isOpenModalConfirmExport}
          title={t("export")}
          htmlContent={
            checkedList.length === 0 ? (
              <p className={classes.contentModalExport}>
                <span className={classes.nameDelete}>
                  {t("export_according_to_search")}
                </span>
                {t("numbers")}
                {` (${total})`}
              </p>
            ) : (
              <p className={classes.contentModalExport}>
                <span className={classes.nameDelete}>{`${t(
                  "numbers"
                )}: `}</span>
                {checkedList.length}
              </p>
            )
          }
          onClose={() => {
            setIsOpenModalConfirmExport(false);
          }}
          onConfirm={() => handleExportContact()}
          contentNo="close"
          contentYes="export"
        />
      )}
      {isOpenModalConfirmRestore && (
        <ModalConfirm
          isOpen={isOpenModalConfirmRestore}
          title={t("confirmation")}
          htmlContent={htmlContentRemove(
            listRestore,
            t("are_you_sure_to_restore")
          )}
          onClose={() => {
            setIsOpenModalConfirmRestore(false);
            setListRestore([]);
          }}
          onConfirm={() => handleRestoreStudent()}
        />
      )}
      {isOpenModalMassUpdate && (
        <ModalMassUpdate
          isOpen={isOpenModalMassUpdate}
          onClose={() => {
            setIsOpenModalMassUpdate(false);
            setListContactUpdate([]);
          }}
          onConfirm={handleMassUpdate}
          searchOption={options}
          contacts={contacts}
          contactData={listContactUpdate}
          isBlackList={selectedTab === 2}
          handleOpenModalAddTask={handleOpenModalAddTask}
          setListContactUpdate={setListContactUpdate}
          searchContact={getStudentContactForOption}
          listId={isSelectAllPage ? checkedList : []}
        />
      )}
      {isOpenModalAddTask && (
        <ModalTask
          isOpen={isOpenModalAddTask}
          title="create_task"
          onClose={() => setIsOpenModalAddTask(false)}
          onConfirm={handleAddTask}
          handleSearchContact={handleSearchContactToAddTask}
          isSearchContact={isSearchContact}
          contact={listContactUpdate}
          setListContactUpdate={setListContactUpdate}
          listId={isSelectAllPage ? checkedList : []}
        />
      )}
      {openModalSendSms && (
        <ModalSendSms
          isOpen={openModalSendSms}
          onClose={handleCloseModalSendSms}
          dataSendSms={listSendSms}
          listId={isSelectAllPage ? checkedList : []}
        />
      )}
      {openModalSendEmail && (
        <ModalSendEmail
          isOpen={openModalSendEmail}
          onClose={handleCloseModalSendEmail}
          dataSendEmail={listSendEmail}
          listId={isSelectAllPage ? checkedList : []}
        />
      )}
    </>
  );
}
