import { useStyles } from "./styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import LightTooltip from "../LightTooltip";
import { useTranslation } from "react-i18next";

interface TooltipProp {
  content: string;
  maxLength?: number;
  onClick?: Function;
}

export default function SeeMoreTooltip({content}: TooltipProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openTooltipReviewNotes, setOpenTooltipReviewNotes] = React.useState(false);
  const [isTruncated, setIsTruncated] = React.useState(false);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (contentRef.current) {
      setIsTruncated(contentRef.current.scrollWidth > contentRef.current.offsetWidth);
    }
  }, [content]);

  const handleTooltipReviewNotesClose = () => {
    setOpenTooltipReviewNotes(false);
  };
  const handleTooltipReviewNotesOpen = () => {
    setOpenTooltipReviewNotes(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipReviewNotesClose}>
      <div>
        <LightTooltip
          onClose={handleTooltipReviewNotesClose}
          open={openTooltipReviewNotes}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
          slotProps={{
            popper: {
              disablePortal: true,
            },
          }}
        >
          <div className={`${classes.blockReviewNotes}`}>
            <span ref={contentRef} className={`${classes.reviewNotes}`}>
              {content.replace(/\n/g, ", ")}
            </span>
            {isTruncated && (
              <span
                className={`${classes.cursorPointer} ${classes.seeMore}`}
                onClick={handleTooltipReviewNotesOpen}
              >
                {t("see_more")}
              </span>
            )}
          </div>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}
