import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	review: {},
	updateStatus: {}
}

const reviewSlice = createSlice({
	name: 'review',
	initialState,
	reducers: {
		setReview: (state, action) => {
			state.review = action.payload
		},
		setUpdateStatus: (state, action) => {
			state.updateStatus = action.payload
		}
	}
})

export const { setReview, setUpdateStatus,  } = reviewSlice.actions;
export default reviewSlice;
